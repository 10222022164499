import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import style from "./css/style.module.css";

const KB_LIMIT = 350;
export default function DropzoneComponente({
  children,
  onDroping,
  setFiles,
  multiple,
  className,
  erroMessages,
  setError,
  sizeLimit,
}) {
  // const [error, setError] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    let isValid = true;
    if (sizeLimit) {
      const KB_SIZE_LIMIT =
        !isNaN(sizeLimit) && sizeLimit !== true ? sizeLimit : KB_LIMIT;
      let files = acceptedFiles;
      if (!Array.isArray(acceptedFiles)) {
        files = [acceptedFiles];
      }
      files.forEach(({ size }) => {
        if (size > KB_SIZE_LIMIT * 1024) {
          if (setError) {
            setError(
              "El tamaño máximo de imagen es de " +
                ` ${KB_SIZE_LIMIT} KB. La imagen que intentas subir es de ${(
                  size / 1024
                ).toFixed(2)} KB`
            );
          }
          isValid = false;
        }
      });
    }
    if (isValid) {
      setFiles(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg", ".png"],
    },
    multiple: multiple ? true : false,
    onDrop,
  });

  return (
    <div className={`${style.zone} ${className}`} {...getRootProps()}>
      <input type="file" {...getInputProps()} />
      {isDragActive ? (
        <div
          className={style.dropping}
          aria-label="Recipient's image"
          aria-describedby="basic-addon2"
        >
          {onDroping ? onDroping : "Suelta la imagen aquí"}
        </div>
      ) : (
        <div aria-label="Recipient's image" aria-describedby="basic-addon2">
          {children ? children : "Selecciona o arrastra la imagen"}
        </div>
      )}
    </div>
  );
}
