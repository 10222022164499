import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import style from "./css/style.module.css";
import {
  ButtonModerna,
  Carrusel,
  Modal,
  ImageDropzone,
  Loading,
  Pagination,
} from "../../../imports";
import Item from "./components/item";
import Input from "../../../common/inputModerna/input";
import {
  activateExhibidorTypeToDataBase,
  deleteExhibitorTypes,
  editExhibitorTypes,
  fetchExhibidoresFromDataBase,
  fetchExhibitorTypes,
  inactiveExhibitorTypes,
  insertExhibitorTypes,
} from "../../../service/Exhibidor";
import { COLORS } from "../../../common/theme/theme";
import {
  deleteImgOneDrive,
  getCurrentUrlImage,
  getExhibidorIdImgOneDrive,
  handleCheckUserSection,
  handleCleanUserSection,
  uploadExhibidorImage,
  verifyExhibidorImage,
} from "../../../util/Utils";
import { v4 as uuidv4 } from "uuid";
import { ModernaContext } from "../../../Context/ModernaContext";
import { ConstantsModerna } from "../../../common/constants/ConstantsModerna";
import { useMsal } from "@azure/msal-react";
import Loading2 from "../../../common/loading2/loading2";
import { handleCheckAccessValidation } from "../../../service/AccessValidation";
import { SweetAlert } from "../../../common/sweet-alert/sweet-alert";
export default function Exhibidor() {
  const [showCreate, setShowCreate] = useState(false);
  const [showView, setShowView] = useState(false);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [editing, setEditing] = useState(false);
  const [image, setImage] = useState(data?.url_imagen_exhibidor);
  const [errorNombre, setErrorNombre] = useState("");
  const [errorImagen, setErrorImagen] = useState("");
  const [errorDuplicado, setErrorDuplicado] = useState("");
  const [cargando, setCargando] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleShowAlert } = useContext(ModernaContext);
  const [estado, setEstado] = useState();
  const [loadingBack, setLoadingBack] = useState(false);
  const [nameExhib, setNameExhib] = useState("");
  const [page, setPage] = useState(1);
  const [pagintationData, setPagintationData] = useState();
  const [estadoExhibidor, setestadoExhibidor] = useState(false);

  const Swal = require("sweetalert2");
  const { accounts } = useMsal();

  const alertDelete = (id) => {
    Swal.fire({
      text: "¿Estás seguro de eliminar este exhibidor?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      cancelButtonColor: COLORS.MODERNA_YELLOW,
      confirmButtonColor: COLORS.MODERNA_RED,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      } else {
        handleCleanUserSection();
      }
    });
  };

  const alertError2 = (text) => {
    Swal.fire({
      icon: "error",
      // title: "Oops...",
      text: text,
      confirmButtonText: "Aceptar",
      confirmButtonColor: COLORS.MODERNA_RED,
      // footer: '<a href="">Why do I have this issue?</a>'
    });
  };
  const alertEnable = (id) => {
    Swal.fire({
      text: "¿Estás seguro de inactivar el exhibidor?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      cancelButtonColor: COLORS.MODERNA_YELLOW,
      confirmButtonColor: COLORS.MODERNA_RED,
    }).then((result) => {
      if (result.isConfirmed) {
        handleInactive(id);
      } else {
        handleCleanUserSection();
      }
    });
  };

  const alertActive = (item) => {
    Swal.fire({
      text: `¿Estás seguro de activar este exhibidor?`,
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      cancelButtonColor: COLORS.MODERNA_YELLOW,
      confirmButtonColor: COLORS.MODERNA_RED,
    }).then((result) => {
      if (result.isConfirmed) {
        // handleDelete(id);
        handleEnableExib(item);
      } else {
        handleCleanUserSection();
      }
    });
  };

  const handleFiles = (e) => {
    const preview2 = URL.createObjectURL(e[0]);
    setImage(preview2);
    setErrorImagen("");
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const _result = await fetchExhibidoresFromDataBase(page);
      setPagintationData({
        limit: _result.limit,
        page: _result.page + 1,
        total_elements: _result.total_elements,
        total_pages: _result.total_pages,
      });
      const result = _result?.data;

      setData(result);
    } catch (error) {}
    setLoading(false);
  };

  const buscarCoincidenciaGC = () => {
    let encontre = data.filter(
      (objeto) =>
        (editing
          ? objeto.id_exhibidor_tipo.toLowerCase() !== id.toLowerCase()
          : true) &&
        objeto.nombre_tipo_exhibidor.toLowerCase() === name.toLowerCase()
    );
    return encontre.length > 0 ? true : false;
  };

  const handleSubmit = async () => {
    if (validarCampos()) {
      return;
    }
    if (buscarCoincidenciaGC()) {
      setErrorDuplicado("* Ya existe un tipo exhibidor con ese nombre");
      return;
    }
    setLoadingBack(true);
    try {
      let imgUrlWeb;
      let tmp_id_exhibidor_tipo = id ? id : uuidv4();
      imgUrlWeb = await uploadExhibidorImage(image, tmp_id_exhibidor_tipo);
      const EXHIBITOR_TYPE = {
        id_exhibidor_tipo: tmp_id_exhibidor_tipo,
        url_imagen_exhibidor: imgUrlWeb.url,
        nombre_tipo_exhibidor: name,
        usuario_creacion: accounts[0].username,
        estado: editing ? estadoExhibidor : 1,
        id_imagen_exhibidor: imgUrlWeb.id,
      };
      let save;
      if (editing) {
        save = await editExhibitorTypes(EXHIBITOR_TYPE);
        handleCleanUserSection();
      } else {
        save = await insertExhibitorTypes(EXHIBITOR_TYPE);
        handleCleanUserSection();
      }
      setShowCreate(false);
      fetchData();
      setId("");
      setName("");
      setImage("");
      setEditing(false);
      setErrorDuplicado("");
      handleCleanUserSection();
    } catch (error) {
      alertError2("Error en el servidor, intentalo de nuevo");
    } finally {
      handleCleanUserSection();
      setLoadingBack(false);
    }
  };
  const handleEnableExib = async (item) => {
    setLoadingBack(true);
    try {
      await activateExhibidorTypeToDataBase(item);
      handleCleanUserSection();
      // } else {
      //   save = await insertExhibitorTypes(EXHIBITOR_TYPE);
      //   handleCleanUserSection();
      // }
      setShowCreate(false);
      fetchData();
      setEditing(false);
      handleCleanUserSection();
    } catch (error) {
      // console.log("Ocurrio un error al subir el exibidor");
      alertError2("Error en el servidor, intentalo de nuevo");
    } finally {
      handleCleanUserSection();
      setLoadingBack(false);
    }
  };

  const handleDelete = async (e) => {
    setLoadingBack(true);
    try {
      await deleteExhibitorTypes(e);
      const { id } = await getExhibidorIdImgOneDrive(e);
      await deleteImgOneDrive(id);
      fetchData();
    } catch (error) {
      // console.log("entre a disabled");
      try {
        await inactiveExhibitorTypes(e);
        fetchData();
      } catch (err) {
        // console.log("fallo");
        // console.log(err);
      }
    } finally {
      setLoadingBack(false);
    }
    // console.log("e::::", e);
  };

  const handleInactive = async (e) => {
    setLoadingBack(true);

    // console.log("entre a disabled");
    try {
      await inactiveExhibitorTypes(e);
      fetchData();
    } catch (err) {
      alertError2("Error en el servidor, intentalo de nuevo");
    } finally {
      setLoadingBack(false);
    }
    // console.log("e::::", e);
  };

  const handleEdit = async (e) => {
    setLoadingBack(true);
    try {
      const isAble = await handleCheckUserSection();
      if (isAble) {
        changeImageModifi(e);
        // setImage(e.url_imagen_exhibidor);
        setErrorImagen("");
        setEditing(true);
        setId(e.id_exhibidor_tipo);
        setName(e.nombre_tipo_exhibidor);
        setestadoExhibidor(e.estado);
        setShowCreate(true);
      } else {
        handleShowAlert(ConstantsModerna.messages.USER_EDITING);
      }
    } catch (error) {
      alertError2("Error en el servidor, intentalo de nuevo");
    } finally {
      setLoadingBack(false);
    }
  };

  const handleView = async (e) => {
    setLoadingBack(true);
    try {
      await changeImageModifi(e);
      setShowView(true);
      setNameExhib(e?.nombre_tipo_exhibidor);
    } catch (error) {
      alertError2("Error en el servidor, intentalo de nuevo");
    } finally {
      setLoadingBack(false);
    }

    // setImage(e.url_imagen_exhibidor);
  };

  const changeImageModifi = async (e) => {
    // const im1 = await verifyExhibidorImage(
    //   e.url_imagen_exhibidor,
    //   e.id_exhibidor_tipo
    // );
    const getImage = async () => {
      if (e.url_imagen_exhibidor) {
        setImage("loading");
        let img = await getCurrentUrlImage(e?.id_exhibidor_tipo, "EX");
        setImage(img);
      }
    };
    getImage();
  };

  const validarCampos = () => {
    let validate = false;
    if (name === "" || name === null) {
      setErrorNombre("* Este campo nombre es obligatorio");
      validate = true;
    }
    if (image === undefined || image === "" || image === null) {
      setErrorImagen("* Este campo imagen es obligatorio");
      validate = true;
    }
    return validate;
  };

  const verificarEstado = (item) => {
    if (item?.estado === true) {
      return false;
    } else {
      return true;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Modal
        disabled={cargando}
        show={showCreate}
        close={async () => {
          if (!editing && (name?.length > 1 || image)) {
            const confirm = await SweetAlert(
              "Tiene cambios sin guardar. ¿Desea salir?"
            );
            if (!confirm) {
              return;
            }
          }
          handleCleanUserSection();
          setShowCreate(cargando);
          setEditing(false);
        }}
        title={editing ? "Editar Exhibidor" : "Crear Exhibidor"}
        buttons
        accept={handleSubmit}
      >
        <div className={style.inputBox}>
          <div style={{ marginBottom: "10px" }}>
            <ImageDropzone
              setFiles={handleFiles}
              onDroping="Suelta las imagenes"
              sizeLimit
              setError={setErrorImagen}
            >
              {image ? (
                image === "loading" ? (
                  <div>
                    <Loading clean />
                  </div>
                ) : (
                  <img
                    src={image}
                    style={{
                      height: "300px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt="img moderna"
                  ></img>
                )
              ) : (
                ""
              )}
            </ImageDropzone>
          </div>
          <p className={style.errorText}>{errorImagen}</p>
          <Input
            block={cargando}
            label="Nombre Exhibidor"
            value={name}
            maxLength={40}
            onChange={(e) => {
              setName(e.target.value);
              setErrorNombre("");
            }}
            errorMessage={errorNombre}
          />
          <p
            className={style.errorText}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {errorDuplicado}
          </p>
        </div>
      </Modal>

      <Modal
        show={showView}
        close={() => {
          // handleCleanUserSection();
          setShowView(false);
          setImage("");
          setId("");
          setName("");
        }}
        title={nameExhib}
      >
        <Carrusel images={image} size={500} />
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <ButtonModerna
          secondary
          onClick={async () => {
            await handleCheckAccessValidation(
              setLoadingBack,
              () => {
                setShowCreate(true);
                setImage("");
                setId("");
                setName("");

                setErrorNombre("");
                setErrorImagen("");
                setErrorDuplicado("");
              },
              handleShowAlert
            );
          }}
          className={`${style.button}`}
          add
        >
          Nuevo Exhibidor
        </ButtonModerna>
      </div>
      {data?.map((item, index) => (
        <div key={index}>
          <Item
            estadoExhibidor={verificarEstado(item)}
            showImage={() => handleView(item)}
            data={item}
            showDelete={async () => {
              await handleCheckAccessValidation(
                setLoadingBack,
                () => {
                  alertDelete(item.id_exhibidor_tipo);
                },
                handleShowAlert
              );
            }}
            handleDisable={async () => {
              await handleCheckAccessValidation(
                setLoadingBack,
                () => {
                  alertEnable(item.id_exhibidor_tipo);
                },
                handleShowAlert
              );
            }}
            handleEnable={async () => {
              await handleCheckAccessValidation(
                setLoadingBack,
                () => {
                  alertActive(item?.id_exhibidor_tipo);
                },
                handleShowAlert
              );
            }}
            showEdit={() => handleEdit(item)}
          />
        </div>
      ))}

      <Pagination setPage={setPage} data={pagintationData} />
      {loadingBack && <Loading2 />}
    </Container>
  );
}
