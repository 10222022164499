import React, { useRef } from "react";
import style from "./style.module.css";
import { Col, Form } from "react-bootstrap";
import {
  validatePercentage,
  validateSpecialCharacters,
} from "../../../../../util/validations";
export default function VariableInput({
  children,
  setValue,
  percentage,
  right,
  disabled,
  maxLength,
  min,
  ...restOfProps
}) {
  const inputRef = useRef();
  const handleFocus = () => {
    if (percentage) {
      inputRef.current.select();
    }
  };
  return (
    <Form.Control
      maxLength={maxLength ? maxLength : 30}
      disabled={disabled}
      className={`${style.input} ${right && style.right}`}
      value={children}
      onFocus={handleFocus}
      ref={inputRef}
      onChange={(e) => {
        if (setValue) {
          let value = e.target.value;
          if (percentage) {
            value = validatePercentage(value, min ? min : 0);
          } else {
            value = validateSpecialCharacters(value);
          }
          setValue(value);
        }
      }}
      {...restOfProps}
    />
  );
}
