import { Container } from "react-bootstrap";

export default function TestPage(props) {
  const obj = {
    key1: "value1",
    key2: null,
    key3: undefined,
  };

  const hasKey = "key4" in obj;

  return (
    <Container style={{ marginTop: "55px" }}>
      has key:{hasKey.toString()}
    </Container>
  );
}
