import { useState } from "react";
import style from "./css/style.module.css";
import { AiOutlineCaretDown as OpenButton } from "react-icons/ai";
import { Transition } from "../../imports";

export default function Dropbutton({
  title,
  children,
  dropable,
  buttons,
  single,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        className={`${style.header} ${style.row} ${
          (children || dropable) && style.dropable
        }`}
        onClick={() => setOpen(!open)}
      >
        <div>
          {title}
          {(children || dropable) && single !== true && (
            <OpenButton
              style={{ margin: "0px 10px" }}
              className={open ? style.down : style.up}
            />
          )}
        </div>
        {buttons ? buttons : ""}
      </div>
      <Transition activator={open} disabled>
        {children}
      </Transition>
    </div>
  );
}
export const Row = ({ children, right }) => {
  return (
    <div className={`${style.item} ${style.row}`}>
      <div> {children}</div>
      {right}
    </div>
  );
};
