import { axios } from "../imports";
const API_GENERAL_URL =
  "https://fotoexito1.azurewebsites.net/api/functionGeneral?code=PfkH6TT2D6DBtUdFhK5lHf2-7Z62TpVnNL6_Z4Oz8KY_AzFucJZ_Vg==";

export const consultCodigoRolUserByEmail = async (email) => {
  let response;
  try {
    const data = {
      typeQuery: "R_JOIN_UNIT",
      data: {
        tableName: "usuario",
        compare: ["correo", email],
        data: ["correo", "usuario", "id_usuario"],
        foreingFields: [
          {
            tableName: "usuario",
            foreingTableName: "rol_usuario",
            data: ["id_rol"],
            foreingKey: "id_usuario",
          },
          {
            tableName: "rol_usuario",
            foreingTableName: "rol",
            data: ["codigo_rol"],
            foreingKey: "id_rol",
          },
        ],
      },
    };
    response = await axios.post(API_GENERAL_URL, data);
    return response;
  } catch (e) {
    response = e;
  }
};
