import React from "react";
import style from "./style.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { CrudButton } from "../../../../imports";
import { COLORS } from "../../../../common/theme/theme";

export default function Item({
  data,
  showImage,
  showDelete,
  showEdit,
  estadoExhibidor,
  handleEnable,
  handleDisable,
}) {
  // useEffect(() => {
  //   first

  //   return () => {
  //     second
  //   }
  // }, [third])

  const handleEdit = () => {
    if (showEdit) {
      showEdit(data);
    }
  };

  const handleDelete = () => {
    if (showDelete) {
      showDelete(data);
    }
  };

  const handleView = () => {
    if (showImage) {
      showImage(data);
    }
  };

  return (
    <div className={style.caja}>
      <Row
        className={estadoExhibidor ? style.navTableInactive : style.navTable}
      >
        <Col>
          <Row>
            <Col className={style.nameExhibidorBox}>
              {data?.nombre_tipo_exhibidor
                ? data?.nombre_tipo_exhibidor
                : "Exhibidor"}
            </Col>
            <Col>
              <div className={style.buttonBox}>
                {estadoExhibidor || data?.registros?.length > 0 ? (
                  <></>
                ) : (
                  <div className={style.buttonBox}>
                    <CrudButton
                      background={COLORS.MODERNA_YELLOW}
                      size={30}
                      edit
                      onClick={handleEdit}
                    />
                  </div>
                )}
                {!estadoExhibidor ? (
                  <></>
                ) : (
                  <div>
                    <CrudButton
                      background={COLORS.MODERNA_NEW_GREEN}
                      size={30}
                      on
                      onClick={handleEnable}
                    />
                  </div>
                )}
                {estadoExhibidor ? (
                  <></>
                ) : (
                  <CrudButton
                    background={
                      data?.registros?.length > 0
                        ? "#88191f"
                        : COLORS.MODERNA_YELLOW
                    }
                    size={30}
                    min={data?.registros?.length > 0}
                    del={data?.registros?.length <= 0}
                    onClick={
                      data?.registros?.length > 0 ? handleDisable : handleDelete
                    }
                  />
                )}
                <CrudButton
                  background={COLORS.MODERNA_YELLOW}
                  size={30}
                  view
                  onClick={handleView}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
