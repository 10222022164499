import { ConstantsModerna } from "../common/constants/ConstantsModerna";
import { PAGINATION_CONFIG } from "../config/app-config";
import { axios } from "../imports";

const API_GENERAL_URL =
  "https://fotoexito1.azurewebsites.net/api/functionGeneral?code=PfkH6TT2D6DBtUdFhK5lHf2-7Z62TpVnNL6_Z4Oz8KY_AzFucJZ_Vg==";

export const selectAllByTable = async (tableName, orderBy) => {
  const data = {
    typeQuery: "SELECT",
    data: {
      tableName,
      orderBy,
    },
  };
  const response = await axios.post(API_GENERAL_URL, data);
  return response?.data?.data ? response.data.data : response;
};

export const selectAllPaginated = async (tableName, orderBy, page = 1) => {
  const data = {
    typeQuery: "SELECT",
    data: {
      tableName,
      orderBy,
      page: page,
      limit: PAGINATION_CONFIG.LIMIT,
    },
  };
  const response = await axios.post(API_GENERAL_URL, data);
  return response?.data ? response.data : response;
};

export const selectAllByTableWhere = async (tableName, colums, values) => {
  const response = await postToDataBase(tableName, colums, values, "SELECT");
  return response?.data?.data ? response.data.data : response;
};

export const deleteById = async (tableName, columns, values) => {
  const typeQuery = "DELETE";
  const response = await postToDataBase(tableName, columns, values, typeQuery);
  return response?.data?.data ? response.data.data : response;
};

export const updateTableById = async (tableName, columns, values) => {
  const typeQuery = "UPDATE";
  const response = await postToDataBase(tableName, columns, values, typeQuery);

  return response?.data?.data ? response.data.data : response;
};

export const insertIntoTable = async (tableName, columns, values) => {
  const typeQuery = "INSERT";
  const response = await postToDataBase(tableName, columns, values, typeQuery);
  return response?.data?.data ? response.data.data : response;
};

export const postToDataBaseJoin = async (
  tableName,
  columns,
  foreingTables,
  page,
  order
) => {
  let response;
  try {
    const data = {
      typeQuery: "R_JOIN",
      data: {
        tableName,
        data: columns,
        foreingFields: foreingTables,
        page: page,
        limit: PAGINATION_CONFIG.LIMIT,
        order,
      },
    };
    response = await axios.post(API_GENERAL_URL, data);
  } catch (e) {
    response = e;
  }
  return response;
};

export const postToDataBaseJoinUnit = async (
  tableName,
  compare,
  columns,
  foreingTables,
  compareFields
) => {
  let response;
  try {
    const data = {
      typeQuery: "R_JOIN_UNIT",
      data: {
        tableName,
        compare: compare,
        compareFields: compareFields,
        data: columns,
        foreingFields: foreingTables,
      },
    };
    response = await axios.post(API_GENERAL_URL, data);
  } catch (e) {
    response = e;
  }
  return response;
};

const postToDataBase = async (
  tableName,
  columns,
  values,
  typeQuery,
  orderBy
) => {
  let response;
  try {
    const data = {
      typeQuery: typeQuery,
      data: {
        tableName,
        fieldType: columns,
        fieldData: values,
        orderBy,
      },
    };
    response = await axios.post(API_GENERAL_URL, data);
    return response;
  } catch (e) {
    response = e;
    throw new Error(e);
  }
};

export const postAxiosRead = async (typeQuery, tableName) => {
  let response;
  try {
    const data = {
      typeQuery: typeQuery,
      data: {
        tableName: tableName,
      },
    };
    response = await axios.post(API_GENERAL_URL, data);
    return response;
  } catch (e) {
    response = e;
    throw new Error(e);
  }
};

export const axiosGetToken = async () => {
  let response;
  try {
    const data = {
      clientSecret: "wQU8Q~WjEgKYC1U9ggNVBY8XV3PQi1ckWKX4ia.p",
    };
    response = await axios.post(ConstantsModerna.enpoints.getAccesToken, data);
    return response;
  } catch (e) {
    response = e;
    throw new Error(e);
  }
};
