import React, { useEffect, useState } from "react";
import { ButtonModerna, ICON_LOGIN, Loading } from "../../imports";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/ad-config";
import { COLORS } from "../../common/theme/theme";
import { Col, Container, Row } from "react-bootstrap";
import style from "./css/style.module.css";
import { handleCleanUserSection } from "../../util/Utils";

export default function Login({ unauthorized }) {
  const { instance } = useMsal();
  const [location, setLocation] = useState(window.location.pathname);

  useEffect(() => {
    let timeoutId;
    if (location === "/saliendo") {
      timeoutId = setTimeout(() => {
        window.history.pushState(null, "", "/");
        setLocation("/"); // Cambia el path a '/new-path' después del delay
      }, 2000);
      return;
    }
    document.title = unauthorized ? "Usuario no autorizado" : "Iniciar Sesión";
    window.history.pushState(null, "", "/");
    // console.log(location);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {}
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    handleCleanUserSection();
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };
  if (location === "/saliendo") {
    return <Loading />;
  }
  return (
    <Container fluid className={style.container}>
      <Row className={style.box}>
        <Col xs={12} sm={6} lg={5} xl={4} className={style.left}>
          <Col xs={12}>
            <img
              src={ICON_LOGIN}
              className={style.image}
              alt="Logo inicio de sesión sistema de auditoría moderna"
            />
          </Col>
          <Col xs={12} className={`${style.left_bottom}`}>
            <p style={{ color: COLORS.MODERNA_YELLOW }}>
              AVISO: ha accedido a un sistema propiedad de la EMPRESA MODERNA
              ALIMENTOS S.A. Necesita tener autorización antes de usarlo,
              estando estrictamente limitado al uso indicado en dicha
              autorización.
            </p>
            <p style={{ color: "white" }}>©2023 Moderna Alimentos S.A.</p>
          </Col>
        </Col>
        <Col xs={12} sm={6} lg={7} xl={8} className={style.right}>
          {unauthorized ? (
            <div>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0rem 5rem",
                }}
              >
                <h2>Ocurrió un problema</h2>
                <h3
                  style={{
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  Al parecer no tienes acceso al sistema, valida las
                  credenciales.
                </h3>
              </Col>
              <ButtonModerna
                color={"black"}
                onClick={handleLogout}
                className={style.button}
              >
                <span style={{ fontSize: "20px" }}>
                  Regresar a inicio de sesión
                </span>
              </ButtonModerna>
            </div>
          ) : (
            <div>
              <h1>Bienvenido</h1>
              <Col xl={10} xs={12}>
                <h3>Sistema de Auditoría Tienda Perfecta</h3>
              </Col>
              <Col xl={2} xs={0}></Col>
              <ButtonModerna
                color={"black"}
                onClick={handleLogin}
                className={style.button}
              >
                <span style={{ fontSize: "20px" }}>Iniciar Sesión</span>
              </ButtonModerna>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
