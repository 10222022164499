export const COLORS = {
  MODERNA_RED: "#ec242c",
  // "E11F1D rojo"
  // "EBA900 amarillo"
  MODERNA_YELLOW: "#ECAA20",
  MODERNA_GREEN: "#5BC2A7",
  TEXT_INFO_COLOR: "#7D7D7D",
  BACKGROUND_GRAY: "rgb(162, 162, 162,0.8)",
  MODERNA_NEW_GREEN: "#00993F",
  MODERNA_GRIS: "#F4F6F6",
  MODERNA_CYAN: "#00AE9E",
};
