import React from "react";
import { Col, Row } from "react-bootstrap";
import { Checkbox, CrudButton } from "../../../../imports";
import { COLORS } from "../../../../common/theme/theme";
import style from "./style.module.css";

export default function Item({
  data,
  showImage,
  showDelete,
  showEdit,
  handleState,
}) {
  const handleEdit = () => {
    if (showEdit) {
      showEdit(data);
    }
  };

  const handleDelete = () => {
    if (showDelete) {
      showDelete(data);
    }
  };

  const handleView = () => {
    if (showImage) {
      showImage(data);
    }
  };

  const capitalize = (value) => {
    if (typeof value !== "string") {
      return value;
    }
    return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
  };

  const changeDate = (dateS) => {
    const date = new Date(dateS);

    const day = date.getDate();
    const month = date.getMonth() + 1; // Sumar 1 ya que los meses se indexan desde 0
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  return (
    <div className={style.box}>
      <Row className={style.navContent}>
        <Col>
          <Row>
            <Col>{capitalize(data ? data.nombre_grupo_cliente : "Grupo")}</Col>
            <Col>{capitalize(data ? data.nombre_categoria : "Categoría")}</Col>
            <Col>
              {data?.fecha_creacion ? changeDate(data?.fecha_creacion) : "N/A"}
            </Col>
            <Col>
              <div className={style.buttonBox}>
                {data?.perchas?.length > 0 ? (
                  <>
                    <div
                      style={{
                        width: 30,
                        // backgroundColor: "black",
                        marginLeft: "2px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: 30,
                        // backgroundColor: "black",
                        marginLeft: "2px",
                        marginRight: "2px",
                      }}
                    ></div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <CrudButton
                      background={COLORS.MODERNA_YELLOW}
                      size={30}
                      color="white"
                      edit
                      onClick={handleEdit}
                    />
                    <CrudButton
                      background={COLORS.MODERNA_YELLOW}
                      size={30}
                      color="white"
                      del
                      onClick={handleDelete}
                    />
                  </div>
                )}

                <CrudButton
                  background={COLORS.MODERNA_YELLOW}
                  size={30}
                  color="white"
                  view
                  onClick={showImage}
                />
                <Checkbox
                  size={30}
                  onClick={handleState}
                  checked={data?.estado ? data?.estado : false}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
