import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import style from "./style.module.css";

export default function SelectDrop({
  label,
  onSelect,
  options,
  errorMessage,
  selected,
  placeholder,
  disabled,
}) {
  const [selectedValue, setSelectedValue] = useState();
  const handleSelectChange = (e) => {
    // if (onSelect) {
    const value = e.target.value;
    if (value < 0) {
      setSelectedValue(undefined);
      onSelect(undefined);
      return;
    }
    onSelect(options[value].value);
    setSelectedValue(value);
    // }
  };

  useEffect(() => {
    let index = -1;
    if (Array.isArray(options)) {
      index = options.findIndex(
        (option) => option?.label === selected || selected === option?.value
      );
    }
    setSelectedValue(index);
  }, [selected]);

  return (
    <div className={style.box}>
      <Row>
        <Col>
          <Row className={style.item}>
            {label ? (
              <Col style={{ display: "flex", flex: "1" }}>
                <label className={style.lab}>{label ? label : "Nombre"}</label>
              </Col>
            ) : (
              ""
            )}

            <Col
              style={{
                flex: "4",
                position: "relative",
              }}
            >
              <Form.Select
                disabled={disabled}
                size="sm"
                className={style.inputDrop}
                onChange={handleSelectChange}
                value={selectedValue}
                defaultValue=""
              >
                <option hidden>
                  {placeholder ? placeholder : "Selecciona un exhibidor"}
                </option>
                {Array.isArray(options) &&
                  options?.map((option, index) => (
                    <option key={index} value={index}>
                      {option.label}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col style={{ display: "flex", flex: 1 }}></Col>
            <Col style={{ display: "flex", flex: 4 }}>
              <p className={style.errorText}>{errorMessage}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

// export default SelectSearchFetch;
