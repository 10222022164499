import React, { useCallback, useEffect } from "react";
import { ModernaContext } from "./ModernaContext";
import { notification } from "antd";
export const ModernaStates = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const handleShowAlert = useCallback((message) => {
    api.info({
      message: "Alerta",
      description: message,
      placement: "topRight",
    });
  }, []);
  return (
    <ModernaContext.Provider
      value={{
        handleShowAlert: handleShowAlert,
      }}
    >
      {contextHolder}
      {children}
    </ModernaContext.Provider>
  );
};
