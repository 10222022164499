import React from "react";
import { Button } from "react-bootstrap";
import _style from "./css/style.module.css";
import { COLORS } from "../../theme/theme";
import { MdAddCircle as Add } from "react-icons/md";
import { FaSave as Save } from "react-icons/fa";
import { MdCancel as Cancel } from "react-icons/md";
import { BsFillCloudArrowUpFill as UpCloud } from "react-icons/bs";
export default function ModernaButton({
  children,
  loading,
  secondary,
  color,
  className,
  style,
  letterBlack,
  add,
  save,
  cancel,
  cloud,
  disabled,
  ...restOfProps
}) {
  const setColor = () => {
    if (secondary) {
      return COLORS.MODERNA_NEW_GREEN;
    }
    if (color) {
      return color;
    }
    return COLORS.MODERNA_RED;
  };

  return (
    <div style={style} className={_style.cicon}>
      <Button
        disabled={disabled}
        className={[_style.btn, className]}
        style={{
          backgroundColor: setColor(),
          color: letterBlack ? "black" : "white",
        }}
        {...restOfProps}
      >
        {children}&nbsp;
        {add ? <Add className={_style.icon} /> : <></>}
        {save ? <Save className={_style.icon} /> : <></>}
        {cancel ? <Cancel className={_style.icon} /> : <></>}
        {cloud ? <UpCloud className={_style.icon} /> : <></>}
      </Button>
    </div>
  );
}
