import { PAGINATION_CONFIG } from "../config/app-config";
import { axios } from "../imports";
import {
  deleteById,
  insertIntoTable,
  selectAllByTable,
  selectAllPaginated,
  updateTableById,
} from "./AxiosService";

const URI_EXHIBIDOR =
  "https://fotoexito1.azurewebsites.net/api/FunctionExhibidor?code=0yYTWQyLYFtn1kHJGpLv03pkP-g_9x9crauWdf9SAozOAzFu0acEYA==";

const URI_REGISTER_EXHIBITOR =
  "https://fotoexito1.azurewebsites.net/api/functionregisterexhibitor?code=lmhsNqEfxZIYP1wScymYVdo7uAKYjw_sL3Mdlf_tSvE6AzFuA9qnjQ%3D%3D";

const DATABASE_STRUCTURE = {
  tableName: "exhibidor_tipo",
  columns: [
    "id_exhibidor_tipo",
    "nombre_tipo_exhibidor",
    "url_imagen_exhibidor",
    "usuario_creacion",
    "estado",
    "fecha_creacion",
    "fecha_modificacion",
    "id_imagen_exhibidor",
  ],
  columnsEditable: [
    "id_exhibidor_tipo",
    "nombre_tipo_exhibidor",
    "url_imagen_exhibidor",
    // "estado",
    //"usuario_creacion",
    // "fecha_creacion",
    "fecha_modificacion",
    "id_imagen_exhibidor",
  ],
};

export const fetchExhibitorTypes = async (page = 1) => {
  return await fecthExhibitorTypesFromDataBase(page);
  // return EXHIBITOR_TYPE;
};

export const fetchExhibidoresFromDataBase = async (page) => {
  let response;
  try {
    const data = {
      typeQuery: "SELECT",
      data: {
        page: page - 1,
        limit: PAGINATION_CONFIG.LIMIT,
      },
    };
    response = await axios.post(URI_EXHIBIDOR, data);
    return response.data;
  } catch (e) {
    response = e;
  }
  return response;
};

export const fetchRegisterExhibidoresFromDataBase = async (
  page,
  nameClient
) => {
  let response;
  try {
    const data = {
      typeQuery: "SELECT",
      data: {
        page: page - 1,
        limit: PAGINATION_CONFIG.LIMIT,
        client: nameClient,
      },
    };
    response = await axios.post(URI_REGISTER_EXHIBITOR, data);
    return response.data;
  } catch (e) {
    response = e;
  }
  return response;
};

export const fetchRegisterExhibidoresClients = async () => {
  let response;
  try {
    const data = {
      typeQuery: "SELECT",
    };
    response = await axios.post(URI_REGISTER_EXHIBITOR, data);
    return response.data;
  } catch (e) {
    response = e;
  }
  return response;
};

export const insertExhibitorTypes = async (exhib) => {
  // EXHIBITOR_TYPE.push(exhib);
  const result = await insertExhibitorTypeToDataBase(exhib);
  return result;
};

export const deleteExhibitorTypes = async (exhibid) => {
  // EXHIBITOR_TYPE = EXHIBITOR_TYPE.filter((item) => item.id !== exhibid);
  const result = await deleteExhibitorTypeToDataBase(exhibid);
  return result;
};

export const editExhibitorTypes = async (data) => {
  const response = await updateExhibitorTypeToDataBase(data);
  return response;
};

export const inactiveExhibitorTypes = async (data) => {
  const response = await disableTypeToDataBase(data);
  return response;
};

// ______________________________REGISTRAR EXHIBIDOR_____________________________________________
let REGISTER_EXHIBITOR = [
  {
    id: "12345",
    idCliente: "323232",
    nombre: "Juan El Caballo",
    sucursal: "Sucursal 1",
    mes: "04",
    anio: "2023",
    tipoExhibidor: "Exhibidor 1",
  },
  {
    id: "654321",
    idCliente: "542111",
    nombre: "Pedro",
    sucursal: "Sucursal 2",
    mes: "05",
    anio: "2023",
    tipoExhibidor: "Exhibidor 2",
  },
];

const DATABASE_STRUCTURE_EXHIBIDOR = {
  tableName: "exhibidor",
  columns: [
    "id_exhibidor",
    "id_exhibidor_tipo",
    "id_cliente",
    "sucursal",
    "ano_exhibidor",
    "mes_exhibidor",
    "usuario_creacion",
    "fecha_creacion",
    "fecha_modificacion",
    "estado",
  ],
  columnsEditable: [
    "id_exhibidor",
    "id_exhibidor_tipo",
    "id_cliente",
    "sucursal",
    "ano_exhibidor",
    "mes_exhibidor",
    // "usuario_creacion",
    // "fecha_creacion",
    "fecha_modificacion",
    "estado",
  ],
};

export const fetchRegisterExhibitor = async () => {
  return REGISTER_EXHIBITOR;
};

export const registerExhibitor = async (data) => {
  const result = await insertRegisterExhibitorDataBase(data);
  return result;
};

export const deleteRegisterExhibitor = async (id) => {
  const result = await deleteRegisterExhibitorDataBase(id);
  return result;
};

export const editRegisterExhibitor = async (data) => {
  const response = await updateRegisterExhibitorDataBase(data);
  return response;
};

// ______________________________________Funciones Azure para registro exhibidor___________________________________________________________
const deleteRegisterExhibitorDataBase = async (deleted) => {
  const values = [deleted];
  const response = await deleteById(
    DATABASE_STRUCTURE_EXHIBIDOR.tableName,
    [DATABASE_STRUCTURE_EXHIBIDOR.columns[0]],
    values
  );
  return response;
};

const insertRegisterExhibitorDataBase = async (insert) => {
  const date = new Date();

  const values = [
    insert.id_exhibidor,
    insert.id_exhibidor_tipo,
    insert.id_cliente,
    insert.sucursal,
    insert.ano_exhibidor,
    insert.mes_exhibidor,
    insert.usuario_creacion,
    date.toISOString(),
    date.toISOString(),
    insert.estado,
  ];
  const response = await insertIntoTable(
    DATABASE_STRUCTURE_EXHIBIDOR.tableName,
    DATABASE_STRUCTURE_EXHIBIDOR.columns,
    values
  );
  return response;
};

const updateRegisterExhibitorDataBase = async (updated) => {
  const date = new Date();
  const values = [
    updated.id_exhibidor,
    updated.id_exhibidor_tipo,
    updated.id_cliente,
    updated.sucursal,
    updated.ano_exhibidor,
    updated.mes_exhibidor,
    date.toISOString(),
    updated.estado,
  ];
  const response = await updateTableById(
    DATABASE_STRUCTURE_EXHIBIDOR.tableName,
    DATABASE_STRUCTURE_EXHIBIDOR.columnsEditable,
    values
  );
  return response;
};

export const updateStatusRegisterExhibitorDataBase = async (updated) => {
  const date = new Date();
  const values = [updated.id_exhibidor, updated.estado];
  const response = await updateTableById(
    DATABASE_STRUCTURE_EXHIBIDOR.tableName,
    [
      DATABASE_STRUCTURE_EXHIBIDOR.columnsEditable[0],
      DATABASE_STRUCTURE_EXHIBIDOR.columnsEditable[7],
    ],
    values
  );
  return response;
};

// _______________________________________________________________________________________

const fecthExhibitorTypesFromDataBase = async (page) => {
  const response = await selectAllPaginated(
    DATABASE_STRUCTURE.tableName,
    [
      { column: "estado", order: "DESC" },
      { column: "fecha_creacion", order: "DESC" },
    ],
    page
  );
  return response;
};
const fecthExhibitorTypesFromDataBase2 = async () => {
  const response = await selectAllByTable("exhibidor");
  return response;
};

const updateExhibitorTypeToDataBase = async (updated) => {
  const date = new Date();
  const values = [
    updated.id_exhibidor_tipo,
    updated.nombre_tipo_exhibidor,
    updated.url_imagen_exhibidor,
    date.toISOString(),
  ];
  const response = await updateTableById(
    DATABASE_STRUCTURE.tableName,
    DATABASE_STRUCTURE.columnsEditable,
    values
  );
  return response;
};

export const activateExhibidorTypeToDataBase = async (id) => {
  const date = new Date();
  const values = [id, 1, date.toISOString()];
  const response = await updateTableById(
    DATABASE_STRUCTURE.tableName,
    [
      DATABASE_STRUCTURE.columns[0],
      DATABASE_STRUCTURE.columns[4],
      DATABASE_STRUCTURE.columns[6],
    ],
    values
  );
  return response;
};

const disableTypeToDataBase = async (updated) => {
  const date = new Date();
  const values = [updated, "0"];
  const response = await updateTableById(
    DATABASE_STRUCTURE.tableName,
    ["id_exhibidor_tipo", "estado"],
    values
  );
  return response;
};

const insertExhibitorTypeToDataBase = async (insert) => {
  const date = new Date();

  const values = [
    insert.id_exhibidor_tipo,
    insert.nombre_tipo_exhibidor,
    insert.url_imagen_exhibidor,
    insert.usuario_creacion,
    insert.estado,
    date.toISOString(),
    date.toISOString(),
    insert.id_imagen_exhibidor,
  ];
  const response = await insertIntoTable(
    DATABASE_STRUCTURE.tableName,
    DATABASE_STRUCTURE.columns,
    values
  );
  return response;
};

const deleteExhibitorTypeToDataBase = async (deleted) => {
  const values = [deleted];
  const response = await deleteById(
    DATABASE_STRUCTURE.tableName,
    [DATABASE_STRUCTURE.columns[0]],
    values
  );

  return response;
};
