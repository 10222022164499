import React from "react";
import style from "./css/style.module.css";

export default function Checkbox({ size, onClick, checked, disabled }) {
  return (
    <div className={style.container}>
      <label className={`${style.labelCbx} ${disabled && style.disabled}`}>
        <input
          type="checkbox"
          className={style.invisible}
          checked={checked}
          onChange={onClick}
          disabled={disabled}
        />
        <div
          className={style.checkbox}
          style={{
            width: size ? size : "30px",
            height: size ? size : "30px",
          }}
        >
          <svg
            width={size ? size : "30px"}
            height={size ? size : "30px"}
            viewBox="1 1 18 18"
          >
            <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
            <polyline points="4 11 8 15 16 6"></polyline>
          </svg>
        </div>
      </label>
    </div>
  );
}
