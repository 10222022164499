import { useState } from "react";
import style from "./css/style.module.css";
import { Button } from "react-bootstrap";
import leftPanel from "../../assets/images/MA-TI-TiendaPerfectaApp-ElementosWeb_2ImgLateral.png";
import variablesImg from "../../assets/images/MA-TI-TiendaPerfectaApp-PasoAPasoWeb_2CrearVariablesINFO.png";
import portafolioImg from "../../assets/images/MA-TI-TiendaPerfectaApp-PasoAPasoWeb_3PortafolioIdealINFO.png";
import carasImg from "../../assets/images/MA-TI-TiendaPerfectaApp-PasoAPasoWeb_4CarasCategoriaINFO.png";
import exhibidorImg from "../../assets/images/MA-TI-TiendaPerfectaApp-PasoAPasoWeb_5CrearExhibidorINFO.png";
import registroImg from "../../assets/images/MA-TI-TiendaPerfectaApp-PasoAPasoWeb_6RegistrarExhibidorINFO.png";
import planogramaImg from "../../assets/images/MA-TI-TiendaPerfectaApp-PasoAPasoWeb_7PlanogramasINFO.png";
import glosarioImg from "../../assets/images/MA-TI-TiendaPerfectaApp-PasoAPasoWeb_1GlosarioINFO.png";
import footerImg from "../../assets/images/MA-TI-TiendaPerfectaApp-ElementosWeb_3Footer.png";

const IMAGES = [
  glosarioImg,
  variablesImg,
  portafolioImg,
  carasImg,
  exhibidorImg,
  registroImg,
  planogramaImg,
];
// const IMAGES = [
//   variablesImg,
//   "https://i.stack.imgur.com/y934C.gif",
//   "https://s.yimg.com/ny/api/res/1.2/S_yutVzrrXUUq01uMSETqw--/YXBwaWQ9aGlnaGxhbmRlcjt3PTEyMDA7aD02NzU-/https://s.yimg.com/os/creatr-uploaded-images/2023-02/eae31010-ae41-11ed-b1b5-18a4a74093d7",
//   "https://64.media.tumblr.com/df3741a399ac6804472c4dd0c2ddbbbf/cc73cd8e309c80bf-3e/s1280x1920/91a9d8b83d4ad8dcdd4c8665dc1a04b430e23bdf.gif",
//   "https://i.pinimg.com/originals/9f/f8/d6/9ff8d64f88aedf93452cb077af938453.gif",
//   "https://laughingsquid.com/wp-content/uploads/2018/04/homemade-garlic-bread-in-space.gif",
// ];

export default function Ayuda() {
  const [index, setIndex] = useState(0);
  {
    /* &gt; para el signo > */
  }
  const handleButtonClick = (_index) => {
    setIndex(_index);
  };
  return (
    <div className={style.container}>
      <div
        style={{
          // backgroundImage: `url(${IMAGES[index]})`,
          // backgroundAttachment: "fixed",
          // backgroundColor: "green",
          backgroundSize: " 100% 100%",
          backgroundRepeat: "no-repeat",
          // backgroundPosition: "center center",
          // backgroundAttachment: "fixed",
          // backgroundPosition: "top-center",
          height: "130vh",
          display: "flex",
          // minWidth: "100%",
          // overflowY: "scroll",
        }}
      >
        {/* ---------------------------------------------------------------------------------------------------- */}

        <div
          // className={style.box1}
          style={{
            backgroundImage: `url(${leftPanel})`,
            // backgroundAttachment: "fixed",
            backgroundSize: "cover",
            height: "100%",
            width: "20%",
          }}
        >
          <div className={style.boxButton}>
            <div
              style={{
                // backgroundColor: "green",
                fontSize: "40px",
                color: "white",
                textAlign: "left",
                width: "80%",
                marginBottom: "10px",
              }}
            >
              Paso a paso
            </div>
            {/* <div style={{ width: "80%", color: "white" }}> - Variables</div> */}

            <Button
              className={`${style.btn} ${0 === index && style.active}`}
              onClick={() => handleButtonClick(0)}
            >
              - Glosario
            </Button>

            <Button
              className={`${style.btn} ${1 === index && style.active}`}
              onClick={() => handleButtonClick(1)}
            >
              - Crear Variables
            </Button>
            <Button
              className={`${style.btn} ${2 === index && style.active}`}
              onClick={() => handleButtonClick(2)}
            >
              - Portafolio Ideal
            </Button>
            <Button
              className={`${style.btn} ${3 === index && style.active}`}
              onClick={() => handleButtonClick(3)}
            >
              - Caras Categoría
            </Button>
            {/* <div style={{ width: "80%", color: "white", marginTop: "158px" }}>
          {" "}
          - Exhibidores
        </div> */}
            <Button
              className={`${style.btn} ${4 === index && style.active}`}
              onClick={() => handleButtonClick(4)}
            >
              - Crear Exhibidor
            </Button>
            <Button
              className={`${style.btn} ${5 === index && style.active}`}
              onClick={() => handleButtonClick(5)}
            >
              - Registrar Exhibidor
            </Button>
            <Button
              className={`${style.btn} ${6 === index && style.active}`}
              onClick={() => handleButtonClick(6)}
            >
              - Planogramas
            </Button>
          </div>
        </div>
        {/* ---------------------------------------------------------------------------------------------------- */}
        <div style={{ height: "100%", width: "80%" }}>
          {/* {IMAGES[index] && ( */}
          <img
            style={{
              backgroundColor: "black",
              height: "100%",
              width: "100%",
            }}
            src={IMAGES[index]}
            alt="Imagen seleccionada"
          />
          {/* )} */}
        </div>

        {/* ---------------------------------------------------------------------------------------------------- */}
      </div>
      <div>
        <img
          style={{
            backgroundColor: "black",
            height: "100%",
            width: "100%",
          }}
          src={footerImg}
          alt="Contactos"
        />
      </div>
    </div>
  );
}
