import { Nav, Navbar, Container, NavDropdown, Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { COLORS } from "../theme/theme";
import style from "./css/style.module.css";
import { useState } from "react";
import { ICON_MODERNA, Transition, ROUTES } from "../../imports";
import { BiExit as Exit } from "react-icons/bi";
import { useMsal } from "@azure/msal-react";
import { FaUserAlt as User } from "react-icons/fa";
import { handleCleanUserSection, userLogeado } from "../../util/Utils";

export default function Header() {
  const location = useLocation().pathname;

  const { instance, accounts } = useMsal();

  let names = accounts.length > 0 ? accounts[0]?.name : "Moderna User";
  names = names.split(" ");
  const displayName = `${names[0]} ${names[2] ? names[2] : names[1]}`;

  const handleLogOut = () => {
    window.history.pushState(null, "", "/saliendo");

    handleCleanUserSection();

    // Cierra la sesión
    // instance.logout();

    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  // userLogeado(accounts[0]?.username);  ----para q esta parte??

  return (
    <Navbar
      sticky="top"
      style={{
        backgroundColor: COLORS.MODERNA_RED,
        minHeight: 50,
        // height: "70px",
        zIndex: 999,
      }}
      collapseOnSelect
      expand="lg"
      variant="dark"
    >
      <Container fluid style={{ marginInline: "1rem" }}>
        <Navbar.Brand
          as={Link}
          to={"/"}
          href="#home"
          style={{ marginTop: -10, marginBottom: -10 }}
        >
          <img
            alt="moderna logo"
            src={ICON_MODERNA}
            height="55"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="justify-content-end flex-grow-1 pe-3"
            style={{ marginBottom: "-18px" }}
          >
            <DropOption
              options={ROUTES.VARIABLES}
              active={location.includes("variables")}
            >
              Variables
            </DropOption>
            <DropOption
              options={ROUTES.EXHIBIDORES}
              active={location.includes("exhibidores")}
            >
              Exhibidores
            </DropOption>
            <DropOption active={location.includes("ayuda")}>Ayuda</DropOption>
          </Nav>
          <Nav>
            <div
              style={{
                width: "10rem",
                // marginTop: "5px",
                fontSize: "16px",
                color: "white",
                justifyContent: "end",
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                // backgroundColor: "black",
                marginBottom: "8px",
              }}
            >
              <div style={{ textAlign: "right" }}>
                <User
                  style={{
                    marginBottom: "3px",
                    transform: "",
                    marginRight: "5px",
                  }}
                />
                {displayName}
              </div>
              <Nav.Link
                onClick={handleLogOut}
                style={{ cursor: "pointer", margin: "0px", padding: "0px" }}
              >
                Cerrar sesión &nbsp;
                <Exit />
              </Nav.Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );

  function DropOption({ children, options, active, as }) {
    const [open, setOpen] = useState(false);
    return (
      <div>
        <div className="d-block d-md-block d-lg-none">
          {options ? (
            <NavDropdown title={children}>
              {options?.map((option, id) => {
                return (
                  <Dropdown.Item key={id} as={Link} to={option.link}>
                    <DropdownItem option={option} />
                  </Dropdown.Item>
                );
              })}
            </NavDropdown>
          ) : (
            <Nav.Link
              as={Link}
              to={children.toLowerCase()}
              active={location.includes(children.toLowerCase())}
              className={`${location.includes(children) && style.activeOption}`}
              style={{ marginBottom: "1.5rem" }}
            >
              {children}
            </Nav.Link>
          )}
        </div>
        <div
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
          className="d-none d-md-none d-lg-block"
        >
          <Nav.Link
            as={Link}
            to={options ? undefined : children.toLowerCase()}
            active={false}
            className={`${active && style.active}`}
          >
            {children}
          </Nav.Link>
          <Transition activator={open}>
            <DropdownMenu options={options} />
          </Transition>
        </div>
      </div>
    );
  }
  function DropdownItem({ option }) {
    return (
      <Nav.Item
        className={`${style.menuItem} ${
          location.includes(option.link) && style.activeOption
        }`}
      >
        {option.name}
      </Nav.Item>
    );
  }
  function DropdownMenu({ options }) {
    return (
      <div className={style.dropdown}>
        {options?.map((option, id) => {
          return (
            <div key={id}>
              <Nav.Item
                as={Link}
                to={option.link}
                style={{ textDecoration: "none" }}
              >
                <DropdownItem option={option} />
              </Nav.Item>
            </div>
          );
        })}
      </div>
    );
  }
}
