import React, { useState } from "react";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

export default function Alert({
  children,
  title,
  message,
  direction,
  tooltip,
  disabled,
  show,
}) {
  const [isShown, setIsShown] = useState(false);
  const handleMouseEnter = () => {
    setIsShown(true);
  };

  const handleMouseLeave = () => {
    setIsShown(false);
  };

  const handleFocus = () => {
    setIsShown(true);
  };

  const handleBlur = () => {
    setIsShown(false);
  };

  const tooltipover = (
    <Tooltip id="tooltip-disabled">{message ? message : title}</Tooltip>
  );

  const popover = (
    <Popover id="popover-basic">
      {title && <Popover.Header as="h3">{title}</Popover.Header>}
      {message && <Popover.Body>{message}</Popover.Body>}
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger
        placement={direction ? direction : "right"}
        overlay={tooltip ? tooltipover : popover}
        show={isShown && !disabled}
      >
        {React.cloneElement(children, {
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
          onFocus: handleFocus,
          onBlur: handleBlur,
        })}
      </OverlayTrigger>
    </div>
  );
}
