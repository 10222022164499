import React, { useEffect, useMemo, useRef, useState } from "react";
import { Select, Spin } from "antd";
import { AiOutlineSearch as Search } from "react-icons/ai";

import debounce from "lodash/debounce";
import style from "./style.module.css";
// import { Modal } from "antd";

function DebounceSelect({
  onChange,
  value,
  onSelect,
  fetchOptions,
  debounceTimeout = 800,
  disabled,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      disabled={disabled}
      showSearch
      value={value ? value.value : ""}
      onChange={(value) => {
        {
          try {
            const resC = options.filter((item) => item.value == value);
            onSelect(resC[0]);
          } catch (e) {
            onSelect(value);
          }
        }
      }}
      suffixIcon={<Search style={{ color: "black" }} />}
      // className={style.searchInput}
      style={{ width: "100%" }}
      placeholder="Buscar cliente"
      optionFilterProp="children"
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? (
          <Spin size="small" />
        ) : !options ? (
          <div>Sin resultados</div>
        ) : null
      }
      filterOption={false}
      options={options}
    />
  );
}

const SelectSearchFetch = ({ value, onSearchClient, onSelect, disabled }) => {
  return (
    <DebounceSelect
      disabled={disabled}
      placeholder="Select users"
      value={value}
      fetchOptions={onSearchClient}
      onSelect={onSelect}
      style={{ width: "100%" }}
    />
  );
};

export default SelectSearchFetch;
