import { Checkbox, CrudButton, DropButton, Row } from "../../../../../imports";
import { COLORS } from "../../../../../common/theme/theme";
import style from "./css/style.module.css";
import Swal from "sweetalert2";
import { handleCheckAccessValidation } from "../../../../../service/AccessValidation";
import { useContext } from "react";
import { ModernaContext } from "../../../../../Context/ModernaContext";

export default function DropdownVariable({
  variable,
  remove,
  edite,
  subvariables,
  checkSubvariables,
  handleModified,
  variables,
  varPrecioRef,
  varPortafolioRef,
  setLoadingBack,
}) {
  const { handleShowAlert } = useContext(ModernaContext);

  const alertError = (text) => {
    Swal.fire({
      icon: "error",
      // title: "Oops...",
      text: text,
      confirmButtonText: "Aceptar",
      confirmButtonColor: COLORS.MODERNA_RED,
      // footer: '<a href="">Why do I have this issue?</a>'
    });
  };

  const handleSelectVariable = async () => {
    await handleCheckAccessValidation(
      setLoadingBack,
      () => {
        if (
          variable.nombre_variable === varPrecioRef.current &&
          variable.estado_variable !== true &&
          variable.estado_variable !== 1
        ) {
          const index = variables.findIndex((_var) => {
            return _var.nombre_variable === varPortafolioRef.current;
          });
          if (index === -1) {
            alertError(
              `No puede estar registrada la variable "${varPrecioRef.current}" si no está registrada la variable "${varPortafolioRef.current}"`
            );
            return;
          }
          if (
            variables[index].estado_variable !== 1 &&
            variables[index].estado_variable !== true
          ) {
            alertError(
              `No puede estar activa la variable "${varPrecioRef.current}" si la variable "${varPortafolioRef.current}" está desactivada`
            );
            return;
          }
        }

        if (
          variable.nombre_variable === varPortafolioRef.current &&
          (variable.estado_variable === true || variable.estado_variable === 1)
        ) {
          const index = variables.findIndex((_var) => {
            return _var.nombre_variable === varPrecioRef.current;
          });
          if (
            index !== -1 &&
            (variables[index].estado_variable === 1 ||
              variables[index].estado_variable === true)
          ) {
            alertError(
              `No puede desactivar la variable "${varPortafolioRef.current}" si la variable "${varPrecioRef.current}" se encuentra activa`
            );
            return;
          }
        }

        if (!variable.estado_variable) {
          if (!Array.isArray(subvariables)) {
            return;
          }
          // let percentage = 0;
          // const selected = subvariables?.map((item) => {
          //   //item.estado_subvariable = 1;
          //   //if (item.estado_subvariable === 1 || item.estado_subvariable === true) {
          //   percentage += item.porcentaje_subvariable;
          //   //}
          //   return item;
          // });
          // variable.porcentaje_variable = percentage;
          // variable.subvariables = selected;
        }
        variable.estado_variable = variable.estado_variable ? 0 : 1;
        if (handleModified) {
          handleModified(
            `CHECKED${variable.id_variable}${variable.estado_variable}`
          );
        }
        if (checkSubvariables) {
          checkSubvariables(variable);
        }
      },
      handleShowAlert
    );
  };

  const handleSingleSelect = (e) => {
    let percentage = 0;
    const selected = subvariables.map((item) => {
      if (item == e) {
        item.estado_subvariable = item.estado_subvariable == 1 ? 0 : 1;
      }
      //if (item.estado_subvariable === 1 || item.estado_subvariable === true) {
      percentage += item.porcentaje_subvariable;
      //}
      return item;
    });

    if (handleModified) {
      handleModified(`CHECKED${e.id_subvariable}${e.estado_subvariable}`);
    }
    if (checkSubvariables) {
      const _var = variable;
      _var.subvariables = selected;
      _var.porcentaje_variable = percentage;
      checkSubvariables(_var);
    }
  };

  const handleDelete = () => {
    if (remove) {
      remove(variable);
    }
  };
  const handleEdite = () => {
    if (edite) {
      edite(variable);
    }
  };

  const CrudButtons = ({ weight }) => {
    return (
      <div className={style.icon_group}>
        <label style={{ fontWeight: "500" }}>
          {weight ? `${weight}%` : "0%"} &nbsp;
        </label>
        <CrudButton
          background={COLORS.MODERNA_YELLOW}
          size={30}
          edit
          onClick={handleEdite}
        />
        <CrudButton
          background={COLORS.MODERNA_YELLOW}
          size={30}
          del
          onClick={handleDelete}
        />
        <Checkbox
          onClick={handleSelectVariable}
          checked={variable.estado_variable}
        />
      </div>
    );
  };
  const RowCrudButtons = ({ element }) => {
    return (
      <div className={style.icon_group}>
        <label>
          {element?.porcentaje_subvariable
            ? `${element.porcentaje_subvariable}%`
            : "0%"}
          &nbsp;
        </label>
        {/* <Checkbox //----------------el check se subvariable
          checked={element?.estado_subvariable}
          onClick={() => handleSingleSelect(element)}
        /> */}
      </div>
    );
  };
  return (
    <DropButton
      title={variable?.nombre_variable}
      buttons={
        <CrudButtons weight={variable.porcentaje_variable} element={variable} />
      }
      single={variable?.subvariables?.length === 0}
    >
      {subvariables?.map((item, id) => {
        return (
          <div key={id}>
            <Row
              right={
                <RowCrudButtons
                  element={item}
                  onSelect={(e) => {
                    handleSingleSelect(e);
                  }}
                />
              }
            >
              {item?.nombre_subvariable}
            </Row>
          </div>
        );
      })}
    </DropButton>
  );
}
