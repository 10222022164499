import React, { forwardRef, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import style from "./style.module.css";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { BsChevronDown as Down } from "react-icons/bs";
registerLocale("es", es);

export default function DateDrop({
  label,
  setMonth,
  setYear,
  date,
  errorMessage,
  disabled,
  setDate,
}) {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className={style.dateInput} onClick={onClick} ref={ref}>
      {value ? value : "Selecciona una fecha"}
      <Down color="black" style={{ margin: "0px 1px", width: "13px" }} />
    </button>
  ));

  const handleChangeDate = (startDate) => {
    if (startDate) {
      const month = startDate.getMonth() + 1;
      const year = startDate.getFullYear();
      setDate({ month, year });
    }
  };

  return (
    <div className={style.box}>
      <Row>
        <Col>
          <Row className={style.item}>
            {label ? (
              <Col style={{ display: "flex", flex: "1" }}>
                <label className={style.lab}>{label ? label : "Nombre"}</label>
              </Col>
            ) : (
              ""
            )}
            <Col
              style={{
                flex: "4",
                marginBottom: "2px",
              }}
            >
              <DatePicker
                disabled={disabled}
                locale="es"
                selected={
                  date
                    ? new Date(parseInt(date.year), parseInt(date.month) - 1, 1)
                    : undefined
                }
                onChange={(e) => handleChangeDate(e)}
                dateFormat="MM/yyyy"
                customInput={<ExampleCustomInput />}
                showMonthYearPicker
                minDate={new Date()}
              />
              <p className={style.errorText}>{errorMessage}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
