import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "./css/style.module.css";
import {
  ButtonModerna,
  Checkbox,
  CrudButton,
  Loading,
  Pagination,
} from "../../../imports";
import { COLORS } from "../../../common/theme/theme";
import Input from "../../../common/inputModerna/input";
import DropSearch from "../../../common/dropdownSearch/dropSearch";
import SelectDrop from "./components/Select/select";
import DateDrop from "./components/dateDrop/dateDrop";
import {
  deleteRegisterExhibitor,
  editRegisterExhibitor,
  fetchExhibitorTypes,
  fetchRegisterExhibidoresClients,
  fetchRegisterExhibidoresFromDataBase,
  registerExhibitor,
  updateStatusRegisterExhibitorDataBase,
} from "../../../service/Exhibidor";
import { fetchClient } from "../../../service/ClientService";
import { postAzure } from "../../../service/backend/RestExecutor";
import { ConstantsModerna } from "../../../common/constants/ConstantsModerna";
import {
  handleCheckUserSection,
  handleCleanUserSection,
} from "../../../util/Utils";
import { ModalModernaAntd } from "../../../common/modal/ModalAntd";
import { ModernaContext } from "../../../Context/ModernaContext";
import { useMsal } from "@azure/msal-react";
import { handleCheckAccessValidation } from "../../../service/AccessValidation";
import Loading2 from "../../../common/loading2/loading2";
import { SweetAlert } from "../../../common/sweet-alert/sweet-alert";
import SearchDrop from "../../variables/portafolio/components/search";

export default function Newexhibidor() {
  const { handleShowAlert } = useContext(ModernaContext);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [data, setData] = useState([]);
  const [cliente, setCliente] = useState("");
  const [sucursal, setSucursal] = useState("");
  const [fechaMes, setFechames] = useState("");
  const [fechAnio, setFechanio] = useState("");
  const [tipo, setTipo] = useState("");
  const [id, setId] = useState("");
  const [idExhibidor, setidExhibidor] = useState("");
  const [idExhibidorTipo, setIdExhibidorTipo] = useState("");
  const [idClient, setIdClient] = useState("");
  const [editing, setEditing] = useState(false);
  const [date, setDate] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [errorNombre, setErrorNombre] = useState("");
  const [errorSucursal, setErrorSucursal] = useState("");
  const [errorTipo, setErrorTipo] = useState("");
  const [exhibidorsType, setExhibidorsType] = useState([]);
  const [errorDate, setErrorDate] = useState("");
  const [cargando, setCargando] = useState(false);
  const [loading, setLoading] = useState(false);
  const { accounts } = useMsal();
  const [loadingBack, setLoadingBack] = useState(false);
  const [clientesSearch, setClienteSearch] = useState([]);
  const [clientesSearchBox, setClienteSearchBox] = useState();
  const [itemsRegister, setItemsRegister] = useState(0);
  const [estadoRegister, setEstadoRegister] = useState(false);
  let clientsAllRef = useRef([]);
  const [registroDuplicado, setRegistroDuplicado] = useState();
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState();
  const [typeOptions, setTypeOptions] = useState([]);
  const [clientRegister, setclientRegister] = useState([]);
  const [exhibidoresTrue, setexhibidoresTrue] = useState([]);
  const Swal = require("sweetalert2");

  const alertDelete = (id) => {
    Swal.fire({
      text: "¿Estás seguro de eliminar este registro?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      cancelButtonColor: COLORS.MODERNA_YELLOW,
      confirmButtonColor: COLORS.MODERNA_RED,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      } else {
        handleCleanUserSection();
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [page, clientesSearchBox]);

  useEffect(() => {
    fetchClientt();
    fetchDataTypesExhib();
  }, []);

  const fetchClientt = async () => {
    const clientes = await fetchClient();
    setClienteSearch(clientes);
  };

  const alertError2 = (text) => {
    Swal.fire({
      icon: "error",
      // title: "Oops...",
      text: text,
      confirmButtonText: "Aceptar",
      confirmButtonColor: COLORS.MODERNA_RED,
      // footer: '<a href="">Why do I have this issue?</a>'
    });
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      const nameClient = clientesSearchBox?.label?.split("- ")[1];
      const registerExhi = await fetchRegisterExhibidoresFromDataBase(
        page,
        nameClient
      );
      setData(registerExhi?.data);
      setPageData({
        limit: registerExhi?.limit,
        page: registerExhi?.page + 1,
        total_elements: registerExhi?.total_elements,
        total_pages: registerExhi?.total_pages,
      });
    } catch (error) {}
    setLoading(false);
  };

  const filterArray2 = (array, value) => {
    const filteredArray = array.filter(
      (item) => item?.nombre_cliente === value
    );
    return filteredArray;
  };

  const fetchDataTypesExhib = async () => {
    const result = await fetchExhibitorTypes();
    // console.log(result.data);
    const objetosConEstadoTrue = result?.data.filter(
      (objeto) => objeto.estado === true
    );
    const valuesTrue = Array.isArray(objetosConEstadoTrue)
      ? objetosConEstadoTrue.map((item) => {
          return { label: item?.nombre_tipo_exhibidor, value: item };
        })
      : [];

    const values = Array.isArray(result.data)
      ? result.data.map((item) => {
          return { label: item?.nombre_tipo_exhibidor, value: item };
        })
      : [];
    setexhibidoresTrue(valuesTrue);
    setExhibidorsType(values);
    return values;
  };

  const rtf = (id) => {
    const _filtered = exhibidorsType.filter((item) => {
      // console.log(item);

      return item?.value.estado || item?.value.id_exhibidor_tipo === id;
    });
    setTypeOptions(_filtered);
  };

  const filterArray = (array, value) => {
    if (!Array.isArray(array)) {
      return array;
    }
    const filteredArray = array?.filter((item) => item?.estado === value);
    return filteredArray;
  };

  const handleDelete = async (e) => {
    setLoadingBack(true);
    try {
      await deleteRegisterExhibitor(e);
    } catch (error) {
      alertError2("Error en el servidor, intentalo de nuevo");
    } finally {
      setLoadingBack(false);
    }
    fetchData();
  };

  const handleSelect = (selectedValue) => {
    setTipo(selectedValue);
    setErrorTipo("");
  };

  const handleSubmit = async () => {
    if (validarCampos()) {
      return;
    }
    const REGISTER_EXHIBITOR = {
      id_exhibidor: idExhibidor,
      id_exhibidor_tipo: tipo?.id_exhibidor_tipo
        ? tipo?.id_exhibidor_tipo
        : idExhibidorTipo,
      id_cliente: cliente?.value,
      sucursal: sucursal,
      ano_exhibidor: date.year,
      mes_exhibidor: date.month,
      usuario_creacion: accounts[0].username,
      estado: editing ? estadoRegister : true,
    };

    const index = data.findIndex((item) => {
      return (
        item.id_exhibidor != REGISTER_EXHIBITOR.id_exhibidor &&
        item.sucursal.toLocaleUpperCase() ===
          REGISTER_EXHIBITOR.sucursal.toLocaleUpperCase() &&
        item.id_exhibidor_tipo === REGISTER_EXHIBITOR.id_exhibidor_tipo &&
        item.ano_exhibidor === REGISTER_EXHIBITOR.ano_exhibidor &&
        item.mes_exhibidor === REGISTER_EXHIBITOR.mes_exhibidor &&
        item.id_cliente === REGISTER_EXHIBITOR.id_cliente
      );
    });

    if (index != -1) {
      setRegistroDuplicado("* Ya existe un registro con esos valores");
      return;
    }
    setCargando(true);
    setLoadingBack(true);
    try {
      if (editing) {
        // console.log("mis datos en edicion::::::", REGISTER_EXHIBITOR);
        await editRegisterExhibitor(REGISTER_EXHIBITOR);
      } else {
        await registerExhibitor(REGISTER_EXHIBITOR);
      }
    } catch (error) {
      alertError2("Error en el servidor, intentalo de nuevo");
    }

    setLoadingBack(false);
    setClienteSearchBox("");
    setCargando(false);
    setShowCreate(false);
    fetchData();
    setDate(undefined);
    setCliente(null);
    setSucursal("");
    setTipo("");
    handleCleanUserSection();
  };

  const statusRegister = async (item) => {
    const REGISTER_EXHIBITOR = {
      id_exhibidor: item?.id_exhibidor,
      estado: !item?.estado,
    };

    try {
      await updateStatusRegisterExhibitorDataBase(REGISTER_EXHIBITOR);
    } catch (error) {
      alertError2("Error en el servidor, intentalo de nuevo");
    }

    fetchData();
    handleCleanUserSection();
  };
  const handleEdit = async (e) => {
    setEditing(true);
    setidExhibidor(e.id_exhibidor);
    setIdClient(e.id_cliente);
    setCliente({
      label: `${e?.id_cliente} - ${e?.nombre_cliente}`,
      value: e.id_cliente,
    });
    setSucursal(e.sucursal);
    setIdExhibidorTipo(e.id_exhibidor_tipo);
    setDate({ year: e.ano_exhibidor, month: e.mes_exhibidor });
    setTipo(e.nombre_tipo_exhibidor);
    setEstadoRegister(e.estado);
    setShowCreate(true);
  };

  const capitalize = (value) => {
    if (typeof value !== "string") {
      return value;
    }
    return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
  };

  const validarCampos = () => {
    let validate = false;

    if (sucursal === "" || sucursal === null) {
      setErrorSucursal("* Este campo sucursal es obligatorio");
      validate = true;
    }

    if (tipo === "" || tipo === null) {
      setErrorTipo("* Este campo tipo es obligatorio");
      validate = true;
    }

    if (!cliente) {
      setErrorNombre("* Debes seleccionar un cliente");
      validate = true;
    }
    if (!date || !date.month || !date.year) {
      setErrorDate("* Debes seleccionar la fecha");
      validate = true;
    }

    return validate;
  };
  const onSearchClient = async (value) => {
    if (value.length <= 0) {
      return [];
    }
    const options = {
      method: "POST",
      body: JSON.stringify({
        typeQuery: "LIKE",
        data: {
          comand: "OR",
          tableName: "cliente",
          fieldType: ["id_cliente", "nombre_cliente"],
          fieldData: [value, value],
        },
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    return fetch(ConstantsModerna.enpoints.generalFunction, options)
      .then((response) => response.json())
      .then((body) => {
        if (body.data.length > 0) {
          return body.data.map((user) => ({
            label: `${user?.id_cliente} - ${user?.nombre_cliente}`,
            value: user.id_cliente,
          }));
        } else {
          return null;
        }
      })
      .catch(() => {
        return null;
      });
  };
  const comprobarFecha = (an, me) => {
    const fechaActual = new Date();
    const añoActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1;
    if (an < añoActual || (añoActual === an && me < mesActual)) {
      return true;
    }
    return false;
  };

  const today = new Date();

  if (loading) {
    return <Loading />;
  }
  const handleUserSection = async () => {
    const isAble = await handleCheckUserSection();
    if (isAble) {
      setShowCreate(true);
      setFechames(undefined);
      setFechanio(undefined);
      setRegistroDuplicado("");
      // setTipo("");
      setDate(undefined);
    } else {
      handleShowAlert(ConstantsModerna.messages.USER_EDITING);
    }
  };
  return (
    <Container>
      <ModalModernaAntd
        show={showCreate}
        close={async () => {
          if (
            !editing &&
            (sucursal?.length > 1 || cliente?.label || date || tipo)
          ) {
            const confirm = await SweetAlert(
              "Tiene cambios sin guardar. ¿Desea salir?"
            );
            if (!confirm) {
              return;
            }
          }
          setShowCreate(cargando);
          setCliente(null);
          setSucursal("");
          setRegistroDuplicado("");
          setFechames(undefined);
          setFechanio(undefined);
          setDate(undefined);
          setErrorNombre("");
          setErrorSucursal("");
          setErrorTipo("");
          setEditing(false);
          setErrorDate("");
          setTipo(null);
          handleCleanUserSection();
        }}
        title={editing ? "Editar Exhibidor" : "Registrar Exhibidor"}
        buttons
        accept={handleSubmit}
      >
        <div className="containerModalAnt">
          <DropSearch
            disabled={cargando}
            label="Cliente"
            value={cliente}
            onSearchClient={onSearchClient}
            onSelect={(e) => {
              setCliente(e);
              setErrorNombre("");
            }}
            errorMessage={errorNombre}
          />

          <Input
            label="Nombre"
            value={cliente?.label ? cliente.label.split("- ")[1] : ""}
            block
          />
          <Input
            label="Sucursal"
            value={sucursal}
            maxLength={20}
            onChange={(e) => {
              setSucursal(e.target.value);
              setErrorSucursal("");
            }}
            errorMessage={errorSucursal}
            block={cargando}
          />

          <DateDrop
            // disabled={cargando}
            setDate={(e) => {
              setDate(e);
              setErrorDate("");
            }}
            label="Fecha"
            date={date}
            errorMessage={errorDate}
          />

          <SelectDrop
            disabled={cargando}
            options={editing ? typeOptions : exhibidoresTrue}
            onSelect={handleSelect}
            selected={tipo}
            label="Tipo"
            errorMessage={errorTipo}
          />
          <p className={style.errorText2}>{registroDuplicado}</p>
        </div>
      </ModalModernaAntd>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // backgroundColor: "black",
          // alignItems: "flex-end",
        }}
      >
        <SearchDrop
          data={clientesSearch}
          placeHolder={"Filtrar cliente"}
          type={"filter"}
          onSelect={(e) => {
            setPage(1);

            setClienteSearchBox(e);
          }}
          value={clientesSearchBox}
          // onCancel={() => {}}
          // onClear={clientesSearchBox}

          // disabled={!selectedClient}
        />
        <ButtonModerna
          secondary
          onClick={async () => {
            await handleCheckAccessValidation(
              setLoadingBack,
              () => {
                handleUserSection();
              },
              handleShowAlert
            );
          }}
          add
        >
          Registrar Exhibidor
        </ButtonModerna>
      </div>
      <Row className={style.tableContainer}>
        <Col>
          <div className={style.tableResponsive}>
            <table className="table table-borderless">
              <thead>
                <tr className={style.title}>
                  <th className="col-0">Id</th>
                  <th className="col-3">Nombre</th>
                  <th className="col-3">Sucursal</th>
                  <th className="col-0">Mes</th>
                  <th className="col-0">Año</th>
                  <th className="col-2">Tipo</th>
                  <th className="col-1">Acciones</th>
                </tr>
              </thead>
              {/* ----------------------parte de los campos de la base de datos---------------- */}

              <tbody>
                {data?.map((data, index) => {
                  return (
                    <tr key={index} className={style.content}>
                      <td>{data ? data?.id_cliente : "idCliente"}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {capitalize(data ? data?.nombre_cliente : "nombre")}
                      </td>
                      <td>{data ? data?.sucursal : "sucursal"}</td>
                      <td>{data ? data?.mes_exhibidor : "sucursal"}</td>
                      <td>{data ? data?.ano_exhibidor : "sucursal"}</td>
                      <td>{data ? data.nombre_tipo_exhibidor : "exhibidor"}</td>
                      {/* <td className={style.staticColumn}> */}
                      <td className="col-1">
                        <div
                          className={style.buttonBox}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {!comprobarFecha(
                            data?.ano_exhibidor,
                            data?.mes_exhibidor
                          ) && (
                            <>
                              {data?.promociones.length == 0 && (
                                <>
                                  <CrudButton
                                    background={COLORS.MODERNA_YELLOW}
                                    size={30}
                                    color="white"
                                    edit
                                    onClick={async () => {
                                      await handleCheckAccessValidation(
                                        setLoadingBack,
                                        () => {
                                          handleEdit(data);
                                          rtf(data.id_exhibidor_tipo);
                                        },
                                        handleShowAlert
                                      );
                                    }}
                                  />
                                  <CrudButton
                                    background={COLORS.MODERNA_YELLOW}
                                    size={30}
                                    color="white"
                                    del
                                    onClick={async () => {
                                      await handleCheckAccessValidation(
                                        setLoadingBack,
                                        () => {
                                          alertDelete(data.id_exhibidor);
                                        },
                                        handleShowAlert
                                      );
                                    }}
                                  />
                                </>
                              )}
                              {data?.ano_exhibidor == today.getFullYear() &&
                              data.mes_exhibidor == today.getMonth() + 1 ? (
                                <Checkbox
                                  checked={data?.estado}
                                  onClick={() => statusRegister(data)}
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {data?.length === 0 && clientesSearchBox ? (
              <div
                style={{
                  borderTopWidth: "1px",
                  borderTopColor: "gray",
                  borderTopStyle: "solid",
                  paddingTop: "5px",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <label>
                  No existen exhibidores registrados para el cliente{" "}
                  {clientesSearchBox.label}
                </label>
              </div>
            ) : (
              <Pagination setPage={setPage} data={pageData} />
            )}
          </div>
        </Col>
      </Row>
      {loadingBack && <Loading2 />}
    </Container>
  );
}
