import { axios } from "../imports";

export const PORTFOLIO_CODE_CATALOG = "P1";
export const PRECIO_CODE_CATALOG = "P2";

const VARIABLES = [];
const API_VARIABLE_URL =
  "https://fotoexito1.azurewebsites.net/api/functionVariable?code=cuJYasCyoA-7iP1IujrhGQROes-QI4pXPiCpANpU0LcEAzFuMXVUVg==";

export const ID_PREFIX = "%ID%_PREFIX";

const DATABASE_STRUCTURE = {
  name: "variable",
  columns: [
    "id_variable",
    "id_grupo_cliente",
    "nombre_variable",
    "estado_variable",
    "porcentaje_variable",
    "usuario_creacion",
    "fecha_creacion",
    "fecha_modificacion",
  ],
  columnsEditable: [
    "id_variable",
    "id_grupo_cliente",
    "nombre_variable",
    "estado_variable",
    "porcentaje_variable",
    "fecha_modificacion",
  ],
  foreingFields: [
    {
      tableName: "subvariable",
      data: [
        "id_subvariable",
        "nombre_subvariable",
        "porcentaje_subvariable",
        "estado_subvariable",
        "fecha_creacion",
        "fecha_modificacion",
      ],
      foreingKey: "id_variable",
    },
  ],
};

export const fetchVariables = async (ClientGroup) => {
  const response = await fetchVariablesFromDataBase(ClientGroup); //
  return response?.data?.data;
};

export const saveVariables = async (variables, toDelete) => {
  const response = await saveVariablesToDataBase(variables, toDelete);
  return response.data;
};

export const deleteVariable = async (variable) => {
  VARIABLES = VARIABLES.filter((item) => item !== variable);
  return fetchVariables(variable.clientType);
};

export const editeVariable = async (original, variable) => {
  VARIABLES = VARIABLES.map((item) => {
    if (item === original) {
      return variable;
    }
    return item;
  });
  return fetchVariables(variable.clientType);
};

/////////////////////////////functions to comunicate with database

const saveVariablesToDataBase = async (variables, toDelete) => {
  // console.log(variables);
  let response;
  try {
    const data = {
      typeQuery: "INSERT",
      data: { variables: variables, toDelete: toDelete },
    };
    response = await axios.post(API_VARIABLE_URL, data);
    return response;
  } catch (e) {
    response = e;
  }
  return response;
};

const fetchVariablesFromDataBase = async (clientGroup) => {
  let response;
  try {
    const data = {
      typeQuery: "SELECT",
      data: {
        fieldType: [DATABASE_STRUCTURE.columns[1]],
        fieldData: [clientGroup],
      },
    };
    response = await axios.post(API_VARIABLE_URL, data);
    return response;
  } catch (e) {
    response = e;
  }
  return response;
};
