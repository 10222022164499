import { ConstantsModerna } from "../common/constants/ConstantsModerna";
import { PAGINATION_CONFIG } from "../config/app-config";
import { axios } from "../imports";
import {
  insertIntoTable,
  postToDataBaseJoin,
  selectAllByTable,
  updateTableById,
} from "./AxiosService";
import { postAzure } from "./backend/RestExecutor";
const URL_CARAS =
  "https://fotoexito1.azurewebsites.net/api/functioncaras?code=DC0id2VlDq0yyf99tgR3xUInG7gW0sIOU4ipKIBAJ-VxAzFuHRJbig%3D%3D";

let CATEGORIES = [
  {
    year: "2023",
    month: "enero",
    typeClient: "ASI",
    typeCategory: "Avena",
    metaActual: "14",
    marketShare: "50",
  },
  {
    year: "2023",
    month: "mayo",
    typeClient: "MAYORISTA",
    typeCategory: "Harina",
    metaActual: "23",
    marketShare: "21",
  },
  {
    year: "2023",
    month: "junio",
    typeClient: "ASI",
    typeCategory: "Pan",
    metaActual: "10",
    marketShare: "63",
  },
  {
    year: "2023",
    month: "mayo",
    typeClient: "MAYORISTA",
    typeCategory: "Harina 2",
    metaActual: "11",
    marketShare: "11",
  },
];
export default CATEGORIES;

const TABLE_STRUCTURE = {
  name: "categoria",
  rows: [
    "id_categoria",
    "nombre_categoria",
    "usuario_creacion",
    "fecha_creacion",
    "fecha_modificacion",
  ],
};

export const fetchCategoryAsLabelValue = async () => {
  const response = await selectAllByTable(
    TABLE_STRUCTURE.name,
    TABLE_STRUCTURE.rows[1]
  );
  const values = Array.isArray(response)
    ? response.map((item) => {
        return {
          label: item.id_categoria + " - " + item.nombre_categoria,
          value: item,
        };
      })
    : [];
  return values;
};

export const fetchCategoryAsLabelValue2 = async () => {
  const response = await selectAllByTable(
    TABLE_STRUCTURE.name,
    TABLE_STRUCTURE.rows[1]
  );
  const values = Array.isArray(response)
    ? response.map((item) => {
        return {
          label: item.nombre_categoria,
          value: item,
        };
      })
    : [];
  return values;
};

export const fetchCategories = async (
  month,
  year,
  clientGroup,
  typeCategory
) => {
  let response = CATEGORIES;
  return response;
  if (typeCategory) {
    response = CATEGORIES.filter((variable) =>
      variable.typeCategory.toLowerCase() === typeCategory.toLowerCase() &&
      month
        ? variable.month?.toLowerCase() === month?.toString().toLowerCase()
        : true && year
        ? variable.year?.toString().toLowerCase() === year.toString()
        : true && clientGroup
        ? variable.typeClient.toLowerCase() === clientGroup.toLowerCase()
        : true
    );
  }
  return response;
};

export const editCategory = async (typeCategory, newData) => {
  CATEGORIES = CATEGORIES.map((item) => {
    if (typeCategory === item.typeCategory) {
      return { ...item, ...newData };
    }
    return item;
  });
  return CATEGORIES;
};

// ___________________________Funciones Azure para Caras Categoreia__________________________

const DATABASE_STRUCTURE_CC = {
  tableName: "caras_categoria",
  columns: [
    "id_caras_categoria",
    "id_grupo_cliente",
    "id_categoria",
    "market_actual",
    "market_share",
    "ano_porcentaje",
    "mes_porcentaje",
    "usuario_creacion",
    "fecha_creacion",
    "fecha_modificacion",
  ],
  foreingFields: [
    {
      tableName: "grupo_cliente",
      data: ["nombre_grupo_cliente"],
      foreingKey: "id_grupo_cliente",
    },
    {
      tableName: "categoria",
      data: ["nombre_categoria"],
      foreingKey: "id_categoria",
    },
  ],
  columnsEditable: [
    "id_caras_categoria",
    "id_grupo_cliente",
    "id_categoria",
    "market_actual",
    "market_share",
    "ano_porcentaje",
    "mes_porcentaje",
    // "usuario_creacion",
    // "fecha_creacion",
    "fecha_modificacion",
  ],
};

const TABLE_STRUCTURE_CATEGORY = {
  name: "categoria",
  columns: [
    "id_categoria",
    "nombre_categoria",
    "usuario_creacion",
    "fecha_creacion",
    "fecha_modificacion",
  ],
};

export const fetchCarasCategoria = async () => {
  return await fetchCarasCategoriaDataBaseJoin();
};

const fetchCarasCategoriaDataBase = async () => {
  const response = await selectAllByTable(DATABASE_STRUCTURE_CC.tableName);
  return response;
};

export const fetchCategoriaAsLabelValue = async () => {
  const response = await selectAllByTable(
    TABLE_STRUCTURE_CATEGORY.name,
    TABLE_STRUCTURE_CATEGORY.columns[1]
  );
  const values = Array.isArray(response)
    ? response.map((item) => {
        return {
          label: `${item.id_categoria} - ${item.nombre_categoria}`,
          value: item,
        };
      })
    : [];
  return values;
};

export const fetchCarasCategoriaAllDataBase = async () => {
  const response = await selectAllByTable("categoria", "nombre_categoria");
  return response;
};
export const fetchCarasCategoriaOrderedAllDataBase = async (
  id_grupo_cliente,
  year,
  month,
  page,
  categoria
) => {
  let response;
  try {
    const data = {
      typeQuery: "CARAS",
      data: {
        id_grupo_cliente,
        year,
        month,
        page,
        limit: PAGINATION_CONFIG.LIMIT,
        id_categoria: categoria,
      },
    };
    response = await axios.post(URL_CARAS, data);
    // console.log(response);
    return response.data;
  } catch (e) {
    response = e;
  }
  return response;
};

const fetchCarasCategoriaDataBaseJoin = async () => {
  const response = await postToDataBaseJoin(
    DATABASE_STRUCTURE_CC.tableName,
    DATABASE_STRUCTURE_CC.columns,
    DATABASE_STRUCTURE_CC.foreingFields
  );

  return response;
};

export const fetchDataFilterCaras = async (year, month, group, onSuccess) => {
  postAzure(
    ConstantsModerna.enpoints.generalFunction,
    {
      typeQuery: "R_JOIN_UNIT",
      data: {
        tableName: "caras_categoria",
        compare: ["ano_porcentaje", year],
        compareFields: {
          fieldType: [
            {
              tableName: "caras_categoria",
              value: "mes_porcentaje",
            },
            {
              tableName: "grupo_cliente",
              value: "nombre_grupo_cliente",
            },
          ],
          fieldData: [month, group],
        },
        data: [
          "id_caras_categoria",
          "market_actual",
          "market_share",
          "ano_porcentaje",
          "mes_porcentaje",
        ],

        foreingFields: [
          {
            tableName: "caras_categoria",
            foreingTableName: "grupo_cliente",
            data: ["id_grupo_cliente", "nombre_grupo_cliente"],
            foreingKey: "id_grupo_cliente",
          },
          {
            tableName: "caras_categoria",
            foreingTableName: "categoria",
            data: ["id_categoria", "nombre_categoria"],
            foreingKey: "id_categoria",
          },
        ],
      },
    },
    (bodyResponse) => {
      onSuccess(bodyResponse);
    },
    (data) => {}
  );
};

export const insertCaraCategoria = async (insert) => {
  return await insertCarasCategoriaDataBase(insert);
};

const insertCarasCategoriaDataBase = async (insert) => {
  const values = [
    insert.id_caras_categoria,
    insert.id_grupo_cliente,
    insert.id_categoria,
    insert.market_actual,
    insert.market_share,
    insert.ano_porcentaje,
    insert.mes_porcentaje,
    insert.usuario_creacion,
    insert.fecha_creacion,
    insert.fecha_modificacion,
  ];
  const response = await insertIntoTable(
    DATABASE_STRUCTURE_CC.tableName,
    DATABASE_STRUCTURE_CC.columns,
    values
  );
  return response;
};

export const editCaraCategoriaAll = async (data) => {
  const response = await updateCarasCategoriaDataBase(data);
  return response;
};

const updateCarasCategoriaDataBase = async (updated) => {
  const date = new Date();
  const values = [
    updated.id_caras_categoria,
    updated.id_grupo_cliente,
    updated.id_categoria,
    updated.market_actual,
    updated.market_share,
    updated.ano_porcentaje,
    updated.mes_porcentaje,
    updated.fecha_modificacion,
  ];
  const response = await updateTableById(
    DATABASE_STRUCTURE_CC.tableName,
    DATABASE_STRUCTURE_CC.columnsEditable,
    values
  );
  return response;
};
