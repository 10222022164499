import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import { AiOutlineCaretDown as OpenButton } from "react-icons/ai";
import { MdCleaningServices as Cancel } from "react-icons/md";
import style from "./css/style.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CrudButton } from "../../imports";
import { handleCleanUserSection } from "../../util/Utils";

export default function Combo3({
  reset,
  pathvariable,
  options,
  onSelect,
  title,
  filter,
  children,
  gray,
  secondary,
  className,
  clear,
  letter,
  onClear,
  styleNew,
  cleanDropdown,
  noLabel,
}) {
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const inputref = useRef();
  const toggleRef = useRef();
  const dropRef = useRef();

  useEffect(() => {
    if (!selected) {
      return;
    }
    // handleCleanUserSection(); // Se ejecuta al cerrar el modal
    clearSelection();
  }, [reset]);

  const clearSelection = () => {
    if (toggleRef.current) {
      // dropRef.current.className = dropRef.current.className.replace("show", "");
      dropRef.current.click();
      toggleRef.current.className = toggleRef.current.className.replace(
        "show",
        ""
      );
    }
    if (onClear) {
      onClear();
    }
    if (pathvariable) {
      let parameters = "?";
      const params = [];
      for (let entry of searchParams.entries()) {
        if (entry[0] !== pathvariable) {
          params.push({ name: entry[0], value: entry[1] });
        }
      }
      params.map((variable) => {
        parameters += `${variable.name}=${variable.value}&`;
      });
      if (parameters.slice(-1) === "&") {
        parameters = parameters.slice(0, -1);
      }
      navigate(parameters);
    }
    setSelected(undefined);
    onSelect(undefined);
    handleCleanUserSection(); // Se ejecuta al cerrar el modal
  };
  useEffect(() => {
    if (!cleanDropdown) {
      setSelected("");
    }
  }, [cleanDropdown]);
  const capitalize = (value) => {
    if (typeof value !== "string") {
      return value;
    }
    return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (pathvariable) {
      const parameter = searchParams.get(pathvariable);
      if (options && parameter != null) {
        const select = options.find((e) => e.label === parameter);
        if (select) {
          if (onSelect) {
            onSelect(select.value);
          }
          setSelected(select);
        } else {
          {
            setError(`${title} ${parameter.toUpperCase()} no válido`);
          }
        }
      }
    }
  }, [searchParams, options]);

  const handleSelect = (e) => {
    setError();
    setSelected(options[e]);
    if (pathvariable) {
      let parameters = "?";
      const params = [];
      for (let entry of searchParams.entries()) {
        if (entry[0] !== pathvariable) {
          params.push({ name: entry[0], value: entry[1] });
        }
      }
      params.push({ name: pathvariable, value: options[e]?.label });
      params.map((variable) => {
        parameters += `${variable.name}=${variable.value}&`;
      });
      if (parameters.slice(-1) === "&") {
        parameters = parameters.slice(0, -1);
      }
      navigate(parameters);
      return;
    }
    if (onSelect) {
      onSelect(options[e]);
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className={`${style.dropdown} ${
        gray ? style.gray : secondary ? style.secondary : style.primary
      } ${className}`}
      style={style}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        if (inputref.current) {
          inputref.current.reset();
        }
      }}
    >
      <a
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </a>
      <div className={style.open}>
        <OpenButton />
      </div>
    </div>
  ));

  return (
    <Row className={`${style.supercontainer}`}>
      {!noLabel && (
        <label className={style.label}>{selected && !secondary && title}</label>
      )}

      <Dropdown
        onSelect={handleSelect}
        className={`${style.container} col`}
        ref={dropRef}
      >
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          as={CustomToggle}
          style={`${gray ? style.gray : style.primary}`}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            {clear && selected && (
              <div>
                <CrudButton
                  cancel
                  style={
                    styleNew
                      ? styleNew
                      : {
                          left: "0.5rem",
                          marginRight: "10px",
                        }
                  }
                  className={style.clear}
                  size={30}
                  onClick={clearSelection}
                />
              </div>
            )}

            <div
              style={{
                paddingLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {selected ? capitalize(selected.label) : title}
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          as={filter && CustomMenu}
          inputref={inputref}
          className={style.menu}
          ref={toggleRef}
        >
          {options?.length === 0 && (
            <Dropdown.Item eventKey={0} key={0}>
              No hay datos para mostrar
            </Dropdown.Item>
          )}
          {options?.map((option, id) => (
            <Dropdown.Item eventKey={id} key={id}>
              {capitalize(option.label)}
            </Dropdown.Item>
          ))}
          {/* </div> */}
        </Dropdown.Menu>
      </Dropdown>
      <Col className={`${style.right}`}>{children}</Col>
    </Row>
  );
}

const CustomMenu = React.forwardRef(
  (
    { children, style, className, "aria-labelledby": labeledBy, inputref },
    ref
  ) => {
    const [value, setValue] = useState("");
    const filteredChildren = React.Children.toArray(children).filter(
      (child) =>
        !value ||
        child.props.children.toLowerCase().includes(value.toLowerCase())
    );

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div
          style={{
            display: "flex",
            margin: `0.5rem 0rem 0rem 0rem`,
            alignItems: "center",
          }}
        >
          <form
            ref={inputref}
            onReset={() => {
              setValue("");
            }}
          ></form>

          <Form.Control
            autoFocus
            style={{
              width: "100%",
              margin: "0rem 1rem",
              marginBottom: "10px",
            }}
            placeholder="Buscar una categoría"
            // onFocus={handleFocus}
            // onBlur={handleBlur}
            onClick={() => {
              setValue("");
            }}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <Cancel
            size={22}
            color={"gray"}
            onClick={() => setValue("")}
            style={{
              // borderRadius: "5px",
              position: "absolute",
              right: "1.3rem",
              // marginTop: "0px",
              marginBottom: "10px",
              // backgroundColor: "black",
            }}
          />
        </div>
        <ul
          className="list-unstyled"
          style={{
            marginBottom: `0rem`,
          }}
        >
          {filteredChildren.length > 0 ? (
            filteredChildren
          ) : (
            <li style={{ marginLeft: "1rem", marginTop: "0.5rem" }}>
              No se encontraron elementos.
            </li>
          )}
        </ul>
        <div style={{ height: "1rem" }}></div>
      </div>
    );
  }
);
