import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  fetchClientGroups,
  fetchClientGroupsAsLabelValue,
} from "../../../service/ClientService";
import { ButtonModerna, ComboBox, Loading, Pagination } from "../../../imports";
import {
  editCaraCategoriaAll,
  editCategory,
  fetchCarasCategoria,
  fetchCarasCategoriaAllDataBase,
  fetchCarasCategoriaOrderedAllDataBase,
  fetchCategories,
  fetchCategoryAsLabelValue,
  fetchDataFilterCaras,
  insertCaraCategoria,
} from "../../../service/CategoryS";
import style from "./css/style.module.css";
import { RowCategoryModerna } from "./components/RowCategoryModerna";
import { v4 as uuidv4 } from "uuid";
import { ModernaContext } from "../../../Context/ModernaContext";
import { handleCleanUserSection } from "../../../util/Utils";
import { useMsal } from "@azure/msal-react";
import Swal from "sweetalert2";
import { COLORS } from "../../../common/theme/theme";
import Loading2 from "../../../common/loading2/loading2";
import { selectAllByTable } from "../../../service/AxiosService";
import { useNavigate } from "react-router-dom";
import Combo3 from "../../../common/combo/combo3";
const MONTHS = [
  { label: "Enero", value: "1" },
  { label: "Febrero", value: "2" },
  { label: "Marzo", value: "3" },
  { label: "Abril", value: "4" },
  { label: "Mayo", value: "5" },
  { label: "Junio", value: "6" },
  { label: "Julio", value: "7" },
  { label: "Agosto", value: "8" },
  { label: "Septiembre", value: "9" },
  { label: "Octubre", value: "10" },
  { label: "Noviembre", value: "11" },
  { label: "Diciembre", value: "12" },
];

export default function PercentageCategory() {
  const [clientTypes, setClientTypes] = useState([]);

  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState();
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState();
  const [selectedMonth, setSelectMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryTypes, setCategoryTypes] = useState([]);
  const [carasCategoriaFiltrado, setCarasCategoriaFiltrado] = useState([]);
  const [categoriasAll, setCategoriasAll] = useState([]);
  const [productoFiltrado, setProductoFiltrado] = useState();
  const { handleShowAlert } = useContext(ModernaContext);
  const [carasCategories, setCarasCategories] = useState([]);
  const [carasCategoriaBatch, setCarasCategoriaBatch] = useState([]);
  const { accounts } = useMsal();
  const [loadingBack, setLoadingBack] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [restart, setRestart] = useState(false);
  //__________________________Mis estados__________________________________
  const [tableView, setTableView] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [reset, setReset] = useState(false);
  const [years, setYears] = useState([]);
  const [changes, setChanges] = useState([]);
  const [successMessageShown, setSuccessMessageShown] = useState(false);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState();
  useEffect(() => {
    try {
      fetchCarasCategoriaDB();
    } catch (error) {}
  }, []);

  useEffect(() => {
    setRestart(new Date());
  }, [selectedYear, selectedMonth, selectedClient]);

  const fetchCarasCategoriaDB = async () => {
    try {
      const response = await selectAllByTable("caras_categoria");

      if (response.length > 0) {
        const an = findMinimumValue(response, "ano_porcentaje");
        const currentYear = new Date().getFullYear();
        const endYear = currentYear + 5;

        const years = [];
        for (let year = an; year <= endYear; year++) {
          years.push({ label: year.toString(), value: year });
        }
        setYears(years);

        return years;
      } else {
        const currentYear = new Date().getFullYear();
        const endYear = currentYear + 5;

        const years = [];
        for (let year = currentYear; year <= endYear; year++) {
          years.push({ label: year.toString(), value: year });
        }
        setYears(years);

        return years;
      }
    } catch (error) {}
  };

  const findMinimumValue = (array, field) => {
    if (array.length === 0) {
      return null;
    }
    return array.reduce((min, item) => {
      if (item[field] < min) {
        return item[field];
      }
      return min;
    }, array[0][field]);
  };

  const alertError = (text) => {
    Swal.fire({
      icon: "error",
      // title: "Oops...",
      text: text,
      confirmButtonText: "Confirmar",
      confirmButtonColor: COLORS.MODERNA_RED,
      // footer: '<a href="">Why do I have this issue?</a>'
    });
  };
  useEffect(() => {
    loadCategories();
    loadClientTypes();
    // loadCarasCategoriaDB(); ////////Funcion de azure AC
  }, []);

  useEffect(() => {
    if (!selectedCategory) {
      setProductoFiltrado(undefined);
      return;
    }

    let categoriaFiltrada = filterArray(
      carasCategoriaFiltrado,
      selectedCategory?.id_categoria
    );
    setProductoFiltrado(categoriaFiltrada);
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedYear && selectedMonth && selectedClient) {
      setLoadingItems(true);
      dataFiltersCaras();

      viewTable();
    }
    setChanges([]);
  }, [selectedYear, selectedMonth, selectedClient, page, selectedCategory]);

  const dataFiltersCaras = async () => {
    setLoadingItems(true);
    try {
      const _result = await fetchCarasCategoriaOrderedAllDataBase(
        selectedClient.id_grupo_cliente,
        selectedYear,
        parseInt(selectedMonth),
        page - 1,
        selectedCategory?.id_categoria
      );
      console.log(carasCategoriaBatch);
      carasCategoriaBatch.forEach((batch) => {
        const index = _result.data.findIndex((data) => {
          return data.id_categoria === batch.id_categoria;
        });
        _result.data[index] = batch;
      });
      setCarasCategoriaFiltrado(_result.data);
      setPageData({
        limit: _result.limit,
        page: _result.page + 1,
        total_elements: _result.total_elements,
        total_pages: _result.total_pages,
      });
      setLoadingItems(false);
      return;
    } catch (error) {}
  };

  // const orderData = (data) => {
  //   const datosOrdenados = data.sort((a, b) => {
  //     const nombreA = a.nombre_categoria.toUpperCase();
  //     const nombreB = b.nombre_categoria.toUpperCase();
  //     if (nombreA < nombreB) {
  //       return -1;
  //     }
  //     if (nombreA > nombreB) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  //   return datosOrdenados;
  // };

  const comprobarFecha = (an, me) => {
    const fechaActual = new Date();
    const añoActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1;
    if (an < añoActual || (añoActual === an && me < mesActual)) {
      return true;
    }
    return false;
  };
  //_________________________________________________________________________________________________________
  const handlePushBatchItem = (item) => {
    try {
      let tempItemsReady = Object.assign([], carasCategoriaBatch);
      let counter = 0;
      let canPushNew = true;
      let newObj = {};
      for (const itemObj of carasCategoriaBatch) {
        if (itemObj.id_categoria == item.id_categoria) {
          newObj = { ...itemObj, ...item };
          canPushNew = false;
          break;
        }
        counter += 1;
      }
      if (canPushNew) {
        tempItemsReady.push(item);
      } else {
        tempItemsReady[counter] = newObj;
      }
      setCarasCategoriaBatch(tempItemsReady);
    } catch (error) {}
  };
  const handleSavePercentages = (itemEdit, newValue, type) => {
    setChanges([...changes, itemEdit]);
    setCarasCategoriaFiltrado(
      carasCategoriaFiltrado.map((item) => {
        let tempObj = {
          market_actual: item.market_actual,
          market_share: item.market_share,
        };
        if (type == "MA") {
          tempObj.market_actual =
            item.id_categoria == itemEdit.id_categoria
              ? newValue
              : item.market_actual;
        } else if (type == "MS") {
          tempObj.market_share =
            item.id_categoria == itemEdit.id_categoria
              ? newValue
              : item.market_share;
        }
        if (item.id_categoria == itemEdit.id_categoria) {
          handlePushBatchItem({ ...item, ...tempObj });
        }
        return {
          ...item,
          ...tempObj,
        };
      })
    );
  };

  const filterArray = (array, value) => {
    const filteredArray = array.filter((item) => item?.id_categoria === value);
    return filteredArray;
  };

  //LOAD
  const loadCategories = async () => {
    setLoading(true);
    const response = await fetchCategoryAsLabelValue();
    if (response) {
      setCategoryTypes(response);
    }
    setLoading(false);
  };

  const loadClientTypes = async () => {
    setLoading(true);
    const response = await fetchClientGroupsAsLabelValue();
    if (response) {
      setClientTypes(response);
    }
    setLoading(false);
  };

  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      text: "Cambios guardados",
      confirmButtonText: "Aceptar",
      customClass: {
        confirmButton: style.myConfirmButton, // Clase CSS personalizada para el botón
      },
      confirmButtonColor: COLORS.MODERNA_GREEN,
    });
  };

  const handleSaveCaraCategoria = async () => {
    if (carasCategoriaBatch.length <= 0) {
      return;
    }
    let cambiosGuardados = false;
    setLoadingBack(true);
    for (const objPush of carasCategoriaBatch) {
      const temp_id_caras_categoria = objPush.id_caras_categoria
        ? objPush.id_caras_categoria
        : uuidv4();
      const readyData = {
        id_caras_categoria: temp_id_caras_categoria,
        id_grupo_cliente: selectedClient?.id_grupo_cliente,
        id_categoria: objPush.id_categoria,
        market_actual: objPush.market_actual + "",
        market_share: objPush.market_share + "",
        ano_porcentaje: selectedYear,
        mes_porcentaje: selectedMonth,
        usuario_creacion: accounts[0].username,
        fecha_creacion: new Date().toISOString(),
        fecha_modificacion: new Date().toISOString(),
      };

      if (objPush.id_caras_categoria) {
        try {
          await editCaraCategoriaAll(readyData);
          setSuccessMessageShown(true);
          cambiosGuardados = true;
        } catch (error) {
          alertError("Ocurrio un error al editar las caras-categoría ");
        } finally {
          // setLoadingBack(false);
        }
      } else {
        // setLoadingBack(true);
        try {
          await insertCaraCategoria(readyData);
          cambiosGuardados = true;
          setSuccessMessageShown(true);
        } catch (error) {
          alertError("Ocurrio un error al registrar las caras-categoría ");
        } finally {
        }
      }
    }
    if (cambiosGuardados) {
      alertSuccess();
    }
    setCarasCategoriaFiltrado([]);
    setSelectedCategory("");
    setLoadingBack(false);
    setSelectMonth("");
    setSelectedYear("");
    setSelectedClient("");
    setTableView(false);
    navigate("/variables/caras");
    setCarasCategoriaBatch([]);
    handleCleanUserSection();
  };
  // useEffect(() => {
  //   if (successMessageShown) {
  //     alertSuccess();
  //     setSuccessMessageShown(false);
  //   }
  // }, [successMessageShown]);
  const handleCategorySelection = async (cat) => {
    setPage(1);
    setSelectedCategory(cat);
  };

  const viewTable = () => {
    if (selectedYear && selectedMonth && selectedClient) {
      setTableView(true);
    } else {
      setTableView(false);
    }
  };

  const handleYearSelection = (e) => {
    // selectedYearRef
    if (selectedYear !== e) {
      setCarasCategoriaBatch([]);
    }
    setSelectedYear(e);
  };
  const handleMonthSelection = (e) => {
    if (selectedMonth !== e) {
      setCarasCategoriaBatch([]);
    }
    setSelectMonth(e);
  };

  //HANDLE
  const handleClientSelection = (e) => {
    if (selectedClient !== e) {
      setCarasCategoriaBatch([]);
    }
    setClient(e);
    setSelectedClient(e);
  };

  const excluirCategorias = (categoriasM, campos, categoriaExcluidas) => {
    return categoriasM.filter((objeto) => {
      return !categoriaExcluidas.some((valorExcluido) =>
        campos.every(
          (propiedad) => objeto[propiedad] === valorExcluido[propiedad]
        )
      );
    });
  };

  const verExluidas = (categoriasAll, carasCategoriaFiltrado) => {
    const arrayFiltrado = excluirCategorias(
      categoriasAll,
      ["id_categoria"],
      carasCategoriaFiltrado
    );
    return arrayFiltrado;
  };

  return (
    <Container fluid="md">
      {!tableView ? (
        <label style={{ width: "100%" }}>
          <h5
            style={{
              // textAlign: "center",
              // marginTop: "60px",
              fontSize: "16px",
              // backgroundColor: "cyan",
              height: "28px",
            }}
          >
            Seleccione el año, mes y grupo de clientes para visualizar los datos
          </h5>
        </label>
      ) : (
        <div
          style={{
            height: "28px",
          }}
        ></div>
      )}
      <Col>
        <Row
          style={{
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "30px",
          }}
        >
          <Col
            style={{
              justifyContent: "flex-start",
              display: "flex",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <Combo3
              pathvariable={"anio"}
              onSelect={handleYearSelection}
              title="Año"
              options={years}
              clear
              onClear={() => {
                setTableView(false);
                setCarasCategoriaBatch([]);
                setCarasCategoriaFiltrado([]);
              }}
              cleanDropdown={selectedYear}
              reset={true}
              // className={style.boxArrow}
            />
          </Col>
          <Col style={{ justifyContent: "flex-end", display: "flex" }}>
            <Combo3
              pathvariable={"mes"}
              onSelect={handleMonthSelection}
              title="Mes"
              options={MONTHS}
              clear
              onClear={() => {
                setTableView(false);
                setCarasCategoriaFiltrado([]);
                setCarasCategoriaBatch([]);
              }}
              cleanDropdown={selectedMonth}
              // className={style.boxArrow}

              // gray={selectedMonth ? false : true}
            />
          </Col>
          <Col style={{ justifyContent: "flex-end", display: "flex" }}>
            <Combo3
              pathvariable={"grupo_cliente"}
              onSelect={handleClientSelection}
              title="Grupo de clientes"
              options={clientTypes}
              clear
              onClear={() => {
                setTableView(false);
                setCarasCategoriaFiltrado([]);
                setCarasCategoriaBatch([]);
              }}
              cleanDropdown={selectedClient}
              // className={style.boxArrow}

              // gray={selectedClient ? false : true}
            />
          </Col>
          <Col
            style={{
              justifyContent: "flex-end",
              display: "flex",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <ButtonModerna
              className={`${style.button}`}
              secondary
              onClick={handleSaveCaraCategoria}
              save
              disabled={
                changes.length === 0 && carasCategoriaBatch.length === 0
              }
            >
              Guardar
            </ButtonModerna>
          </Col>
        </Row>

        {!tableView ? (
          <></>
        ) : (
          <Row
            style={{ marginLeft: "0px", marginRight: "0px", padding: "0rem" }}
          >
            <Row
              className={style.navContentP}
              style={{ marginLeft: "0px", marginRight: "0px", padding: "0rem" }}
            >
              <Col>
                <Row
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Col
                    style={{
                      marginLeft: "0px",
                      marginRight: "0px",
                      padding: "0rem",
                    }}
                  >
                    <Combo3
                      pathvariable={"categorias"}
                      title={"Categoría"}
                      options={categoryTypes}
                      onSelect={handleCategorySelection}
                      reset={reset}
                      clear
                      filter
                      cleanDropdown={selectedCategory}
                      noLabel
                    />
                  </Col>
                  <Col>Meta Actual</Col>
                  <Col>Marketshare</Col>
                </Row>
              </Col>
            </Row>
            {loadingItems ? (
              <Loading />
            ) : (
              <Row
                // className={style.rowContent1}
                style={{
                  marginTop: "5px",
                  // padding: "0rem",
                  // backgroundColor: "ORANGE",
                }}
              >
                {carasCategoriaFiltrado?.length >= 0 ? (
                  <div style={{ padding: "0rem" }}>
                    {carasCategoriaFiltrado?.map((category, index) => {
                      return (
                        <div key={index}>
                          <RowCategoryModerna
                            restart={restart}
                            data={category}
                            disable={comprobarFecha(
                              selectedYear,
                              selectedMonth
                            )}
                            handleSavePercentages={handleSavePercentages}
                          ></RowCategoryModerna>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <label style={{ fontSize: "16px" }}>
                    {client
                      ? `No existen caras categorías`
                      : "Seleccione un grupo de clientes para registrar o modificar caras-categoría"}
                  </label>
                )}
                <Pagination setPage={setPage} data={pageData} />

                {/* //------------------------------------------------------------------------------------------------------------- */}
              </Row>
            )}
          </Row>
        )}
      </Col>
      {loadingBack && <Loading2 />}
    </Container>
  );
}
