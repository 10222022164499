import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import { AiOutlineCaretDown as OpenButton } from "react-icons/ai";
import { RxCross2 as Cancel } from "react-icons/rx";
import style from "./css/style.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CrudButton } from "../../imports";

export default function Combo({
  reset,
  pathvariable,
  options,
  onSelect,
  title,
  filter,
  children,
  gray,
  secondary,
  className,
  clear,
  selectedLabel,
  onClear,
  styleNew,
  cleanDropdown,
  banned,
}) {
  const [selected, setSelected] = useState(null);
  const [_options, setOptions] = useState(options);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const dropRef = useRef();
  const toggleRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selected) {
      return;
    }
    clearSelection();
  }, [reset]);

  const clearSelection = () => {
    if (toggleRef.current) {
      // dropRef.current.className = dropRef.current.className.replace("show", "");
      dropRef.current.click();
      toggleRef.current.className = toggleRef.current.className.replace(
        "show",
        ""
      );
    }
    if (onClear) {
      onClear();
    }
    if (pathvariable) {
      let parameters = "?";
      const params = [];
      for (let entry of searchParams.entries()) {
        if (entry[0] !== pathvariable) {
          params.push({ name: entry[0], value: entry[1] });
        }
      }
      params.map((variable) => {
        parameters += `${variable.name}=${variable.value}&`;
      });
      if (parameters.slice(-1) === "&") {
        parameters = parameters.slice(0, -1);
      }
      navigate(parameters);
    }
    setSelected(undefined);
    onSelect(undefined);
  };

  useEffect(() => {
    if (!cleanDropdown) {
      setSelected("");
    }
  }, [cleanDropdown]);

  const capitalize = (value) => {
    if (typeof value !== "string") {
      return value;
    }
    return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (pathvariable) {
      const parameter = searchParams.get(pathvariable);
      if (_options && parameter != null) {
        const select = _options.find((e) => e.label === parameter);
        if (select) {
          if (onSelect) {
            onSelect(select.value);
          }
          setSelected(select);
        } else {
          {
            setError(`${title} ${parameter.toUpperCase()} no válido`);
          }
        }
      }
    }
  }, [searchParams, _options]);

  const handleSelect = (e) => {
    setError();
    setSelected(_options[e]);
    if (pathvariable) {
      let parameters = "?";
      const params = [];
      for (let entry of searchParams.entries()) {
        if (entry[0] !== pathvariable) {
          params.push({ name: entry[0], value: entry[1] });
        }
      }
      params.push({ name: pathvariable, value: _options[e]?.label });
      params.map((variable) => {
        parameters += `${variable.name}=${variable.value}&`;
      });
      if (parameters.slice(-1) === "&") {
        parameters = parameters.slice(0, -1);
      }
      navigate(parameters);
      return;
    }
    if (onSelect) {
      onSelect(_options[e]);
    }
  };
  useEffect(() => {
    if (selectedLabel) {
      const index = _options.findIndex((option) => {
        return option.label === selectedLabel;
      });
      setSelected(index >= 0 ? options[index] : undefined);
    }
    if (!banned || !Array.isArray(banned) || banned.length === 0) {
      setOptions(options);
      return;
    }

    if (!banned || !Array.isArray(banned)) {
      setOptions(options);
      return;
    }

    const opt = options.filter((option) => {
      const index = banned.findIndex((ban) => {
        return ban.label === option.label;
      });
      return index === -1 || option.label === selectedLabel;
    });
    setOptions(opt);
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className={`${style.dropdown} ${
        gray ? style.gray : secondary ? style.secondary : style.primary
      } ${className}`}
      style={style}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <a
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </a>
      <div className={style.open}>
        <OpenButton />
      </div>
    </div>
  ));

  return (
    <Row className={`${style.supercontainer}`}>
      <label className={style.label}>{selected && !secondary && title}</label>
      <Dropdown
        ref={dropRef}
        onSelect={handleSelect}
        className={`${style.container} col`}
      >
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          as={CustomToggle}
          style={`${gray ? style.gray : style.primary}`}
        >
          {clear && selected && (
            <CrudButton
              cancel
              style={
                styleNew
                  ? styleNew
                  : {
                      position: "absolute",
                      left: "0.5rem",
                      top: "20%",
                      // paddingBottom: "10px",
                      marginRight: "2px",
                      // backgroundColor: "black",
                    }
              }
              className={style.clear}
              size={30}
              onClick={clearSelection}
            />
          )}
          {selected?.label ? capitalize(selected.label) : title}
        </Dropdown.Toggle>
        <Dropdown.Menu
          as={filter && CustomMenu}
          ref={toggleRef}
          className={style.menu}
        >
          {/* <div className={style.scrollView}> */}
          {_options?.length === 0 && (
            <Dropdown.Item eventKey={0} key={0}>
              No hay datos para mostrar
            </Dropdown.Item>
          )}
          {_options?.map((option, id) => (
            <Dropdown.Item eventKey={id} key={id}>
              {capitalize(option.label)}
            </Dropdown.Item>
          ))}
          {/* </div> */}
        </Dropdown.Menu>
      </Dropdown>
      <Col className={`${style.right}`}>{children}</Col>
    </Row>
  );
}

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");
    const filteredChildren = React.Children.toArray(children).filter(
      (child) =>
        !value ||
        child.props.children.toLowerCase().includes(value.toLowerCase())
    );

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div
          style={{
            display: "flex",
            margin: `0.5rem 0rem 0rem 0rem`,
            alignItems: "center",
          }}
        >
          <Form.Control
            autoFocus
            style={{ width: "100%", margin: "0rem 1rem" }}
            placeholder="Buscar..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <Cancel
            size={22}
            color={"gray"}
            onClick={() => setValue("")}
            style={{
              borderRadius: "5px",
              position: "absolute",
              right: "1.3rem",
            }}
          />
        </div>
        <ul
          className="list-unstyled"
          style={{
            marginBottom: `0rem`,
          }}
        >
          {filteredChildren.length > 0 ? (
            filteredChildren
          ) : (
            <li style={{ marginLeft: "1rem", marginTop: "0.5rem" }}>
              No se encontraron elementos.
            </li>
          )}
        </ul>
        <div style={{ height: "1rem" }}></div>
      </div>
    );
  }
);
