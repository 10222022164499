import { postToDataBaseJoin, selectAllByTable } from "./AxiosService";
import { axios } from "../imports";
import { PAGINATION_CONFIG } from "../config/app-config";

const PORTFOLIO_FUNCTION_URL =
  "https://fotoexito1.azurewebsites.net/api/functionPortafolio?code=MNlJ4NW4ef0q-K73ik9qjbbEZDBUFST9gwjTI7LY5rfQAzFugK-ZJg==";

const ESTRUCTURE_PRODUCTS = {
  tableName: "producto",
  data: [
    "id_producto",
    "nombre_producto",
    "url_imagen_producto",
    "precio",
    "usuario_creacion",
    "fecha_creacion",
    "fecha_modificacion",
    "organizacion",
    "canal",
  ],
  foreingFields: [
    {
      tableName: "categoria",
      data: ["id_categoria", "nombre_categoria"],
      foreingKey: "id_categoria",
    },
  ],
};

export const fetchProductsCategory = async () => {
  const response = await postToDataBaseJoin(
    ESTRUCTURE_PRODUCTS.tableName,
    ESTRUCTURE_PRODUCTS.data,
    ESTRUCTURE_PRODUCTS.foreingFields
  );
  //console.log("lo que viene de la base::::::", response);
  return response;
};

export const fetchCategory = async () => {
  const response = await selectAllByTable("categoria");
  //console.log("lo que viene de la base::::::", response);
  return response;
};

export const fetchPortafolios = async (
  idGroupClient,
  page,
  id_categoria,
  id_producto
) => {
  const data = {
    typeQuery: "SELECT",
    data: {
      id_grupo_cliente: idGroupClient,
      limit: PAGINATION_CONFIG.LIMIT,
      page: page - 1,
      id_categoria,
      id_producto,
    },
  };
  const resp = await axios.post(PORTFOLIO_FUNCTION_URL, data);
  // console.log(resp.data.data);
  return resp.data;
};
export const saveBriefcases = async (portafolios) => {
  // console.log(portafolios);
  const response = await saveBriefcasesToDataBase(portafolios);
  return response;
};
const saveBriefcasesToDataBase = async (portafolios) => {
  let response;

  const data = {
    typeQuery: "INSERT",
    data: { portafolios: portafolios },
  };
  response = await axios.post(PORTFOLIO_FUNCTION_URL, data);
  return response;
};
