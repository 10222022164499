import React, { useEffect, useState } from "react";
import style from "./css/style.module.css";
//import "./css/style.css";
import { ButtonModerna } from "../../imports";

function ActiveSession() {
  const [showAlert, setShowAlert] = useState(true);
  const originalTitle = document.title; // Guarda el título original

  useEffect(() => {
    window.history.pushState(null, "", "/error404");
    if (showAlert) {
      // Establece el nuevo título con el icono deseado
      document.title = "Moderna | 🔒 Sesión activa";
      // Restaura el título original
      return () => {
        document.title = originalTitle;
      };
    }
  }, []);

  // const handleDismissAlert = () => {
  //     setShowAlert(false);
  //     setShowCreate(false);
  //     document.title = originalTitle;
  //     // window.location.reload();
  // };

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "50%",
        padding: "10px",
        borderRadius: "3px",
      }}
    >
      {/* <Modal
        show={showCreate}
        close={() => {
          setShowCreate(false);
        }}
        title={"Advertencia"}
        isStatic={"static"}
        closeModal={false}
        className={style.background}
      >
        <div>
          {showAlert && (
            <div xs={12} className={style.text}>
              <div>
                <p>
                  La sesión está activa en otra ventana. Cierre esta ventana
                  para continuar en la otra ventana. <br />
                </p>
                <ButtonModerna onClick={() => window.location.reload()}>
                  Usar aquí
                </ButtonModerna>
              </div>
            </div>
          )}
        </div>
      </Modal> */}
      El sistema de auditoría está abierto en otra ventana. Haz clic en "Usar
      aquí" para abrir nuevamente el sistema de auditoría.
      <ButtonModerna
        onClick={() => window.location.reload()}
        className={style.btnRep}
        style={{
          // backgroundColor: "black",
          // borderRadius: "25px",
          display: "flex",
          justifyContent: "end",
          marginTop: "5px",
          marginRight: "10px",
        }}
      >
        Usar aquí
      </ButtonModerna>
    </div>
  );
}

export default ActiveSession;
