import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Alert,
  ButtonModerna,
  ComboBox,
  Loading,
  Modal,
} from "../../../imports";
import {
  ID_PREFIX,
  PORTFOLIO_CODE_CATALOG,
  PRECIO_CODE_CATALOG,
  fetchVariables,
  saveVariables,
} from "../../../service/VariableService";
import { fetchClientGroupsAsLabelValue } from "../../../service/ClientService";
import DropdownVariable from "./components/dropdown/dropdown";
import CreateVariable from "./components/create-form";
import style from "./css/style.module.css";
import { GoAlert as AlertIcon } from "react-icons/go";
import { AiOutlineReload as Reload } from "react-icons/ai";
import { BsCheckCircleFill as CheckIcon } from "react-icons/bs";
import { COLORS } from "../../../common/theme/theme";
import { SweetAlert } from "../../../common/sweet-alert/sweet-alert";
import { handleCleanUserSection } from "../../../util/Utils";
import { ModernaContext } from "../../../Context/ModernaContext";
import Loading2 from "../../../common/loading2/loading2";
import { handleCheckAccessValidation } from "../../../service/AccessValidation";
import { fetchCatalogVariableAsLabelValue } from "../../../service/CatalogService";
import Swal from "sweetalert2";

const VARIABLE_LIMIT = 4;

export default function Create() {
  const { handleShowAlert } = useContext(ModernaContext);
  const [loading, setLoading] = useState(true);
  const [variables, setVariables] = useState([]);
  const [clientGroups, setClientGroups] = useState([]);
  const [client, setClient] = useState();
  const [modal, setModal] = useState(false);
  const [weight, setWeight] = useState(0);
  const [editing, setEditing] = useState();
  const [unsaved, setUnsaved] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();
  const [loadingBack, setLoadingBack] = useState(false);
  const [toDelete, setToDelete] = useState([]);
  const [changes, setChanges] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [usedCatalog, setUsedCatalog] = useState([]);

  const varPrecioRef = useRef();
  const varPortafolioRef = useRef();

  const handleSaveAllData = async () => {
    // setLoadingBack(true);
    setSaving(true);
    try {
      variables.forEach((_var) => {
        if (_var.id_variable.startsWith(ID_PREFIX)) {
          _var.action = "INSERT";
        } else {
          _var.action = "UPDATE";
        }
      });
      await saveVariables(variables, toDelete);
      loadVariables();
      setChanges([]);
    } catch (e) {
      setError("Error al guardar");
    } finally {
      // setLoadingBack(false);
    }

    setSaving(false);
    handleCleanUserSection();
  };

  const loadVariables = async () => {
    setFetching(true);
    try {
      const response = await fetchVariables(
        client?.id_grupo_cliente ? client.id_grupo_cliente : ""
      );
      if (response) {
        const sorted = oderVariables(response);
        const arrayOrdenado = sorted.map((objeto) => {
          const subvariablesOrdenadas = objeto.subvariables.sort((a, b) => {
            return new Date(b.fecha_creacion) - new Date(a.fecha_creacion);
          });
          return { ...objeto, subvariables: subvariablesOrdenadas };
        });
        setVariables(arrayOrdenado);
      }
    } catch (e) {
      setError("No se ha podido establecer comunicación con el servidor");
    }
    setFetching(false);
  };
  const oderVariables = (array) => {
    const order = catalog.map(({ label: cat }) => {
      return cat;
    });
    array.sort(function (a, b) {
      var indexA = order.indexOf(a.nombre_variable);
      var indexB = order.indexOf(b.nombre_variable);
      if (indexA < indexB) {
        return -1;
      } else if (indexA > indexB) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  };

  const loadClientGroups = async () => {
    setLoading(true);
    try {
      const response = await fetchClientGroupsAsLabelValue();
      if (response) {
        setClientGroups(response);
      }
      const _catalog = await fetchCatalogVariableAsLabelValue();
      if (_catalog) {
        setCatalog(_catalog);
      }
      if (!catalog || !response) {
        throw new Error("No se ha podido cargar");
      }
    } catch (e) {
      setError("No se ha podido establecer comunicación con el servidor");
    }

    setLoading(false);
  };

  useEffect(() => {
    loadClientGroups();
  }, []);
  //-------------------
  useEffect(() => {
    if (client) loadVariables();
  }, [client]);

  useEffect(() => {
    const _used = [];
    if (variables) {
      let counter = 0;
      variables.forEach((variable) => {
        if (
          variable.estado_variable === 1 ||
          variable.estado_variable === true
        ) {
          counter += parseFloat(variable.porcentaje_variable);
        }
        _used.push({
          label: variable.nombre_variable,
          value: variable.estado_variable,
        });
      });
      setUsedCatalog(_used);
      setWeight(counter);
    }

    if (!varPortafolioRef.current || !varPrecioRef.current) {
      if (_used && Array.isArray(_used)) {
        catalog.forEach(({ value: cat }) => {
          if (cat.codigo_catalogo === PORTFOLIO_CODE_CATALOG) {
            varPortafolioRef.current = cat.descripcion;
          }
          if (cat.codigo_catalogo === PRECIO_CODE_CATALOG) {
            varPrecioRef.current = cat.descripcion;
          }
        });
      }
    }
  }, [variables]);

  const handleClientSelection = (e) => {
    setClient(e);
  };

  const handleModified = (value) => {
    if (value.startsWith("CHECKED")) {
      const index = changes.findIndex((element) => {
        return element.slice(0, -1) === value.slice(0, -1);
      });
      if (index !== -1) {
        if (changes[index] !== value) {
          if (changes.length === 1) {
            setChanges([]);
            return;
          }
          const _c = [...changes];
          _c.splice(index, 1);
          setChanges(_c);
        }
        return;
      }
      if (value.startsWith("DELETED")) {
        const index = changes.findIndex((element) => {
          return element === `CREATED${value.substring(7)}`;
        });

        if (index !== -1) {
          const _c = [...changes];
          _c.splice(index, 1);
          setChanges(_c);
        }
        return;
      }
    }
    setChanges([...changes, value]);
  };

  const handleDelete = async (item) => {
    await handleCheckAccessValidation(
      setLoadingBack,
      async () => {
        const confirmation = await SweetAlert(
          `¿Está seguro de eliminar la variable "${item.nombre_variable}"?`
        );
        if (confirmation === false) {
          handleCleanUserSection();
          return;
        }
        setLoading(true);
        const response = variables.filter(
          (element) => element.id_variable !== item.id_variable
        );
        handleModified(`DELETED${item.id_variable}`);
        setToDelete([...toDelete, item]);
        if (response) {
          setVariables(response);
        }
        setLoading(false);
      },
      handleShowAlert
    );
  };

  const handleEdit = async (item) => {
    await handleCheckAccessValidation(
      setLoadingBack,
      () => {
        setEditing(item);
        setModal(true);
      },
      handleShowAlert
    );
  };

  const handleCloseModal = async () => {
    if (unsaved === true) {
      const confirm = await SweetAlert(
        "Tiene cambios sin guardar. ¿Desea salir?"
      );
      if (!confirm) {
        return;
      }
    }
    setUnsaved(false);
    setModal(false);
    setEditing(undefined);
    handleCleanUserSection();
  };

  const handleSaveVariable = (variable) => {
    const vars = [variable, ...variables];
    const sorted = oderVariables(vars);
    setVariables(sorted);
    setChanges([...changes, `CREATED${variable.id_variable}`]);
  };

  const handleToggleCheckSuvariable = (variable) => {
    handleEditeVariable(variable);
  };

  const handleEditeVariable = async (variable) => {
    await handleCheckAccessValidation(
      setLoadingBack,
      () => {
        const vars = variables.map((element) => {
          if (element?.id_variable === variable?.id_variable) {
            return variable;
          }
          return element;
        });
        const sorted = oderVariables(vars);
        setVariables(sorted);
        setEditing(undefined);
      },
      handleShowAlert
    );
  };

  const handleButtonClick = () => {
    if (!clientGroups) {
      loadClientGroups();
    }
    setError("");
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div
        style={{
          textAlign: "center",
          // backgroundColor: "black",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          // height: "80vh",
        }}
      >
        {error}
        <div>
          <ButtonModerna onClick={handleButtonClick}>
            Recargar &nbsp;
            <Reload />
          </ButtonModerna>
        </div>
      </div>
    );
  }
  return (
    <Container fluid={"md"}>
      {variables?.length > 0 ? (
        <div
          style={{
            height: "1rem",
            margin: "1.2rem 0rem",
          }}
        ></div>
      ) : (
        <div
          style={{
            height: "1rem",
            margin: "1.2rem 0rem",
          }}
        >
          <h5 style={{ fontSize: "16px" }}>
            {client
              ? !fetching &&
                `No existen variables registradas para el grupo de clientes ${client.nombre_grupo_cliente}`
              : "Seleccione el grupo de clientes para registrar o modificar las variables asignadas"}
          </h5>
        </div>
      )}
      <Modal
        title={`${editing ? "Editar" : "Nueva"} Variable ${
          client?.nombre_grupo_cliente
        }`}
        show={modal}
        close={handleCloseModal}
      >
        <CreateVariable
          close={() => {
            setModal(false);
            setUnsaved(false);
            handleCleanUserSection();
          }}
          cancel={handleCloseModal}
          clientType={client}
          editing={editing}
          changes={unsaved}
          modified={(e) => setUnsaved(e)}
          saveVariable={handleSaveVariable}
          editeVariable={handleEditeVariable}
          variables={variables}
          totalPercentage={weight}
          handleModified={handleModified}
          modifies={unsaved}
          catalog={catalog}
          usedCatalog={usedCatalog}
          varPortafolioRef={varPortafolioRef}
          varPrecioRef={varPrecioRef}
        />
      </Modal>
      <Row className={style.row}>
        <Col xs={12} md={12} xl={8}>
          <ComboBox
            pathvariable={"grupo_cliente"}
            onSelect={handleClientSelection}
            title="Grupo de Clientes"
            options={clientGroups}
            gray
            clear
            onClear={() => {
              setVariables([]);
              // setClientGroups([]);
            }}
            // filter
            className={style.poss}
          >
            {client && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {weight != 100 ? (
                  <AlertIcon
                    style={{ marginRight: "0.5rem" }}
                    size={25}
                    color={COLORS.MODERNA_RED}
                  />
                ) : (
                  <CheckIcon
                    style={{ marginTop: "0.1rem", marginRight: "0.5rem" }}
                    size={25}
                    color={COLORS.MODERNA_GREEN}
                  />
                )}
                {`${weight}%`}
              </div>
            )}
          </ComboBox>
        </Col>
        <Col xs={12} md={12} xl={4}>
          <Row>
            <Col xs={6}>
              <Alert
                title={
                  !client
                    ? "Primero debe seleccionar un grupo de cliente"
                    : `No puede agregar más de ${catalog.length} variables`
                }
                direction={"top"}
                tooltip
                show={false}
                disabled={client && variables.length < VARIABLE_LIMIT}
              >
                <div>
                  <ButtonModerna
                    className={`${style.button}`}
                    secondary
                    onClick={async () => {
                      await handleCheckAccessValidation(
                        setLoadingBack,
                        () => {
                          setModal(true);
                        },
                        handleShowAlert
                      );
                    }}
                    disabled={!client || variables.length >= VARIABLE_LIMIT}
                    add
                  >
                    Nueva Variable
                  </ButtonModerna>
                </div>
              </Alert>
            </Col>
            <Col xs={6}>
              <Alert
                title={
                  weight > 100
                    ? "La suma total de las variables no puede sobrepasar el 100%"
                    : "La suma total de las variables no puede ser menor al 100%"
                }
                direction={"top"}
                tooltip
                disabled={weight === 100 || !client ? true : false}
              >
                <div>
                  <ButtonModerna
                    className={`${style.button}`}
                    secondary
                    disabled={weight !== 100 || saving || changes.length === 0}
                    onClick={handleSaveAllData}
                    save
                  >
                    Guardar
                  </ButtonModerna>
                </div>
              </Alert>
            </Col>
          </Row>
        </Col>
      </Row>
      {fetching ? (
        <Loading />
      ) : saving ? (
        <Loading message={"Guardando"} />
      ) : (
        <Row>
          {variables?.length > 0 ? (
            <div>
              {variables?.map((variable, id) => {
                return (
                  <div key={id}>
                    <DropdownVariable
                      subvariables={variable?.subvariables}
                      variable={variable}
                      remove={handleDelete}
                      edite={handleEdit}
                      checkSubvariables={handleToggleCheckSuvariable}
                      handleModified={handleModified}
                      variables={variables}
                      varPrecioRef={varPrecioRef}
                      varPortafolioRef={varPortafolioRef}
                      setLoadingBack={setLoadingBack}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
        </Row>
      )}
      {loadingBack && <Loading2 />}
    </Container>
  );
}
