import React, { useEffect, useState } from "react";
import { COLORS } from "../../../../../common/theme/theme";
import { Col, Form, Row } from "react-bootstrap";
import { Alert, CrudButton } from "../../../../../imports";
import ItemSubvariable from "../item/item";
import VariableInput from "../input/variable-input";
import { ID_PREFIX } from "../../../../../service/VariableService";
import { useMsal } from "@azure/msal-react";
import { validateSimilarity } from "../../../../../util/validations";
const MIN_LENGTH = 1;
const MIN_PERCENTAGE = 1;
export default function SubvariableHeader({
  updatePercentaje,
  subvariables,
  setSubvariables,
  setModified,
  changes,
  setChanges,
}) {
  const [inputPercentage, setInputPercentage] = useState(0);
  const [inputName, setInputName] = useState("");
  const [error, setError] = useState("");
  const { accounts } = useMsal();

  useEffect(() => {
    //console.log(subvariables);
    let total = 0;
    subvariables?.map((item) => {
      total += item.porcentaje_subvariable;
    });
    updatePercentaje(total);
  }, [subvariables]);

  const isValidSubvariable = (value, _setError, id, percentage) => {
    if (parseFloat(percentage) < MIN_PERCENTAGE) {
      _setError(
        `El porcentaje de la subvariable debe ser mayor o igual a ${MIN_PERCENTAGE}%`
      );
      return false;
    }

    if (value.replace(" ", "")?.length < MIN_LENGTH) {
      _setError(`El nombre de la subvariable debe ser mayor a ${MIN_LENGTH}`);
      return false;
    }
    let valid = true;
    subvariables.forEach((element) => {
      if (
        validateSimilarity(element.nombre_subvariable, value) >= 0.966 &&
        element.id_subvariable !== id
      ) {
        _setError(
          `Ya existe una subvariable con este nombre '${element.nombre_subvariable}'`
        );
        valid = false;
        return;
      }
    });
    return valid;
  };
  const handleAddSubvariable = () => {
    const _id = `${ID_PREFIX}${new Date()}`;
    setError("");
    if (!isValidSubvariable(inputName, setError, _id, inputPercentage)) {
      return;
    }
    const sub = {
      id_subvariable: _id,
      nombre_subvariable: inputName,
      porcentaje_subvariable: inputPercentage,
      estado_subvariable: 1,
      usuario_creacion:
        accounts.length > 0 ? accounts[0].username : "Default User",
    };
    setSubvariables([sub, ...subvariables]);
    setInputName("");
    setInputPercentage(0);
    setChanges([...changes, "ADDED SUVARIABLE"]);
  };

  const handleEditSubvariable = (element, modified) => {
    setError("");
    const modified_array = subvariables.map((subvariable) => {
      if (subvariable.id_subvariable === element.id_subvariable) {
        return {
          id_subvariable: subvariable.id_subvariable,
          id_variable: subvariable.id_variable,
          nombre_subvariable: modified.nombre_subvariable,
          porcentaje_subvariable: modified.porcentaje_subvariable,
          estado_subvariable: subvariable.estado_subvariable,
          usuario_creacion: subvariable.usuario_creacion,
          fecha_creacion: subvariable.fecha_creacion,
        };
      }
      return subvariable;
    });
    setChanges([...changes, "EDITED"]);
    setSubvariables(modified_array);
  };

  const handleDelete = (element) => {
    //console.log("eliminando", element);
    //console.log("subvariables", subvariables);
    setError("");
    const filtered = subvariables.filter(
      (item) => item.id_subvariable !== element.id_subvariable
    );
    setChanges([...changes, "deleted SUVARIABLE"]);
    setSubvariables(filtered);
  };

  return (
    <Row
      style={{
        backgroundColor: "#F4F6F6",
        borderRadius: 5,
        padding: "1rem 0rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Col
        sm={4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <Form.Label
          style={{
            whiteSpace: "nowrap",
            margin: "0rem",
          }}
        >
          Nombre Subvariable
        </Form.Label>
      </Col>
      <Col
        sm={5}
        xs={8}
        style={{
          marginRight: "-1rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <VariableInput
          setValue={(e) => {
            setError("");
            setInputName(e);
            if (setModified) {
              setModified(e !== "");
            }
          }}
          label={"Nombre Subvariable"}
          maxLength={30}
        >
          {inputName}
        </VariableInput>
      </Col>
      <Col
        sm={3}
        xs={4}
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <VariableInput
          min={MIN_PERCENTAGE}
          percentage
          right
          setValue={setInputPercentage}
        >
          {inputPercentage}
        </VariableInput>
        &nbsp;%&nbsp;
        <Alert
          title={"Debe ingresar un nombre para la subvariable"}
          tooltip
          direction={"top"}
          disabled={inputName !== ""}
        >
          <div>
            <CrudButton
              plus
              size={30}
              color={"white"}
              background={COLORS.MODERNA_YELLOW}
              onClick={handleAddSubvariable}
              disabled={inputName === ""}
            />
          </div>
        </Alert>
      </Col>

      <Row
        style={{
          padding: 0,
          margin: 0,
          width: "100%",
        }}
      >
        <div style={{ display: "flex", padding: 10 }}>
          <label
            style={{
              fontSize: 14,
              // textAlign: "center",
              color: COLORS.MODERNA_RED,
            }}
          >
            {error ? `*${error}` : ""}
          </label>
        </div>
        <Col sm={12}>
          <div
            style={{
              padding: "0.15rem 0rem",
              backgroundColor:
                subvariables?.length > 0 ? "white" : "transparent",
            }}
          >
            {subvariables?.map((item, index) => {
              return (
                <ItemSubvariable
                  key={index}
                  validate={isValidSubvariable}
                  subvariable={item}
                  remove={handleDelete}
                  editeSubvariable={handleEditSubvariable}
                />
              );
            })}
          </div>
        </Col>
      </Row>
    </Row>
  );
}
