import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import style from "./css/style.module.css";
import { Loading } from "../../imports";
import { AiOutlineLeft as Left } from "react-icons/ai";
import { AiOutlineRight as Right } from "react-icons/ai";
const DEFAULT_IMAGES = [
  "https://i.ytimg.com/vi/7huEQgahrco/maxresdefault.jpg",
  // "https://s1.eestatic.com/2021/06/30/curiosidades/mascotas/592951586_193746688_1024x576.jpg",
  // "https://dam.muyinteresante.com.mx/wp-content/uploads/2022/07/cosas-mas-raras-que-hacen-los-gatos-1-770x449.jpg",
];

export default function Carrusel({ images, size = 300 }) {
  const [displayImages, setDisplayImages] = useState(DEFAULT_IMAGES);
  useEffect(() => {
    if (images) {
      if (Array.isArray(images)) {
        const arrayConValor = images.filter(
          (element) =>
            element !== null &&
            element !== "" &&
            element !== undefined &&
            element !== "null"
        );
        setDisplayImages(arrayConValor);
        return;
      }
      setDisplayImages([images]);
      return;
    }
    setDisplayImages(DEFAULT_IMAGES);
  }, [images]);

  return (
    <Container>
      {displayImages.length === 1 ? (
        displayImages[0] === "loading" ? (
          <div
            style={{
              // backgroundColor: "black",
              height: `${size}px`,
              // padding: "2px",
              width: "100%",
              marginBottom: "5px",
            }}
          >
            <Loading clean />
          </div>
        ) : (
          <img
            src={displayImages[0]}
            alt="Imagen"
            style={{
              // backgroundColor: "cyan",
              height: `${size}px`,
              // padding: "2px",
              width: "100%",
              marginBottom: "5px",
              objectFit: "contain",
            }}
          />
        )
      ) : (
        <Carousel
          className={style.imageBox}
          prevIcon={
            <Left
              size={50}
              style={{
                backgroundColor: "red",
                borderRadius: "50px",
                padding: "8px",
                margin: "0px",
              }}
            />
          }
          nextIcon={
            <Right
              size={50}
              style={{
                backgroundColor: "red",
                borderRadius: "50px",
                padding: "8px",
                margin: "0px",
              }}
            />
          }
        >
          {displayImages?.map((planogramaImage, id) => {
            return (
              <Carousel.Item key={id}>
                {planogramaImage === "loading" ? (
                  <div
                    style={{
                      height: `${size}px`,
                      // padding: "2px",
                      width: "100%",
                      marginBottom: "5px",
                    }}
                  >
                    <Loading clean />
                  </div>
                ) : (
                  <img
                    className="d-block w-100"
                    src={planogramaImage}
                    alt="First slide"
                    style={{ objectFit: "contain", height: `${size}px` }}
                  />
                )}
                {/* <Carousel.Caption>
                          <h3>First slide label</h3>
                          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                      </Carousel.Caption> */}
              </Carousel.Item>
            );
          })}
        </Carousel>
      )}
    </Container>
  );
}
