import React, { useMemo } from "react";
import { MdDeleteForever as DeleteButton } from "react-icons/md";
import { MdEdit as EditButton } from "react-icons/md";
import { MdRemoveRedEye as ViewButton } from "react-icons/md";
import { AiOutlineSearch as Search } from "react-icons/ai";
import { BsFillCalendarEventFill as Calendar } from "react-icons/bs";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { BsFillXSquareFill } from "react-icons/bs";
import { AiOutlinePlus as Plus } from "react-icons/ai";
import { FaCheck as Confirm } from "react-icons/fa";
import { RxCross2 as Cancel } from "react-icons/rx";
import { GoCheck as SaveButton } from "react-icons/go";
import { CgOptions } from "react-icons/cg";
import { AiFillCloseSquare } from "react-icons/ai";
import { AiOutlineReload as Reload } from "react-icons/ai";
import { BsFillEraserFill as Clean } from "react-icons/bs";
import { BiCheckCircle as Onn } from "react-icons/bi";
import { AiOutlineMinusCircle as Min } from "react-icons/ai";

import style from "./css/style.module.css";

export default function CrudButton({
  save,
  confirm,
  cancel,
  del,
  edit,
  view,
  color,
  size,
  background,
  onClick,
  padding,
  search,
  calendar,
  className,
  check,
  plus,
  filter,
  disabled,
  canceled,
  add,
  reload,
  clean,
  on,
  min,
  ...restOfProps
}) {
  const buttonIcon = useMemo(() => {
    const style = {
      backgroundColor: background,
      margin: "0px 1px",
      padding: padding ? padding : 5,
    };

    if (del) {
      return <DeleteButton style={style} size={size} color={color} />;
    }
    if (save) {
      return <SaveButton style={style} size={size} color={color} />;
    }
    if (confirm) {
      return <Confirm style={style} size={size} color={color} />;
    }
    if (cancel) {
      return <Cancel style={style} size={size} color={color} />;
    }
    if (edit) {
      return <EditButton style={style} size={size} color={color} />;
    }
    if (view) {
      return <ViewButton style={style} size={size} color={color} />;
    }
    if (search) {
      return <Search style={style} size={size} color={color} />;
    }
    if (calendar) {
      return <Calendar style={style} size={size} color={color} />;
    }
    if (check) {
      return <BsFillCheckSquareFill style={style} size={size} color={color} />;
    }
    if (plus) {
      return <Plus style={style} size={size} color={color} />;
    }
    if (filter) {
      return <CgOptions style={style} size={size} color={color} />;
    }
    if (clean) {
      return <Clean style={style} size={size} color={color} />;
    }
    if (on) {
      return <Onn style={style} size={size} color={color} />;
    }
    if (min) {
      return <Min style={style} size={size} color={color} />;
    }
    if (canceled) {
      return (
        <AiFillCloseSquare
          style={{ margin: "0px 0px", padding: "0px" }}
          size={size}
          color={color}
        />
      );
    }
    if (reload) {
      return (
        <Reload
          style={{ margin: "0px 0px", padding: "0px" }}
          size={size}
          color={color}
        />
      );
    }
  }, []);

  return (
    <div className={[className]} {...restOfProps}>
      <div
        className={`${style.icon} ${disabled && style.disabled}`}
        onClick={(e) => {
          e.stopPropagation();
          if (onClick && disabled !== true) {
            onClick(e);
          }
        }}
      >
        {buttonIcon}
      </div>
    </div>
  );
}
