import { VscLoading as Spinner } from "react-icons/vsc";
import style from "./style.module.css";
import { useEffect, useState } from "react";

export default function Loading2({ message }) {
  const label = message ? message : "Cargando";
  const [loadingText, setLoadingText] = useState(label);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingText((loadingText) => {
        switch (loadingText) {
          case label:
            return `${label}.`;
          case `${label}.`:
            return `${label}..`;
          case `${label}..`:
            return `${label}...`;
          case `${label}...`:
            return `${label}`;
          default:
            return `${label}`;
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={style.layout}>
      <div className={`${style.spinner}`}>
        <Spinner className={style.spinning} size={50} />
        {/* <a className={style.text}>{loadingText}</a> */}
      </div>
    </div>
  );
}
