import { ConstantsModerna } from "../common/constants/ConstantsModerna";
import { handleCheckUserSection } from "../util/Utils";

export const handleCheckAccessValidation = async (
  setLoadingBack,
  onSuccess,
  handleShowAlert
) => {
  setLoadingBack(true);
  try {
    const isAble = await handleCheckUserSection();
    if (isAble) {
      onSuccess();
    } else {
      handleShowAlert(ConstantsModerna.messages.USER_EDITING);
    }
  } catch (error) {
  } finally {
    setLoadingBack(false);
  }
};
