import React from "react";
import style from "./css/style.module.css";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import ModernaButton from "../../common/buttons/button-moderna/moderna-button";

export default function PageNotFound() {
  return (
    <Container className={style.container}>
      <div xs={12} className={style.text}>
        <div>ERROR</div>
        <h1 className={style.h1}>404</h1>
        <hr />
        <div>Página no encontrada</div>
        <br />
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <ModernaButton>Volver al inicio</ModernaButton>
        </Link>
      </div>
      <div className={style.astronaut}>
        <img
          className={style.astronaut_img}
          src="http://desarrollo.nacion-digital.com/moderna_new/wp-content/uploads/2020/04/Empaques.png"
          // src="https://images.vexels.com/media/users/3/152639/isolated/preview/506b575739e90613428cdb399175e2c8-space-astronaut-cartoon-by-vexels.png"
          alt="pan moderna"
        />
      </div>
    </Container>
  );
}
