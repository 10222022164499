import React, { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";
import { COLORS } from "../theme/theme";
import { FaChevronRight as Separator } from "react-icons/fa";
import { ROUTES } from "../../imports";
import { getCurrentPageName } from "../../util/routes";
import { handleCleanUserSection } from "../../util/Utils";

export default function PathDisplay() {
  const location = useLocation().pathname;
  const path = location.substring(1).split("/");
  const directory = path[0];

  const [right, setRight] = useState("");

  useEffect(() => {
    const pathName = getCurrentPageName(location.substring(1));
    document.title = `Moderna ${
      pathName !== "Moderna" ? " | " + pathName : ""
    }`;
    handleCleanUserSection();
    setRight(pathName);
  }, [location]);

  if (right?.includes("Moderna")) {
    return;
  }

  return (
    <div style={{ marginBottom: "1.5rem" }}>
      {!right?.includes("Moderna") && (
        <div
          style={{
            fontSize: 13,
            margin: "0.5rem 1rem 0rem",
            color: COLORS.TEXT_INFO_COLOR,
          }}
        >
          {directory.charAt(0).toUpperCase() + directory.slice(1)}
          <Separator style={{ margin: "-0.1rem 0.1rem 0rem" }} />
          {right}
        </div>
      )}
    </div>
  );
}
