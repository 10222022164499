const stringSimilarity = require("string-similarity");

export const validatePercentage = (value, minvalue) => {
  if (!value) {
    return minvalue ? minvalue.toString() : "0";
  }
  let percentage = validateFloat(value);

  if (!isNaN(percentage)) {
    if (percentage > 100) {
      return 100;
    }
    if (percentage < minvalue ? minvalue : 0) {
      return minvalue ? minvalue : "0";
    }
    return percentage;
  }
  return minvalue ? minvalue : "0";
};
export const validateNumericAsString = (value) => {
  return value.replace(/\D/g, "");
};

export const validateNumeric = (value) => {
  return parseInt(value.replace(/\D/g, ""), 10);
};
export const validateFloat = (value) => {
  let filtered = value.replace(",", ".");
  const specialChars = /[^0-9.]/g;
  filtered = filtered.replace(specialChars, "");

  const firstDotIndex = filtered.indexOf(".");
  if (firstDotIndex !== -1) {
    const decimalPart = filtered.slice(firstDotIndex + 1).replace(/\./g, "");
    const truncatedDecimalPart = decimalPart.slice(0, 2);
    filtered = filtered.slice(0, firstDotIndex + 1) + truncatedDecimalPart;
  }
  if (filtered.length > 1 && filtered[0] === "0") {
    filtered = filtered.slice(1);
  }
  return filtered;
};

export const validateSpecialCharacters = (string) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/;

  const filtered = string.replace(specialChars, "");

  return filtered;
};

export const validateSimilarity = (s1, s2) => {
  return stringSimilarity.compareTwoStrings(s1.toUpperCase(), s2.toUpperCase());
};
