import { selectAllByTable } from "./AxiosService";

const TABLE_STRUCTURE = {
  name: "catalogo",
  columns: ["id_catalogo", "codigo_catalogo", "descripcion"],
};

export const fetchCatalogVariableAsLabelValue = async () => {
  const response = await selectAllByTable(
    TABLE_STRUCTURE.name,
    TABLE_STRUCTURE.columns[1]
  );
  const values = Array.isArray(response)
    ? response.map((item) => {
        return { label: item.descripcion, value: item };
      })
    : [];
  return values;
};
