import React, { Children, useEffect, useRef, useState } from "react";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import { AiOutlineCaretDown as OpenButton } from "react-icons/ai";
import style from "./css/style.module.css";
import { CrudButton } from "../../../../../imports";
import { COLORS } from "../../../../../common/theme/theme";
import { RxCross2 as Cancel } from "react-icons/rx";

export default function ComboPlanograma({
  options,
  setSelected,
  title,
  label,
  filter,
  children,
  selected,
  className,
  errorMessages,
  disabled,
}) {
  const [error, setError] = useState("");
  const inputref = useRef();

  const [value, setValue] = useState(() => {
    const sel = options?.filter((option) => option.label == selected);
    if (sel?.length > 0) {
      return sel[0];
    }
  });

  const handleSelect = (e) => {
    if (setSelected) {
      setError();
      setValue(options[e]);
      setSelected(options[e].value);
    }
  };

  const capitalize = (value) => {
    if (typeof value !== "string") {
      return value;
    }
    return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className={`${style.dropdown} ${style.primary} ${className}`}
      style={style}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        if (inputref.current) {
          inputref.current.reset();
        }
      }}
    >
      <a
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </a>
      <div className={style.open}>
        <OpenButton />
      </div>
    </div>
  ));

  return (
    <Row className={`${style.supercontainer}`}>
      {label && <Col sm={3}>{label}</Col>}
      <Col sm={label ? 9 : 12}>
        <Dropdown onSelect={handleSelect} className={`${style.container} col`}>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            as={CustomToggle}
            style={`${style.primary}`}
          >
            {capitalize(value ? value.label : title)}
          </Dropdown.Toggle>
          <Dropdown.Menu
            as={filter && CustomMenu}
            inputref={inputref}
            className={style.menu}
          >
            {/* <div className={style.scrollView}> */}
            {options?.map((option, id) => (
              <Dropdown.Item disabled={disabled} eventKey={id} key={id}>
                {capitalize(option.label)}
              </Dropdown.Item>
            ))}
            {/* </div> */}
          </Dropdown.Menu>
        </Dropdown>
        <Col className={`${style.right}`}>{children}</Col>
        <p className={style.errorText}>{errorMessages}</p>
      </Col>
    </Row>
  );
}
const CustomMenu = React.forwardRef(
  (
    { children, style, className, "aria-labelledby": labeledBy, inputref },
    ref
  ) => {
    const [value, setValue] = useState("");

    const filteredChildren = React.Children.toArray(children).filter(
      (child) =>
        !value ||
        child.props.children.toLowerCase().includes(value.toLowerCase())
    );

    const handleFocus = (event) => {
      setValue(event.target.value);
    };

    const handleBlur = () => {
      setValue("");
    };

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <form
          ref={inputref}
          onReset={() => {
            setValue("");
          }}
        />
        <div
          style={{
            // backgroundColor: "black",
            display: "flex",
            marginBottom: "5px",
            alignItems: "center",
          }}
        >
          <Form.Control
            autoFocus
            // className="mx-3 my-2 w-75"
            // onFocus={handleFocus}
            // onBlur={handleBlur}
            placeholder="Buscador..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onClick={() => {
              setValue("");
            }}
            maxLength={40}
            style={{ width: "100%", margin: "0rem 0.7rem" }}
            // style={{ width: "100%", marginLeft: "10px" }}
          />
          <Cancel
            size={22}
            color={"gray"}
            onClick={() => setValue("")}
            style={{
              borderRadius: "5px",
              position: "absolute",
              right: "1.3rem",
            }}
          />
        </div>
        <ul className="list-unstyled" style={{ marginBottom: "0rem" }}>
          {filteredChildren.length > 0 ? (
            filteredChildren
          ) : (
            <li style={{ marginLeft: "1rem", marginTop: "0.5rem" }}>
              No se encontraron elementos.
            </li>
          )}
        </ul>
      </div>
    );
  }
);
