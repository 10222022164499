import React, { useState } from "react";
import Select, { components } from "react-select";
import style from "./style.module.css";
import { FiSearch as Search } from "react-icons/fi";
import { Col, Row } from "react-bootstrap";
import SelectSearchFetch from "../../pages/exhibidor/register/components/Select/SelectSearch";
// import { SelectSearchFetch } from "../../pages/exhibidor/register/components/Select/select";

export default function DropSearch({
  value,
  onSearchClient,
  data,
  label,
  onSelect,
  errorMessage,
  disabled,
}) {
  const options = [
    { label: "Opción 1", value: { id: 1, name: "Opción 1" } },
    { label: "Opción 2", value: { id: 2, name: "Opción 2" } },
    { label: "Opción 3", value: { id: 3, name: "Opción 3" } },
  ];

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} className={style.container}>
        <Search color="black" />
      </components.DropdownIndicator>
    );
  };
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (value) => {
    // Limitar la longitud del valor de entrada
    if (value.length <= 20) {
      setInputValue(value);
    }
  };

  const MyComponent = () => (
    <div className={style.box}>
      {/* {label ? <label>{label}</label> : ""} */}
      <Row>
        <Col>
          <Row className={style.item}>
            {label ? (
              <Col style={{ display: "flex", flex: "1" }}>
                <label className={style.lab}>{label ? label : "Nombre"}</label>
              </Col>
            ) : (
              ""
            )}
            <Col
              style={{
                // display: "flex",
                flex: "4",
                // justifyContent: "center",
                // backgroundColor: "green",
                width: "100%",
                // backgroundColor: "green",
                height: "100%",
              }}
            >
              {/* <Select
                // {...props}
                // inputValue={inputValue}
                placeholder="Buscar Cliente..."
                components={{ DropdownIndicator }}
                options={data ? data : options}
                isClearable={true}
                noOptionsMessage={() => "No se encontro el usuario"}
                onChange={(e) => {
                  if (onSelect) {
                    onSelect(e?.value);
                  }
                }}
                // onInputChange={handleInputChange}
              /> */}
              <SelectSearchFetch
                disabled={disabled}
                value={value}
                onSelect={onSelect}
                onSearchClient={onSearchClient}
              ></SelectSearchFetch>
            </Col>
          </Row>
          <Row>
            <Col style={{ display: "flex", flex: 1 }}></Col>
            <Col style={{ display: "flex", flex: 4, position: "relative" }}>
              <p className={style.errorText}>{errorMessage}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
  return <MyComponent />;
}
