import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { CrudButton } from "../../imports";
import { COLORS } from "../theme/theme";
import style from "./css/style.module.css";
export default function Input({
  inputRef,
  label,
  block,
  name,
  value,
  onChange,
  center,
  maxLength,
  inputStyle,
  onFocusLost,
  errorMessage,
  className,
}) {
  const capitalize = (value) => {
    if (typeof value !== "string") {
      return value;
    }
    return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
  };
  return (
    <div className={style.container}>
      <Row>
        <Col>
          <Row className={style.item}>
            {label && (
              <Col style={{ display: "flex", flex: 1 }}>
                <label className={style.lab}>{label}</label>
              </Col>
            )}
            <Col
              style={{
                display: "flex",
                flex: 4,
                // backgroundColor: "black",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Control
                onBlur={onFocusLost}
                value={className ? capitalize(value) : value}
                onChange={onChange}
                name={name}
                ref={inputRef}
                className={`${style.inputstyle} ${className}`}
                disabled={block}
                maxLength={maxLength}
                style={inputStyle}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ display: "flex", flex: 1 }}></Col>
            <Col style={{ display: "flex", flex: 4, position: "relative" }}>
              <p className={style.errorText}>{errorMessage}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
