import { ConstantsModerna } from "../common/constants/ConstantsModerna";
import {
  axiosGetToken,
  deleteById,
  insertIntoTable,
  postAxiosRead,
} from "../service/AxiosService";

const PLANOGRAMA_FOLDER = "Planograma";
const EXHIBIDOR_FOLDER = "Exhibidor";

export const getDateAtributes = (date, type) => {
  try {
    let temp = date.split("T");
    let temp1 = temp[0].split("-");
    switch (type) {
      case "M": {
        return temp1[1];
      }
      case "Y": {
        return temp1[0];
      }
      case "D": {
        return temp1[2];
      }
    }
    return date;
  } catch (e) {
    return "0";
  }
};

let userLog = "";

export const userLogeado = (userLog2) => {
  userLog = userLog2;
};

export const handleCheckUserSection = async () => {
  const currentDate = new Date();
  currentDate.setMinutes(currentDate.getMinutes() + 60); ///la hora xdxdxdxdxdd
  const updatedDateTime = currentDate.toISOString();

  const userId = localStorage.getItem("XXS");

  const ESS = [
    null,
    userId,
    updatedDateTime,
    userLog,
    new Date().toISOString(),
    new Date().toISOString(),
    getCurrentWebPath(),
  ];
  try {
    if (!userId) {
      return false;
    }
    const { data } = await postAxiosRead(
      ConstantsModerna.queriesDb.READ,
      ConstantsModerna.tableNamesDb.SESSION
    );
    if (data.data.length <= 0) {
      if (!getCurrentWebPath()) {
        return false;
      }

      await insertIntoTable(
        ConstantsModerna.tableNamesDb.SESSION,
        getKeysTable(Object.keys(ConstantsModerna.tableNamesDb)[0]),
        ESS
      );
      return true; //no esta editando
    } else {
      let isAble = true;
      let canCreate = true;
      for (const session of data.data) {
        if (session.ruta == getCurrentWebPath()) {
          if (new Date() < new Date(session.tiempo_expiracion)) {
            //borrar la sesion de base
            canCreate = false;
            isAble = session.id_usuario == userId ? true : false;
          } else {
            await deleteById("sesion", ["id_sesion"], [session.id_sesion]);
          }
          break;
        }
      }
      if (canCreate) {
        await insertIntoTable(
          ConstantsModerna.tableNamesDb.SESSION,
          getKeysTable(Object.keys(ConstantsModerna.tableNamesDb)[0]),
          ESS
        );
      }
      return isAble;
    }
  } catch (error) {
    return false; //si esta editando
  }
};
export const handleCleanUserSection = async () => {
  try {
    await deleteById(
      ConstantsModerna.tableNamesDb.SESSION,
      [ConstantsModerna.columnTables.SESSION.ID_USUARIO],
      [localStorage.getItem("XXS")]
    );
  } catch (error) {}
};

export const getKeysTable = (obj2) => {
  const keys = Object.keys(ConstantsModerna.columnTables[obj2]);
  return keys.map((key) => {
    return ConstantsModerna.columnTables[obj2][key];
  });
};

export const getCurrentWebPath = () => {
  try {
    let location = window.location.href;
    location = location.replace(`${window.location.origin}/`, "");
    location = location.split("?");
    return location[0];
  } catch (e) {
    return null;
  }
};

export const uploadPlanogramaImage = async (imgURl, imgId) => {
  const BASE_URL = `https://modernaalimentos-my.sharepoint.com/personal/app_fotoexito_moderna_com_ec/Documents/TiendaPerfecta/${PLANOGRAMA_FOLDER}/`;
  const folderId = "01FWEOYBFE43LTUTL7FRAZZZTWYBDOVOKD";
  const response = await uploadImgOneDrive(imgURl, imgId, folderId);
  if (response) {
    const imageUrl = BASE_URL + response.url;
    return { id: response.id, url: imageUrl };
  }
  throw new Error("Error uploading the image");
};

export const uploadExhibidorImage = async (imgURl, imgId) => {
  const BASE_URL = `https://modernaalimentos-my.sharepoint.com/personal/app_fotoexito_moderna_com_ec/Documents/TiendaPerfecta/${EXHIBIDOR_FOLDER}/`;
  const folderId = "01FWEOYBCLTQCUVOYSPBAL3OVD7S2VASNL";
  const response = await uploadImgOneDrive(imgURl, imgId, folderId);
  if (response) {
    const imageUrl = BASE_URL + response.url;
    return { id: response.id, url: imageUrl };
  }
  throw new Error("Error uploading the image");
};

const uploadImgOneDrive = async (imgURl, imgId, folderId) => {
  try {
    const {
      data: { token },
    } = await axiosGetToken();
    const filename = "" + imgId + ".png";
    const driveId =
      "b!8xz3gdf43Eq0KCkvbna31vxpGAuGOrpPnBVuUAfQKqbgq9iLUK9GT5ZWD37MzEAh";
    const response = await fetch(imgURl);
    const blob = await response.blob();

    const uploadResponse = await fetch(
      `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${folderId}:/${filename}:/content`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "image/jpeg",
        },
        body: blob, // binary image dataff
      }
    );

    if (uploadResponse.ok) {
      const json = await uploadResponse.json();
      return { id: json?.id, url: filename };
    } else {
      throw new Error("error");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteImgOneDrive = async (imgId) => {
  try {
    const {
      data: { token },
    } = await axiosGetToken();

    let deleteResponse1 = await fetch(
      `https://graph.microsoft.com/v1.0/me/drive/items/${imgId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (deleteResponse1.status === 204) {
      return { ok: true };
    } else {
      throw new Error("error");
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getPlanogramaIdImgOneDrive = async (imgId) => {
  const response = await getItemIdImgOneDrive(imgId, PLANOGRAMA_FOLDER);
  return response;
};
export const getExhibidorIdImgOneDrive = async (imgId) => {
  const response = await getItemIdImgOneDrive(imgId, EXHIBIDOR_FOLDER);
  return response;
};

const getItemIdImgOneDrive = async (imgId, folder) => {
  try {
    const {
      data: { token },
    } = await axiosGetToken();
    let itemResponse1 = await fetch(
      `https://graph.microsoft.com/v1.0/me/drive/root:/TiendaPerfecta/${folder}/${imgId}.png`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let itemResponse = await itemResponse1.json();
    if (itemResponse1.status === 200) {
      return itemResponse;
    } else {
      throw new Error("error");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const verifyPlanogramaImage = async (url_imagen, name_image) => {
  const res = await verifyUrlImage(url_imagen, name_image, PLANOGRAMA_FOLDER);
  return res;
};
export const verifyExhibidorImage = async (url_imagen, name_image) => {
  const res = await verifyUrlImage(url_imagen, name_image, EXHIBIDOR_FOLDER);
  return res;
};

const verifyUrlImage = async (url_imagen, name_image, folder) => {
  return url_imagen;
  // try {
  //   const {
  //     data: { token },
  //   } = await axiosGetToken();

  //   await fetch(url_imagen, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   return url_imagen;
  // } catch (e) {
  //   try {
  //     const data = await getItemIdImgOneDrive(name_image, folder);
  //     return data["@microsoft.graph.downloadUrl"];
  //   } catch (e) {
  //     return url_imagen;
  //   }
  // }
};

export const getCurrentUrlImage = async (name_image, folderType) => {
  try {
    switch (folderType) {
      case "EX": {
        const data = await getItemIdImgOneDrive(name_image, EXHIBIDOR_FOLDER);
        return data["@microsoft.graph.downloadUrl"];
      }
      case "PL": {
        const data = await getItemIdImgOneDrive(name_image, PLANOGRAMA_FOLDER);
        return data["@microsoft.graph.downloadUrl"];
      }
    }
    return "";
  } catch (e) {
    return "";
  }
};
