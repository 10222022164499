import { VscLoading as Spinner } from "react-icons/vsc";
import style from "./css/style.module.css";
import { useEffect, useState } from "react";

export default function Loading({ message, clean }) {
  const label = message ? message : "Cargando";
  const [loadingText, setLoadingText] = useState(label);

  useEffect(() => {
    if (clean) {
      return;
    }
    const interval = setInterval(() => {
      setLoadingText((loadingText) => {
        switch (loadingText) {
          case label:
            return `${label}.`;
          case `${label}.`:
            return `${label}..`;
          case `${label}..`:
            return `${label}...`;
          case `${label}...`:
            return `${label}`;
          default:
            return `${label}`;
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`${style.spinner} ${clean && style.clean}`}>
      <Spinner className={style.spinning} size={50} />
      {!clean && <a className={style.text}>{loadingText}</a>}
    </div>
  );
}
