import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import style from "../css/style.module.css";
import { Col, Row } from "react-bootstrap";
import { CrudButton } from "../../../../imports";

export default function SearchDrop({
  data,
  placeHolder,
  type,
  onSelect,
  onCancel,
  disabled,
  onClear,
  value,
}) {
  const handleSelectChange = (selectedOption) => {
    onSelect(selectedOption);
  };

  const DropdownIndicator = (props) => {
    if (type === "search") {
      return <CrudButton search size={30} color={"black"} />;
    } else {
      return <CrudButton filter size={30} color={"black"} />;
    }
  };

  const MyComponent = () => (
    <div className={style.box}>
      <Row>
        <Col>
          <Row className={style.item}>
            <Col
              className={style.select}
              style={{
                width: "400px",
                color: "black",
                marginBottom: "10px",
                backgroundColor: "white",
              }}
            >
              <Select
                placeholder={placeHolder}
                components={{ DropdownIndicator }}
                options={data}
                isClearable={true}
                onChange={handleSelectChange}
                value={value}
                styles={{ alignContent: "flex-start" }}
                isDisabled={disabled}
                noOptionsMessage={() => "No se encontraron resultados"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
  return <MyComponent />;
}
