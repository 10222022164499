import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Alert, ButtonModerna, ComboBox } from "../../../../imports";
import SubvariableHeader from "./subvariable-crud/subvariable-crud";
import { COLORS } from "../../../../common/theme/theme";
import VariableInput from "./input/variable-input";
import { ID_PREFIX } from "../../../../service/VariableService";
import { validateSimilarity } from "../../../../util/validations";
import { useMsal } from "@azure/msal-react";
import style from "./style.module.css";

const MIN_LENGTH = 1;

export default function CreateVariable({
  close,
  cancel,
  clientType,
  editing,
  modified,
  modifies,
  editeVariable,
  saveVariable,
  variables,
  totalPercentage,
  handleModified,
  catalog,
  usedCatalog,
  varPortafolioRef,
  varPrecioRef,
}) {
  const [changes, setChanges] = useState([]);
  const initialName = editing?.nombre_variable ? editing.nombre_variable : "";
  const initialWeigth = editing?.porcentaje_variable
    ? totalPercentage - editing.porcentaje_variable
    : totalPercentage;
  const [activePercentage, setActivePercentage] = useState(initialWeigth);

  const [variableName, setVariableName] = useState(initialName);

  const [percentage, setPercentage] = useState(
    editing?.porcentaje_variable ? editing.porcentaje_variable : 0
  );
  const initialSubvariables = editing?.subvariables ? editing.subvariables : [];
  const [subvariables, setSubvariables] = useState(initialSubvariables);
  const [errorVariable, setErrorVariable] = useState("");
  const [errorPercentage, setErrorPercentage] = useState("");
  const [errorSubVariables, setErrorSubVariables] = useState("");

  const { accounts } = useMsal();

  const handleSave = async () => {
    const _id = editing ? editing.id_variable : `${ID_PREFIX}${new Date()}`;
    if (!isValidSubvariable(variableName, setErrorVariable, _id)) {
      return;
    }
    const usuario_creacion = editing
      ? editing.usuario_creacion
      : accounts[0].username;
    if (!isValidSubvariable(variableName, setErrorVariable, _id)) {
      return;
    }
    let estado = 1;
    if (editing) {
      estado = editing.estado_variable ? 1 : 0;
    } else {
      estado = percentage !== 0 ? 1 : 0;
    }
    const variable = {
      id_variable: _id,
      nombre_variable: variableName,
      id_grupo_cliente: clientType.id_grupo_cliente,
      estado_variable: estado,
      porcentaje_variable: percentage,
      subvariables: subvariables,
      usuario_creacion: usuario_creacion,
      fecha_creacion: editing?.fecha_creacion,
      fecha_modificacion: editing?.fecha_modificacion,
    };

    if (Array.isArray(variables)) {
      let exists = false;
      variables.forEach((element) => {
        if (
          validateSimilarity(
            element.nombre_variable,
            variable.nombre_variable
          ) >= 0.966 &&
          element.id_variable !== variable.id_variable
        ) {
          exists = true;
          setErrorVariable(
            `Ya existe una variable con este nombre '${element.nombre_variable}'`
          );
          return;
        }
      });
      if (exists) {
        return;
      }
      if (editing) {
        if (editeVariable) {
          handleModified(`EDITED${variable.id_variable}`);
          editeVariable(variable);
        }
      } else if (saveVariable) {
        saveVariable(variable);
      }
      close();
      return;
    }
    setErrorVariable("No se ha podido validar la nueva variable");
  };

  useEffect(() => {
    let _error = "";

    if (variableName === varPrecioRef.current) {
      const index = usedCatalog.findIndex(({ label: nombre_variable }) => {
        return nombre_variable === varPortafolioRef.current;
      });
      if (index === -1) {
        _error = `Para registrar la variable "${varPrecioRef.current}" debe registrar antes la variable "${varPortafolioRef.current}"`;
      } else if (
        usedCatalog[index].value !== true &&
        usedCatalog[index].value !== 1
      ) {
        _error = `Para registrar la variable "${varPrecioRef.current}", la variable "${varPortafolioRef.current}" debe estar activa`;
      }
    }
    setErrorVariable(_error);
  }, [variableName]);

  useEffect(() => {
    let total = 0;
    if (subvariables) {
      subvariables.forEach((item) => {
        if (item.estado_subvariable === 1) {
          total += parseFloat(item.porcentaje_subvariable);
        }
      });
    }
    setActivePercentage(total);
  }, [subvariables]);

  useEffect(() => {
    let _error = "";
    if (
      parseFloat(activePercentage) !== parseFloat(percentage) &&
      parseFloat(activePercentage) !== 0
    ) {
      _error = `La sumatoria de los % de las subvariables
    (${parseFloat(activePercentage)}%) debe ser igual al porcentaje
    de la variable (${parseFloat(percentage)}%). Favor corregir los
    % para guardar los cambios`;
    }
    setErrorSubVariables(_error);
  }, [percentage, activePercentage]);

  const isValidSubvariable = (value, _setError, id) => {
    if (value.replace(" ", "")?.length < MIN_LENGTH) {
      _setError(`El nombre de la variable debe ser mayor a ${MIN_LENGTH}`);
      return false;
    }
    let valid = true;
    variables.forEach((element) => {
      if (
        validateSimilarity(element.nombre_variable, value) >= 0.966 &&
        element.id_variable !== id
      ) {
        _setError(
          `Ya existe una variable con este nombre '${element.nombre_variable}'`
        );
        valid = false;
        return;
      }
    });
    return valid;
  };

  return (
    <div style={{ padding: "0rem 1rem", maxWidth: 500 }}>
      <Row sm={10}>
        <Col>
          <Form.Group className="mb-3">
            <Row>
              <Col
                sm={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Form.Label style={{ margin: "0rem" }}>Variable</Form.Label>
              </Col>
              <Col sm={6} xs={9} style={{ padding: "0rem" }}>
                <ComboBox
                  className={style.combobox}
                  onSelect={(e) => {
                    if (e) {
                      setChanges([...changes, `${e?.label}`]);
                    }

                    setVariableName(e?.label ? e.label : "");
                  }}
                  selectedLabel={variableName}
                  secondary
                  options={catalog}
                  title={"Variable"}
                  banned={usedCatalog}
                ></ComboBox>
              </Col>
              <Col
                sm={3}
                xs={3}
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <VariableInput
                  right
                  setValue={(e) => {
                    setChanges([...changes, `CHANGED:${e}`]);
                    setPercentage(`${e}`);
                    let _error = "";
                    if (parseFloat(e) + parseFloat(initialWeigth) > 100) {
                      _error = `La sumatoria de los % individuales de las variables activas no pueden
                      superar el 100%, el total actual es de
                      ${parseFloat(initialWeigth) + parseFloat(e)}%. Favor
                      corregir los % para guardar los cambios`;
                    }
                    setErrorPercentage(_error);
                  }}
                  percentage
                >{`${percentage}`}</VariableInput>
                &nbsp;&nbsp;&nbsp;%&nbsp;&nbsp;
              </Col>
            </Row>
            {errorVariable !== "" && (
              <label
                style={{
                  fontSize: 14,
                  // textAlign: "center",
                  color: COLORS.MODERNA_RED,
                }}
              >
                *{errorVariable}
              </label>
            )}
            {errorPercentage !== "" && (
              <label
                style={{
                  fontSize: 14,
                  // textAlign: "center",
                  color: COLORS.MODERNA_RED,
                }}
              >
                *{errorPercentage}
              </label>
            )}
          </Form.Group>
          <Form.Group>
            <SubvariableHeader
              setModified={modified}
              updatePercentaje={setActivePercentage}
              subvariables={subvariables}
              setSubvariables={setSubvariables}
              setChanges={setChanges}
              changes={changes}
            />
          </Form.Group>
        </Col>
      </Row>
      {errorSubVariables !== "" && (
        <label
          style={{
            fontSize: 14,
            color: COLORS.MODERNA_RED,
            textAlign: "justify",
          }}
        >
          *{errorSubVariables}
        </label>
      )}
      <Row
        sm={12}
        style={{
          marginTop: 10,
        }}
      >
        <Col xs={6} xl={6} style={centeredCol}>
          <ButtonModerna
            secondary
            onClick={handleSave}
            disabled={
              variableName.length < MIN_LENGTH ||
              percentage > 100 ||
              errorPercentage !== "" ||
              errorVariable !== "" ||
              errorSubVariables !== "" ||
              changes.length === 0
            }
          >
            Aceptar
          </ButtonModerna>
        </Col>
        <Col xs={6} xl={6} style={centeredCol}>
          <ButtonModerna onClick={cancel}>Cancelar</ButtonModerna>
        </Col>
      </Row>
    </div>
  );
}

const centeredCol = {
  display: "flex",
  justifyContent: "center",
};
