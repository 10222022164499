import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CrudButton } from "../../../../../imports";
import { COLORS } from "../../../../../common/theme/theme";
import style from "./style.module.css";
import VariableInput from "../input/variable-input";

export default function ItemSubvariable({
  subvariable,
  remove,
  validate,
  editeSubvariable,
}) {
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(subvariable.nombre_subvariable);
  const [weight, setWeight] = useState(subvariable.porcentaje_subvariable);
  const [error, setError] = useState("");

  useEffect(() => {
    setName(subvariable.nombre_subvariable);
    setWeight(subvariable.porcentaje_subvariable);
    setEditing(false);
  }, [subvariable]);

  const handleEdit = () => {
    setEditing(true);
  };
  const handleConfirm = () => {
    if (!validate(name, setError, subvariable.id_subvariable)) {
      return;
    }
    if (editeSubvariable) {
      editeSubvariable(subvariable, {
        nombre_subvariable: name,
        porcentaje_subvariable: weight,
      });
    }
    setEditing(false);
  };

  const handleRemove = () => {
    setError("");
    if (remove) {
      remove(subvariable);
    }
  };
  const handleCancel = () => {
    setError("");
    setName(subvariable.nombre_subvariable);
    setWeight(subvariable.porcentaje_subvariable);
    setEditing(false);
  };
  return (
    <div>
      <Row className={`${style.container}`}>
        <Col className={`${style.box} ${editing && style.editing}`}>
          <Col xl={8} xs={8} style={{ marginRight: "0.1rem" }}>
            <VariableInput
              disabled={!editing}
              setValue={(e) => {
                setError("");
                setName(e);
              }}
            >
              {name}
            </VariableInput>
          </Col>
          <Col>
            <div className={style.buttons}>
              <VariableInput
                disabled={!editing}
                percentage
                right
                setValue={setWeight}
              >
                {weight}
              </VariableInput>
              %&nbsp;
              {!editing ? (
                <CrudButton
                  background={COLORS.MODERNA_RED}
                  size={30}
                  color="white"
                  del
                  onClick={handleRemove}
                />
              ) : (
                <div>
                  <CrudButton
                    background={COLORS.MODERNA_RED}
                    size={30}
                    color="white"
                    cancel
                    onClick={handleCancel}
                  />
                </div>
              )}
              {!editing ? (
                <CrudButton
                  background={COLORS.MODERNA_YELLOW}
                  size={30}
                  color="white"
                  edit
                  onClick={handleEdit}
                />
              ) : (
                <div>
                  <CrudButton
                    background={COLORS.MODERNA_GREEN}
                    size={30}
                    color="white"
                    save
                    onClick={handleConfirm}
                    disabled={!error ? false : true}
                  />
                </div>
              )}
            </div>
          </Col>
        </Col>
      </Row>
      {error && (
        <Row
          style={{
            padding: "0rem 1.5rem",
            fontSize: 13,
            color: COLORS.MODERNA_RED,
          }}
        >
          *{error}
        </Row>
      )}
    </div>
  );
}
