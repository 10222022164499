import { COLORS } from "../theme/theme";

export const SweetAlert = async (title) => {
  const Swal = require("sweetalert2");

  return new Promise((resolve) => {
    Swal.fire({
      // title: title,
      text: title,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      cancelButtonColor: COLORS.MODERNA_YELLOW,
      confirmButtonColor: COLORS.MODERNA_RED,
    }).then((result) => {
      resolve(result.isConfirmed);
    });
  });
};
