import axios from "axios";

const client = axios.create();
export const setBearerToken = (token) => {
  client.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["Content-Type"] = "image/jpg";
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const putImage = async (files, url, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "image/jpeg", // Ajusta el tipo de contenido según el archivo que estés subiendo
  };

  try {
    await client.put(url, files[0], { headers });
  } catch (e) {}
};
export default client;
