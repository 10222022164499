import { da } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import style from "./css/style.module.css";
import { PAGINATION_CONFIG } from "../../config/app-config";
const PATH_NAME = "pagina";

export default function ModernaPagination({ setPage, data }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [actualPage, setActualPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    for (let entry of searchParams.entries()) {
      if (entry[0] === PATH_NAME) {
        if (!entry[1] || isNaN(entry[1]) || entry[1] < 1) {
          handleChangePage(1);
          return;
        }
        const _p = parseInt(entry[1]) > 0 ? parseInt(entry[1]) : 1;
        setActualPage(_p);

        if (setPage) {
          setPage(_p);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (data.total_elements < actualPage * data.limit) {
        handleChangePage(data.total_pages);
      }
    }
  }, [data]);

  const handleChangePage = (_number_page) => {
    const number_page = _number_page > 0 ? _number_page : 1;
    if (!PAGINATION_CONFIG.ENABLED) {
      return;
    }
    let parameters = "?";
    const params = [];
    let hasParam = false;
    for (let entry of searchParams.entries()) {
      if (entry[0] !== PATH_NAME) {
        params.push({ name: entry[0], value: entry[1] });
      } else {
        hasParam = true;
        params.push({ name: PATH_NAME, value: number_page });
      }
    }
    if (!hasParam) {
      params.push({ name: PATH_NAME, value: number_page });
    }
    params.forEach((variable) => {
      parameters += `${variable.name}=${variable.value}&`;
    });
    if (parameters.slice(-1) === "&") {
      parameters = parameters.slice(0, -1);
    }
    setActualPage(number_page);
    if (setPage) {
      setPage(number_page);
    }
    navigate(parameters);
  };
  return (
    <div
      style={{
        borderTopWidth: "1px",
        borderTopColor: "gray",
        borderTopStyle: "solid",
        paddingTop: "5px",
        marginBottom: "20px",
        marginTop: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {data?.total_elements > 0 ? (
          <div>
            Mostrando registros del {(data?.page - 1) * data?.limit + 1} al{" "}
            {data?.page * data?.limit <= data?.total_elements
              ? data?.page * data?.limit
              : data?.total_elements}{" "}
            de un total de {data?.total_elements} registros
          </div>
        ) : (
          <div>No hay elementos que mostrar</div>
        )}

        {data?.total_pages > 1 && (
          <Pagination style={{ margin: "0rem" }}>
            {data?.page > 2 && (
              <Pagination.First
                onClick={() => {
                  handleChangePage(1);
                }}
                className={style.item}
              />
            )}
            <Pagination.Prev
              className={style.item}
              onClick={() => {
                handleChangePage(actualPage - 1);
              }}
              disabled={actualPage === 1}
            />
            {actualPage > 1 && (
              <Pagination.Item
                className={style.item}
                onClick={() => {
                  handleChangePage(actualPage - 1);
                }}
              >
                {actualPage - 1}
              </Pagination.Item>
            )}
            <Pagination.Item className={`${style.item} ${style.active}`}>
              {actualPage}
            </Pagination.Item>
            {data?.total_pages > actualPage && (
              <Pagination.Item
                className={style.item}
                onClick={() => {
                  handleChangePage(actualPage + 1);
                }}
              >
                {actualPage + 1}
              </Pagination.Item>
            )}
            <Pagination.Next
              disabled={data?.total_pages <= actualPage}
              onClick={() => {
                handleChangePage(actualPage + 1);
              }}
              className={style.item}
            />
            {data?.page < data.total_pages - 1 && (
              <Pagination.Last
                onClick={() => {
                  handleChangePage(data.total_pages);
                }}
                className={style.item}
              />
            )}
          </Pagination>
        )}
      </div>
    </div>
  );
}
