import { getKeysTable } from "../../util/Utils"

export class ConstantsModerna {
  constructor() {

  }
  static enpoints = {
    generalFunction: "https://fotoexito1.azurewebsites.net/api/functionGeneral?code=PfkH6TT2D6DBtUdFhK5lHf2-7Z62TpVnNL6_Z4Oz8KY_AzFucJZ_Vg==",
    getAccesToken:"https://fotoexito1.azurewebsites.net/api/getAccesToken?code=kyjbYT96Qfu_70h8FiMD0LqXHhbS7-pr_AMrXCjePuMlAzFuV4ZKLA=="
  }
  static tableNamesDb = {
    SESSION: "sesion"
  }
  static queriesDb = {
    READ: "SELECT",
    LIKE: "LIKE"
  }
  static columnTables = {
    SESSION: {
      ID_SESSION: "id_sesion",
      ID_USUARIO: "id_usuario",
      TIME_EXPIRATION: "tiempo_expiracion",
      CREATION_USER: "usuario_creacion",
      DATE_CREATION: "fecha_creacion",
      DATE_UPDATED: "fecha_modificacion",
      PATH: "ruta"
    }
  }
  static messages={
    USER_EDITING:"Otro usuario ya está editando esta sección, espera a que termine"
  }
}

