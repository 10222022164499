// const REDIRECT_URI = "http://localhost:3000/";
//--------------------
const REDIRECT_URI = "https://foto-exito.vercel.app/";
//aaa
export const msalConfig = {
  auth: {
    clientId: "e8b0dfd1-3041-493e-be24-f8a55c5ef37e",
    authority:
      "https://login.microsoftonline.com/d7060bcc-bb26-4708-a91d-88362a86ae35",
    // redirectUri:,
    redirectUri: REDIRECT_URI,
    // postLogoutRedirectUri: REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
      },
      logLevel: "info",
      piiLoggingEnabled: false,
    },
    accountMode: "SINGLE", // Configura accountMode como "SINGLE" para evitar cerrar sesión en el navegador
  },
};

export const loginRequest = {
  scopes: ["User.Read", "Files.ReadWrite.All"],
  prompt: "select_account",
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
