import { PAGINATION_CONFIG } from "../config/app-config";
import { axios } from "../imports";
import {
  deleteById,
  insertIntoTable,
  postToDataBaseJoin,
  updateTableById,
} from "./AxiosService";

// __________________Estrucutra de la base de datos________________

const URI_PLANOGRAMA =
  "https://fotoexito1.azurewebsites.net/api/FunctionPlanograma?code=w_-sEDH93lCoZq35BIRN0F9f6TUlwT8WjF8eGXJHlu5vAzFu2A42ng==";

const DATABASE_STRUCTURE = {
  tableName: "planograma",
  columns: [
    "id_planograma",
    "id_categoria",
    "id_grupo_cliente",
    "url_imagen1",
    "url_imagen2",
    "url_imagen3",
    "fecha_creacion",
    "fecha_modificacion",
    "estado",
    "id_imagen1",
    "id_imagen2",
    "id_imagen3",
  ],
  foreingFields: [
    {
      tableName: "grupo_cliente",
      data: ["nombre_grupo_cliente"],
      foreingKey: "id_grupo_cliente",
    },
    {
      tableName: "categoria",
      data: ["nombre_categoria"],
      foreingKey: "id_categoria",
    },
  ],
  columnsEditable: [
    "id_planograma",
    "id_categoria",
    "id_grupo_cliente",
    "url_imagen1",
    "url_imagen2",
    "url_imagen3",
    "fecha_modificacion",
    "estado",
    "id_imagen1",
    "id_imagen2",
    "id_imagen3",
  ],
};

export const fetchPlanograma = async (page) => {
  return await fetchPlanogramaDataBase(page);
};

export const fetchPlanogramaFromDataBase = async (page) => {
  let response;
  try {
    const data = {
      typeQuery: "SELECT",
      data: {
        page: page - 1,
        limit: PAGINATION_CONFIG.LIMIT,
      },
    };
    response = await axios.post(URI_PLANOGRAMA, data);
    return response.data;
  } catch (e) {
    response = e;
  }
  return response;
};

export const insertPlanograma = async (planid) => {
  const date = new Date();
  planid.fecha_creacion = date.toISOString();
  return await insertPlanogramaDataBase(planid);

  // return PLANOGRAMA;
};

export const deletePlanograma = async (planid) => {
  const result = await deletePlanogramaDataBase(planid);
  return result;
};

export const editPlanograma = async (data) => {
  const date = new Date();
  if (!data.fecha_creacion) {
    data.fecha_creacion = date.toISOString();
  }
  data.fecha_modificacion = date.toISOString();
  const response = await updatePlanogramaDataBase(data);
  return response;
};

export const activatePlanogramaToDataBase = async (item) => {
  // console.log("mi item en la function es::::", item);
  const date = new Date();
  const values = [
    item.id_planograma,
    item.id_categoria,
    item.id_grupo_cliente,
    item.estado,
    date.toISOString(),
  ];
  const response = await updateTableById(
    DATABASE_STRUCTURE.tableName,
    [
      DATABASE_STRUCTURE.columnsEditable[0],
      DATABASE_STRUCTURE.columnsEditable[1],
      DATABASE_STRUCTURE.columnsEditable[2],
      DATABASE_STRUCTURE.columnsEditable[7],
      DATABASE_STRUCTURE.columnsEditable[6],
    ],
    values
  );
  return response;
};

// const updatePlanogramaDataBase = async (updated) => {
//   const date = new Date();
//   const values = [
//     updated.id_planograma,
//     updated.id_categoria,
//     updated.id_grupo_cliente,
//     updated.url_imagen1,
//     updated.url_imagen2,
//     updated.url_imagen3,
//     date.toISOString(),
//     updated.estado,
//   ];
//   const response = await updateTableById(
//     DATABASE_STRUCTURE.tableName,
//     DATABASE_STRUCTURE.columnsEditable,
//     values
//   );
//   return response;
// };
// __________________________funciones azure__________________________________

const fetchPlanogramaDataBase = async (page) => {
  const response = await postToDataBaseJoin(
    DATABASE_STRUCTURE.tableName,
    DATABASE_STRUCTURE.columns,
    DATABASE_STRUCTURE.foreingFields,
    page
  );
  return response;
};

const insertPlanogramaDataBase = async (insert) => {
  const date = new Date();
  const values = [
    insert.id_planograma,
    insert.id_categoria,
    insert.id_grupo_cliente,
    insert.url_imagen1,
    insert.url_imagen2,
    insert.url_imagen3,
    date.toISOString(),
    date.toISOString(),
    insert.estado,
    insert.id_imagen1,
    insert.id_imagen2,
    insert.id_imagen3,
  ];
  const response = await insertIntoTable(
    DATABASE_STRUCTURE.tableName,
    DATABASE_STRUCTURE.columns,
    values
  );
  return response;
};

const updatePlanogramaDataBase = async (updated) => {
  const date = new Date();
  const values = [
    updated.id_planograma,
    updated.id_categoria,
    updated.id_grupo_cliente,
    updated.url_imagen1,
    updated.url_imagen2,
    updated.url_imagen3,
    date.toISOString(),
    updated.estado,
    updated.id_imagen1,
    updated.id_imagen2,
    updated.id_imagen3,
  ];
  const response = await updateTableById(
    DATABASE_STRUCTURE.tableName,
    DATABASE_STRUCTURE.columnsEditable,
    values
  );
  return response;
};

const deletePlanogramaDataBase = async (deleted) => {
  const values = [deleted];
  const response = await deleteById(
    DATABASE_STRUCTURE.tableName,
    [DATABASE_STRUCTURE.columns[0]],
    values
  );
  return response;
};
