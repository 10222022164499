import React, { useState, useRef, useContext, useEffect } from "react";

import { CrudButton, Modal } from "../../../../imports";
import { COLORS } from "../../../../common/theme/theme";
import { Col, Row } from "react-bootstrap";
import style from "../css/style.module.css";
import { handleCheckUserSection } from "../../../../util/Utils";
import { ConstantsModerna } from "../../../../common/constants/ConstantsModerna";
import { ModernaContext } from "../../../../Context/ModernaContext";
import Loading2 from "../../../../common/loading2/loading2";
import { handleCheckAccessValidation } from "../../../../service/AccessValidation";
import { validatePercentage } from "../../../../util/validations";

export const RowCategoryModerna = ({
  handleSavePercentages,
  data,
  disable,
  restart,
}) => {
  const capitalize = (value) => {
    if (typeof value !== "string") {
      return value;
    }
    return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
  };

  return (
    <div className={style.caja}>
      <Row
        className={style.navContent}
        style={{
          marginLeft: "5px",
          marginRight: "5px",
          marginBottom: "5px",
          // backgroundColor: "black",
          // padding: "0rem",
        }}
      >
        <Col>
          <Row>
            <Col className={style.marginRow}>
              {capitalize(
                data
                  ? data?.id_categoria + " - " + data?.nombre_categoria
                  : "categoría"
              )}
            </Col>
            <Col>
              <Fila
                restart={restart}
                valueFila={data?.market_actual}
                item={data}
                handleSavePercentages={handleSavePercentages}
                type={"MA"}
                disable={disable}
              />
            </Col>
            &nbsp; &nbsp;
            <Col>
              <Fila
                restart={restart}
                valueFila={data?.market_share}
                item={data}
                handleSavePercentages={handleSavePercentages}
                type={"MS"}
                disable={disable}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const Fila = ({
  handleSavePercentages,
  valueFila,
  item,
  type,
  disable,
  restart,
}) => {
  const { handleShowAlert } = useContext(ModernaContext);
  const [loadingBack, setLoadingBack] = useState(false);

  const [isEditing, setisEditing] = useState(false);
  const [value, setValue] = useState(valueFila);
  const [isDeleteing, setIsDeleting] = useState(false);
  useEffect(() => {
    if (isDeleteing) {
      handleSaveEdit();
    }
  }, [isDeleteing]);
  // }, [isDeleteing, value]);

  let prevValue = useRef(value);
  const handleCancelEdit = () => {
    setisEditing(false);
    setValue(prevValue.current);
  };
  const handleSaveEdit = () => {
    if (prevValue.current.toString() === value.toString()) {
      setisEditing(false);
      return;
    }
    if (value.length <= 0) {
      setValue("");
    }
    handleSavePercentages(item, value.length <= 0 ? "0" : value, type);

    setisEditing(false);
  };
  const handleOnChange = (item) => {
    try {
      let val = parseFloat(item.target.value);
      if (val >= 0 && val <= 100) {
        setValue(item.target.value);
      } else if (item.target.value.length <= 0) {
        setValue("");
      }
    } catch (e) {
      //console.log(e);
    }
  };
  // useEffect(() => {
  //   // console.log(isEditing);
  // }, [isEditing]);

  useEffect(() => {
    setisEditing(false);
  }, [restart]);

  return (
    <Row>
      <Col className={style.colSpecial1}>
        {isEditing ? (
          <Row>
            <Col
              className={style.Padding}
              style={{ margin: "0px 0px", padding: "0px" }}
            >
              <CrudButton
                size={40}
                canceled
                onClick={handleCancelEdit}
                color={COLORS.MODERNA_RED}
              />
            </Col>
            <Col className={style.Padding}>
              <CrudButton
                size={40}
                check
                onClick={handleSaveEdit}
                color={"green"}
              />
            </Col>
            <Col className={style.Padding}>
              <input
                type="text"
                value={value}
                onChange={(e) =>
                  setValue(validatePercentage(e.target.value, 0))
                }
                className={style.editInput}
                autoFocus
              />
            </Col>
            <Col className={[style.Padding, style.marginRow]}> %</Col>
          </Row>
        ) : (
          <>{disable ? valueFila : value}%</>
          // <>{valueFila} %</>
        )}
      </Col>
      <Col className={[style.colSpecial2, style.marginRow]}>
        {disable ? (
          <></>
        ) : (
          <div style={{ flexDirection: "row", display: "flex" }}>
            <CrudButton
              background={COLORS.MODERNA_YELLOW}
              size={30}
              edit
              color={"white"}
              disabled={disable}
              onClick={async () => {
                await handleCheckAccessValidation(
                  setLoadingBack,
                  () => {
                    setisEditing(!isEditing);
                    prevValue.current = value;
                  },
                  handleShowAlert
                );
              }}
            />
            <CrudButton
              background={COLORS.MODERNA_YELLOW}
              size={30}
              clean
              color={"white"}
              // disabled={disable}
              onClick={async () => {
                await handleCheckAccessValidation(
                  setLoadingBack,
                  () => {
                    setValue("0");
                    setIsDeleting(true);

                    // setTimeout(() => {
                    //   handleSaveEdit();

                    //   prevValue.current = value;
                    // }, 2000);
                  },
                  handleShowAlert
                );
              }}
            />
          </div>
        )}
      </Col>
      {loadingBack && <Loading2 />}
    </Row>
  );
};
