import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ButtonModerna,
  Checkbox,
  ComboBox,
  Loading,
  Pagination,
} from "../../../imports";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import style from "./css/style.module.css";
import {
  fetchClientGroupsAsLabelValue,
  fetchTypeClient,
} from "../../../service/ClientService";
import {
  fetchPortafolios,
  fetchProductsCategory,
  saveBriefcases,
} from "../../../service/Briefcase";
import SearchDrop from "./components/search";
import { COLORS } from "../../../common/theme/theme";
import { ID_PREFIX } from "../../../service/VariableService";
import Swal from "sweetalert2";
import Loading2 from "../../../common/loading2/loading2";
import { ModernaContext } from "../../../Context/ModernaContext";
import { handleCheckAccessValidation } from "../../../service/AccessValidation";
import { handleCleanUserSection } from "../../../util/Utils";
import { fetchCategoriaAsLabelValue } from "../../../service/CategoryS";
import { useNavigate } from "react-router-dom";
import Combo3 from "../../../common/combo/combo3";

export default function Portafolio() {
  const { handleShowAlert } = useContext(ModernaContext);

  const [selectedAll, setSelectedAll] = useState(false);
  const [clientTypes, setClientTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedClient, setSelectedClient] = useState();
  const [searchCancelled, setSearchCancelled] = useState(false);
  const [productosDB, setProductosDB] = useState([]);
  const [tipoClientes, setTipoClientes] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [productSearch, setProductSearch] = useState([]);
  const [searchProd, setSearchProd] = useState(undefined);

  const [portafolios, setPortafolios] = useState([]);

  const [searchCategoria, setSearchCategoria] = useState();
  const [categoriaFil, setCategoriaFil] = useState(undefined);
  const [numColTipoClient, setNumColTipoClient] = useState();
  const [loadingBack, setLoadingBack] = useState(false);
  const clientTypesRef = useRef([]);
  const modified = useRef([]);
  const [loadItems, setLoadItems] = useState(false);
  const [reset, setReset] = useState();
  const navegar = useNavigate();
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState();
  const lastRequest = useRef();

  const loadClientGroups = async () => {
    try {
      const response = await fetchClientGroupsAsLabelValue();
      if (response) {
        setClientTypes(response);
      }
    } catch (error) {}
  };

  const loadCategories = async () => {
    try {
      const response = await fetchCategoriaAsLabelValue();
      if (response) {
        setSearchCategoria(response);
      }
    } catch (error) {}
  };

  const loadTypeClient = async () => {
    if (selectedClient) {
      try {
        const response = await fetchTypeClient(
          selectedClient?.id_grupo_cliente
        );

        const arrayOrdenado = response.sort((a, b) => {
          return b.nombre_tipo_cliente.localeCompare(a.nombre_tipo_cliente);
        });
        // console.log("response de tipos clientes::::::", response);
        clientTypesRef.current = response.map((element) => {
          return element.id_tipo_cliente;
        });
        setTipoClientes(response);
        setNumColTipoClient(response?.length);
        return;
      } catch (error) {}
    }
    setTipoClientes([]);
  };

  const cargarProductos = async () => {
    try {
      const response = await fetchProductsCategory();
      const productosFil = response?.data?.data?.map((item) => {
        return {
          label: `${item?.id_producto} - ${item?.nombre_producto}`,
          value: item?.id_producto,
        };
      });
      productosFil.sort((a, b) => a.label.localeCompare(b.label));

      setProductSearch(productosFil);
      // console.log("fetchProductsCategory", response);
      setProductosDB(response?.data?.data);
    } catch (error) {}
  };
  const loadPrimaryData = async () => {
    setLoading(true);
    await loadClientGroups();
    await cargarProductos();
    setLoading(false);
    loadCategories();
  };

  //USEEFFECT --------------------
  useEffect(() => {
    loadPrimaryData();
  }, []);

  useEffect(() => {
    const filterProducts = [];
    let nameCategory = categoriaFil ? categoriaFil.label.split("- ")[1] : "";
    productosDB?.forEach((item) => {
      if (nameCategory === "" || item?.nombre_categoria === nameCategory) {
        filterProducts.push({
          label: `${item?.id_producto} - ${item?.nombre_producto}`,
          value: item?.id_producto,
        });
      }
    });
    setProductSearch(filterProducts);
  }, [categoriaFil]);

  useEffect(() => {
    if (selectedClient) {
      loadTypeClient();
      loadBriefCases();
    }
  }, [selectedClient, page, categoriaFil, searchProd]);

  useEffect(() => {
    modified.current = [];
    setCategoriaFil(undefined);
    setProductSearch(undefined);
  }, [selectedClient]);

  useEffect(() => {
    if (!categoriaFil) {
      setSearchProd(null);
    }
  }, [categoriaFil]);

  useEffect(() => {
    if (searchCancelled) {
      setSearchCancelled(false);
    }
  }, [searchCancelled]);

  const loadBriefCases = async () => {
    const category_search = categoriaFil
      ? categoriaFil.label.split("-")[0]
      : undefined;
    const product_search = searchProd
      ? searchProd.label.split("-")[0]
      : undefined;

    const actual_request = `category${category_search}-product${product_search}-client${selectedClient?.id_grupo_cliente}-page${page}`;
    if (actual_request === lastRequest.current) {
      return;
    }
    setLoadItems(true);

    try {
      const _result = await fetchPortafolios(
        selectedClient?.id_grupo_cliente,
        page,
        category_search,
        product_search
      );
      setPageData({
        limit: _result.limit,
        page: _result.page + 1,
        total_elements: _result.total_elements,
        total_pages: _result.total_pages,
      });
      // if()

      const response = _result?.data;
      if (response) {
        const filterrrr = response;
        //sustituirArrayPorId(productosDB, "id_producto", response);
        let isAllSelected = true;
        filterrrr.forEach((element) => {
          if (!element.estado) {
            isAllSelected = false;
            return;
          }
        });
        if (modified.current?.length > 0) {
          modified.current.forEach((item) => {
            const index = filterrrr.findIndex((filt) => {
              return item.id_producto === filt.id_producto;
            });
            if (index !== -1) {
              filterrrr[index] = item;
            }
          });
        }
        setPortafolios(filterrrr);
        CheckIfAllSelected(filterrrr);
        lastRequest.current = actual_request;
        setLoadItems(false);
        return;
      }
    } catch (error) {
      setLoadItems(false);
    }
  };

  const capitalize = (value) => {
    if (typeof value !== "string") {
      return value;
    }
    return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
  };

  const handleCheckClientType = (_portafolio, tipo_cliente) => {
    handleCheckAccessValidation(
      setLoadingBack,
      () => {
        const index = portafolios.findIndex(
          (item) =>
            item.id_producto === _portafolio.id_producto &&
            item.id_portafolio == _portafolio.id_portafolio
        );

        if (!Array.isArray(portafolios[index].infaltables)) {
          portafolios[index].infaltables = [];
        }
        if (portafolios[index].infaltables.length === 0) {
          portafolios[index].infaltables.push(tipo_cliente);
          portafolios[index].estado = true;
        } else {
          const i = portafolios[index].infaltables.findIndex((infaltable) => {
            return infaltable.id_tipo_cliente === tipo_cliente.id_tipo_cliente;
          });
          if (i === -1) {
            portafolios[index].infaltables.push(tipo_cliente);
            portafolios[index].estado = true;
          } else {
            portafolios[index].infaltables = portafolios[
              index
            ].infaltables.filter(
              (element) =>
                element.id_tipo_cliente !== tipo_cliente.id_tipo_cliente
            );
          }
        }
        setPortafolios([...portafolios]);
        updateModified(_portafolio, "UPDATE");
      },
      handleShowAlert
    );
  };

  const updateModified = (item, action) => {
    CheckIfAllSelected([...portafolios]);

    item.action = action;
    if (!Array.isArray(modified.current) || modified.current.length === 0) {
      modified.current = [item];
      return;
    }
    const index = modified.current.findIndex(
      (b) => b.id_producto === item.id_producto
    );

    if (index < 0) {
      modified.current = [...modified.current, item];
      return;
    }
    const _m = [];
    modified.current.forEach((element) => {
      if (item.id_producto === element.id_producto) {
        if (!(item.action === "INSERT" && element.action === "DELETE")) {
          _m.push(item);
        }
        if (item.action === "UPDATE" && element.action === "INSERT") {
          item.action = "INSERT";
          _m.push(item);
        }
      } else {
        _m.push(element);
      }
    });
    modified.current = _m;
    // console.log(modified.current);
  };

  const handleBriefCaseSelection = async (_portafolio) => {
    await handleCheckAccessValidation(
      setLoadingBack,
      () => {
        if (!_portafolio) {
          return;
        }
        const index = portafolios.findIndex(
          (item) => item.id_producto === _portafolio.id_producto
        );
        portafolios[index].estado = portafolios[index].estado
          ? !portafolios[index].estado
          : true;
        updateModified(_portafolio, _portafolio.estado ? "INSERT" : "DELETE");
        portafolios([...portafolios]);
      },
      handleShowAlert
    );
  };

  const alertError = () => {
    Swal.fire({
      icon: "error",
      // title: "Oops...",
      text: "Error de servidor, intentalo de nuevo",
      confirmButtonText: "Aceptar",
      confirmButtonColor: COLORS.MODERNA_RED,
      // footer: '<a href="">Why do I have this issue?</a>'
    });
  };
  const alertSuccess = () => {
    Swal.fire({
      icon: "success",
      // title: "Oops...",
      text: "Cambios guardados",
      confirmButtonText: "Aceptar",
      confirmButtonColor: COLORS.MODERNA_GREEN,
      // footer: '<a href="">Why do I have this issue?</a>'
    });
  };
  const handleSaveAll = async () => {
    setFetching(true);
    try {
      const response = await saveBriefcases(modified.current);
      if (response) {
        alertSuccess();
        loadBriefCases();
        modified.current = [];
        handleCleanUserSection();
      }
      setCategoriaFil("");
      setSearchProd("");
      setSelectedClient("");
      navegar("/variables/portafolio");
      setReset(new Date());
    } catch (error) {
      alertError();
    }
    setFetching(false);
    handleCleanUserSection(); // Se ejecuta al cerrar el modal
  };

  const CheckIfAllSelected = (array) => {
    let isAllSelected = true;
    array.forEach((item) => {
      if (item.estado !== true) {
        isAllSelected = false;
        return;
      }
    });
    // console.log("is all selected: ", isAllSelected);
    setSelectedAll(isAllSelected);
  };

  const handleCheckAll = () => {
    handleCheckAccessValidation(
      setLoadingBack,
      () => {
        if (!selectedAll) {
          portafolios.forEach((_b) => {
            if (_b?.estado === null || _b?.estado === undefined) {
              updateModified(_b, "INSERT");
            } else if (_b.estado === false) {
              _b.estado = true;
              updateModified(_b, "UPDATE");
            }
            _b.estado = true;
            const _id = portafolios.findIndex((p) => {
              return p.id_producto === _b.id_producto;
            });
            portafolios[_id] = _b;
            return;
          });
          setPortafolios([...portafolios]);
        } else {
          portafolios.forEach((_case) => {
            if (checkIfCanCheck(_case)) {
              _case.estado = false;
              updateModified(_case, "DELETE");
            }
            const _id = portafolios.findIndex((p) => {
              return p.id_producto === _case.id_producto;
            });
            portafolios[_id] = _case;
          });
          setPortafolios([...portafolios]);
        }
      },
      handleShowAlert
    );
  };

  const checkIfCanCheck = (item) => {
    if (!item.infaltables) {
      return true;
    }
    if (Array.isArray(item.infaltables)) {
      return !item.infaltables.length > 0;
    }
    return true;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      {!selectedClient ? (
        <div
          style={{
            height: "30px",
            marginBottom: "7px",
          }}
        >
          Seleccione el grupo de clientes para visualizar los datos
        </div>
      ) : (
        <div style={{ height: "30px" }}></div>
      )}
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col xs={12} sm={12} xl={7} style={{ margin: "0rem", padding: "0rem" }}>
          <Row style={{ display: "flex", margin: "0rem", padding: "0rem" }}>
            <Col xs={12} sm={6} xl={6} style={{ paddingInline: "0.5rem" }}>
              <SearchDrop
                data={searchCategoria}
                placeHolder={"Selecciona la categoría"}
                type={"filter"}
                onSelect={(e) => {
                  setSearchProd(undefined);
                  setCategoriaFil(e);
                  setPage(1);
                }}
                value={categoriaFil}
                disabled={!selectedClient || loadItems ? true : false}
              />
            </Col>
            <Col xs={12} sm={6} xl={6} style={{ paddingInline: "0.5rem" }}>
              <SearchDrop
                data={productSearch}
                placeHolder={"Buscar el producto"}
                type={"search"}
                onSelect={(e) => {
                  setSearchProd(e);
                  setPage(1);
                }}
                value={searchProd}
                disabled={!selectedClient || loadItems ? true : false}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} xl={5} style={{ margin: "0rem", padding: "0rem" }}>
          <Row style={{ display: "flex", margin: "0rem", padding: "0rem" }}>
            <Col xs={12} sm={6} xl={6} style={{ paddingInline: "0.5rem" }}>
              <Combo3
                pathvariable={"grupo_cliente"}
                onSelect={(e) => {
                  setSelectedClient(e);
                  // setSearchProd("");
                  // setCategoriaFil("");
                  // setCanOrder(true);
                }}
                title="Grupo de Clientes"
                options={clientTypes}
                secondary
                clear
                className={style.widthDrop}
                reset={reset}
                // onClear={() => {
                //   setCategoriaFil("");
                //   setSearchProd("");
                // }}
              />
            </Col>
            <Col
              className={style.button_col}
              xs={12}
              sm={6}
              xl={6}
              style={{ paddingInline: "0.5rem" }}
            >
              <ButtonModerna
                className={`${style.button}`}
                secondary
                save
                disabled={
                  !Array.isArray(modified.current) ||
                  modified.current.length == 0 ||
                  fetching === true
                }
                onClick={() => handleSaveAll()}
              >
                Guardar
              </ButtonModerna>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ margin: "0.5rem" }} />
      <Row style={{ justifyContent: "end" }}>
        <Col xl={4}>
          <Col style={{ backgroundColor: COLORS.MODERNA_RED }}>
            <Row style={{ textAlign: "center" }}></Row>
          </Col>
        </Col>
      </Row>
      <Row xs={12}>
        {!selectedClient ? (
          <></>
        ) : (
          <Col>
            {loadItems ? (
              <Loading />
            ) : (
              <Table responsive>
                <thead
                  style={{
                    backgroundColor: COLORS.MODERNA_RED,
                    color: "white",
                    border: "none",
                    borderBottom: "none",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      {"            "}
                    </th>
                    <th
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      {"     "}
                    </th>
                    <th
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      {"         "}
                    </th>
                    <th
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      {"        "}
                    </th>
                    <th
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      {"           "}
                    </th>
                  </tr>
                  <tr
                    style={{
                      borderColor: COLORS.MODERNA_RED,
                    }}
                  >
                    <th
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      {"            "}
                    </th>
                    <th
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      {"     "}
                    </th>
                    <th
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      {"         "}
                    </th>
                    <th
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      {"        "}
                    </th>
                    <th
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      {"           "}
                    </th>
                    <th
                      style={{
                        backgroundColor: COLORS.MODERNA_RED,
                      }}
                    >
                      {"             "}
                    </th>

                    <th
                      colSpan={numColTipoClient}
                      style={{ textAlign: "center", whiteSpace: "nowrap" }}
                    >
                      Portafolio Infaltable
                    </th>
                  </tr>
                  <tr>
                    <th>Organización</th>
                    <th>Canal</th>
                    <th>Categoria</th>
                    <th>Producto</th>
                    <th>Descripción</th>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Portafolio Ideal &nbsp;
                        <Checkbox
                          checked={selectedAll}
                          onClick={() => {
                            handleCheckAll();
                          }}
                          size={18}
                        />
                      </div>
                    </th>
                    {Array.isArray(tipoClientes) ? (
                      tipoClientes?.map((element, index) => (
                        <th
                          style={{ textAlign: "center", whiteSpace: "nowrap" }}
                          key={index}
                        >
                          {capitalize(element?.nombre_tipo_cliente)}
                        </th>
                      ))
                    ) : (
                      <div />
                    )}
                  </tr>
                </thead>

                <tbody>
                  {portafolios.map((prod, index) => {
                    return (
                      <tr key={index}>
                        <td className="col-1">{prod?.organizacion}</td>
                        <td className="col-0">{prod?.canal}</td>
                        <td className="col-3">
                          {capitalize(prod?.nombre_categoria)}
                        </td>
                        <td className="col-1">{prod?.id_producto}</td>
                        <td className="col-4">
                          {capitalize(prod?.nombre_producto)}
                        </td>
                        <td className="col-1">
                          <div
                            style={{
                              marginTop: "3px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              checked={prod.estado === true ? true : false}
                              onClick={() => handleBriefCaseSelection(prod)}
                              size={18}
                              disabled={prod.infaltables?.length > 0}
                            />
                          </div>
                        </td>
                        {tipoClientes?.map((tipo_cliente, index) => (
                          <td
                            className="col-1"
                            style={{ backgroundColor: "transparent" }}
                            key={index}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "3px",
                                  display: "flex",
                                  // backgroundColor: "black",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  checked={
                                    prod.infaltables?.length > 0 &&
                                    prod.infaltables.findIndex(
                                      (element) =>
                                        element.id_tipo_cliente ===
                                        tipo_cliente.id_tipo_cliente
                                    ) !== -1
                                  }
                                  onClick={() =>
                                    handleCheckClientType(prod, tipo_cliente)
                                  }
                                  size={18}
                                />
                              </div>
                            </div>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            {loadItems ? (
              <></>
            ) : categoriaFil && portafolios.length === 0 ? (
              <div
                style={{
                  borderTopWidth: "1px",
                  borderTopColor: "gray",
                  borderTopStyle: "solid",
                  paddingTop: "5px",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <label>
                  No existen productos para la categoría {categoriaFil.label}
                </label>
              </div>
            ) : (
              <Pagination setPage={setPage} data={pageData} />
            )}
          </Col>
        )}
      </Row>
      {(loadingBack || fetching) && <Loading2 />}
      {/* Mostrando {filteredPortafolios.length} de {portafolios.length} */}
    </Container>
  );
}
