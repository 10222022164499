import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import style from "./css/style.module.css";
import {
  ButtonModerna,
  Carrusel,
  Checkbox,
  ComboBox,
  ImageDropzone,
  Loading,
  Modal,
  Pagination,
} from "../../../imports";
import { v4 as uuidv4 } from "uuid";
import { COLORS } from "../../../common/theme/theme";
import Input from "../../../common/inputModerna/input";
import Item from "./components/item";
import {
  activatePlanogramaToDataBase,
  deletePlanograma,
  editPlanograma,
  fetchCategory,
  fetchGroupClient,
  fetchPlanograma,
  fetchPlanogramaFromDataBase,
  insertPlanograma,
} from "../../../service/Planograma";
import SelectDrop from "../register/components/Select/select";
import { fetchClientGroupsAsLabelValue } from "../../../service/ClientService";
import {
  fetchCategoryAsLabelValue,
  fetchCategoryAsLabelValue2,
} from "../../../service/CategoryS";
import ComboPlanograma from "./components/combo-planograma/combo-planograma";
import {
  deleteImgOneDrive,
  getCurrentUrlImage,
  getPlanogramaIdImgOneDrive,
  handleCheckUserSection,
  handleCleanUserSection,
  uploadPlanogramaImage,
  verifyPlanogramaImage,
} from "../../../util/Utils";
import { ModernaContext } from "../../../Context/ModernaContext";
import { ConstantsModerna } from "../../../common/constants/ConstantsModerna";
import Loading2 from "../../../common/loading2/loading2";
import { handleCheckAccessValidation } from "../../../service/AccessValidation";
import { SweetAlert } from "../../../common/sweet-alert/sweet-alert";

export default function Planograma() {
  const [showCreate, setShowCreate] = useState(false);
  const [showPlanograma, setshowPlanograma] = useState(false);
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [grupo, setGrupo] = useState("");
  const [categoria, setCategoria] = useState("");
  const [errorGrupo, setErrorGrupo] = useState("");
  const [errorCategoria, setErrorCategoria] = useState("");
  const [errorImagen, setErrorImagen] = useState("");
  const [errorDuplicado, seterrorDuplicado] = useState("");
  const [id, setId] = useState();
  const [files1, setFiles1] = useState();
  const [files2, setFiles2] = useState();
  const [files3, setFiles3] = useState();
  const [idCategoria, setIdCategoria] = useState("");
  const [idGrupoCliente, setIdGrupoCliente] = useState("");
  const [clientGroups, setClientGroups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [clientGroup, setClientGroup] = useState();
  const [cargando, setCargando] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleShowAlert } = useContext(ModernaContext);
  const [loadingBack, setLoadingBack] = useState(false);
  const [estadoPlanograma, setEstadoPlanograma] = useState(false);
  //paginacion::::::::::::::::::::::::::::::::::::::
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState();

  let planogramaPrev = useRef(null);

  const getClientGroups = async () => {
    const response = await fetchClientGroupsAsLabelValue();
    if (response) {
      setClientGroups(response);
    }
  };

  const alertError2 = (text) => {
    Swal.fire({
      icon: "error",
      // title: "Oops...",
      text: text,
      confirmButtonText: "Aceptar",
      confirmButtonColor: COLORS.MODERNA_RED,
      // footer: '<a href="">Why do I have this issue?</a>'
    });
  };
  const getCategories = async () => {
    const response = await fetchCategoryAsLabelValue2();
    if (response) {
      setCategories(response);
    }
  };
  useEffect(() => {
    getClientGroups();
    getCategories();
  }, []);

  const Swal = require("sweetalert2");

  const alertDelete = (item) => {
    Swal.fire({
      text: "¿Estás seguro de eliminar este planograma?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      cancelButtonColor: COLORS.MODERNA_YELLOW,
      confirmButtonColor: COLORS.MODERNA_RED,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(item);
      } else {
        handleCleanUserSection();
      }
    });
  };

  const alertError = (text) => {
    Swal.fire({
      icon: "error",
      // title: "Oops...",
      text: text,
      confirmButtonText: "Confirmar",
      confirmButtonColor: COLORS.MODERNA_RED,
    }).then((result) => {
      if (result.isConfirmed) {
        handleCleanUserSection();
      } else {
        handleCleanUserSection(); // Se ejecuta al cerrar el modal
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await fetchPlanogramaFromDataBase(page);
      setPageData({
        limit: result?.limit,
        page: result?.page + 1,
        total_elements: result?.total_elements,
        total_pages: result?.total_pages,
      });
      result.data = result?.data?.map((item) => {
        return {
          ...item,
          ...{
            estado: !item.estado ? false : item.estado,
          },
        };
      });
      setData(result?.data);
    } catch (error) {}
    setLoading(false);
  };

  const buscarCoincidenciaGC = () => {
    let encontre = data.filter(
      (objeto) =>
        (editing
          ? objeto.id_planograma.toLowerCase() !== id.toLowerCase()
          : true) &&
        objeto.id_categoria.toLowerCase() === idCategoria.toLowerCase() &&
        objeto.id_grupo_cliente.toLowerCase() ===
          idGrupoCliente.toLowerCase() &&
        objeto.estado === true
    );
    return encontre.length > 0 ? true : false;
  };

  const handleImagePlanograma = async (file, index, tmp_planograma_id) => {
    let imgUrlWeb = {};
    if (
      planogramaPrev.current[`url_imagen${index}`] &&
      planogramaPrev.current[`url_imagen${index}`] !== "null"
    ) {
      //we have planograma image link, but no file
      if (!file) {
        const response = await getPlanogramaIdImgOneDrive(
          `${tmp_planograma_id}${index}`
        );
        await deleteImgOneDrive(response.id);
        return {};
      } else if (file !== planogramaPrev.current[`url_imagen${index}`]) {
        //we have file and it's different than the actual image
        imgUrlWeb = await uploadPlanogramaImage(
          file,
          `${tmp_planograma_id}${index}`
        );
        return imgUrlWeb;
      }
      //we have link and image, and they're the same
      imgUrlWeb["url"] = planogramaPrev.current[`url_imagen${index}`];
      imgUrlWeb["id"] = planogramaPrev.current[`url_imagen${index}`];
      return imgUrlWeb;
    } else if (file) {
      //we don't have the url/image in planograma but we have file
      imgUrlWeb = await uploadPlanogramaImage(
        file,
        `${tmp_planograma_id}${index}`
      );
      return imgUrlWeb;
    }
    return {};
  };

  const handleSubmit = async () => {
    if (validarCampos()) {
      return;
    }

    if (buscarCoincidenciaGC()) {
      seterrorDuplicado(
        "* Ya tiene un planograma activo para esta combinación de grupo de clientes - categoría"
      );
      return;
    }
    setCargando(true);
    setLoading(true);
    try {
      let tmp_planograma_id = id ? id : uuidv4();
      let imgUrlWeb1 = {};
      let imgUrlWeb2 = {};
      let imgUrlWeb3 = {};
      if (editing) {
        imgUrlWeb1 = await handleImagePlanograma(files1, 1, tmp_planograma_id);
        imgUrlWeb2 = await handleImagePlanograma(files2, 2, tmp_planograma_id);
        imgUrlWeb3 = await handleImagePlanograma(files3, 3, tmp_planograma_id);
        //---------------------------------------------------------------------------------------
      } else {
        if (files1 && files1.length > 0) {
          imgUrlWeb1 = await uploadPlanogramaImage(
            files1,
            tmp_planograma_id + "1"
          );
        }
        if (files2 && files2.length > 0) {
          imgUrlWeb2 = await uploadPlanogramaImage(
            files2,
            tmp_planograma_id + "2"
          );
        }
        if (files3 && files3.length > 0) {
          imgUrlWeb3 = await uploadPlanogramaImage(
            files3,
            tmp_planograma_id + "3"
          );
        }
      }
      const PLANOGRAMA = {
        id_planograma: tmp_planograma_id,
        id_categoria: idCategoria,
        id_grupo_cliente: idGrupoCliente,
        url_imagen1: "url" in imgUrlWeb1 ? imgUrlWeb1.url : "",
        url_imagen2: "url" in imgUrlWeb2 ? imgUrlWeb2.url : "",
        url_imagen3: "url" in imgUrlWeb3 ? imgUrlWeb3.url : "",
        estado: editing ? estadoPlanograma : true,
        id_imagen1: "id" in imgUrlWeb1 ? imgUrlWeb1.id : "",
        id_imagen2: "id" in imgUrlWeb2 ? imgUrlWeb2.id : "",
        id_imagen3: "id" in imgUrlWeb3 ? imgUrlWeb3.id : "",
      };
      let save;
      if (editing) {
        save = await editPlanograma(PLANOGRAMA);
        handleCleanUserSection();
      } else {
        save = await insertPlanograma(PLANOGRAMA);
        handleCleanUserSection();
      }
      setShowCreate(false);
      fetchData();
      setId("");
      setFiles1("");
      setFiles2("");
      setFiles3("");
      setGrupo("");
      setCategoria("");
      setEditing(false);
      seterrorDuplicado("");
    } catch (error) {
      // alertError2("Error de servidor, intentalo de nuevo");
    } finally {
      handleCleanUserSection();
      // setLoadingBack(false);
      setLoading(false);
    }
    setCargando(false);
  };

  const updateStateSubmit = async (item) => {
    let encontre = data.filter(
      (objeto) =>
        objeto.id_planograma.toLowerCase() !==
          item?.id_planograma.toLowerCase() &&
        objeto.id_categoria.toLowerCase() ===
          item?.id_categoria.toLowerCase() &&
        objeto.id_grupo_cliente.toLowerCase() ===
          item?.id_grupo_cliente.toLowerCase() &&
        objeto.estado === true
    );
    // console.log("mi encontre", encontre);
    if (encontre.length > 0) {
      alertError(
        "Ya tiene un planograma activo para esta combinación de grupo de clientes - categoría"
      );
      // handleCleanUserSection();
      return;
    }
    setCargando(true);
    setLoading(true);
    try {
      const PLANOGRAMA = {
        id_planograma: item?.id_planograma,
        id_categoria: item?.id_categoria,
        id_grupo_cliente: item?.id_grupo_cliente,
        estado: !item.estado,
      };
      await activatePlanogramaToDataBase(PLANOGRAMA);

      fetchData();
    } catch (error) {
      // console.log("Ha ocurrido un error");
      alertError2("Error de servidor, intentalo de nuevo");
    } finally {
      handleCleanUserSection();
    }
    handleCleanUserSection();
    setLoading(false);
    setCargando(false);
    setEditing(false);
  };
  const handleView = async (e) => {
    setLoadingBack(true);
    try {
      changeImageModifi2(e);
      setshowPlanograma(true);
      setCategoria(e.nombre_categoria);
      setGrupo(e.nombre_grupo_cliente);
    } catch (error) {
    } finally {
      setLoadingBack(false);
    }
  };

  const changeImageModifi2 = async (e) => {
    try {
      if (e.url_imagen1) {
        setFiles1("loading");
        const getImage1 = async () => {
          const img = await getCurrentUrlImage(e?.id_planograma + "1", "PL");
          setFiles1(img);
        };
        getImage1();
      } else {
        setFiles1(null);
      }
    } catch (error) {}
    try {
      if (e.url_imagen2) {
        setFiles2("loading");
        const getImage2 = async () => {
          const img = await getCurrentUrlImage(e?.id_planograma + "2", "PL");
          setFiles2(img);
        };
        getImage2();
      } else {
        setFiles2(null);
      }
    } catch (error) {}
    try {
      if (e.url_imagen3) {
        setFiles3("loading");
        const getImage3 = async () => {
          const img = await getCurrentUrlImage(e?.id_planograma + "3", "PL");
          setFiles3(img);
        };
        getImage3();
      } else {
        setFiles3(null);
      }
    } catch (error) {}
  };

  const changeImageModifi = async (e) => {
    try {
      const getImage1 = async () => {
        if (e.url_imagen1) {
          setFiles1("loading");
          const urlImg = await getCurrentUrlImage(e.id_planograma + "1", "PL");
          setFiles1(urlImg);
        } else {
          setFiles1(null);
        }
      };
      getImage1();
    } catch (error) {}

    try {
      const getImage2 = async () => {
        if (e.url_imagen2) {
          setFiles2("loading");
          const urlImg = await getCurrentUrlImage(e.id_planograma + "2", "PL");
          setFiles2(urlImg);
        } else {
          setFiles2(null);
        }
      };
      getImage2();
    } catch (error) {}

    try {
      const getImage3 = async () => {
        if (e.url_imagen3) {
          setFiles3("loading");
          const urlImg = await getCurrentUrlImage(e.id_planograma + "3", "PL");
          setFiles3(urlImg);
        } else {
          setFiles3(null);
        }
      };
      getImage3();
    } catch (error) {}
  };

  const handleEdit = async (e) => {
    setLoadingBack(true);
    try {
      const isAble = await handleCheckUserSection();
      if (isAble) {
        changeImageModifi(e);

        setId(e.id_planograma);
        setCategory(e.nombre_categoria);
        setClientGroup(e.nombre_grupo_cliente);
        setIdCategoria(e.id_categoria);
        setIdGrupoCliente(e.id_grupo_cliente);
        setEstadoPlanograma(e.estado);
        setEditing(true);
        setShowCreate(true);
      } else {
        handleShowAlert(ConstantsModerna.messages.USER_EDITING);
      }
    } catch (error) {
    } finally {
      setLoadingBack(false);
    }
  };

  const handleDelete = async (item) => {
    // setLoadingBack(true);
    setLoading(true);
    try {
      await deletePlanograma(item.id_planograma);
      fetchData();
      if (item.url_imagen1 && item.url_imagen1.length > 0) {
        const { id: id1 } = await getPlanogramaIdImgOneDrive(
          item.id_planograma + "1",
          "Planograma"
        );
        await deleteImgOneDrive(id1);
      }
      if (item.url_imagen2 && item.url_imagen2.length > 0) {
        const { id: id2 } = await getPlanogramaIdImgOneDrive(
          item.id_planograma + "2",
          "Planograma"
        );
        await deleteImgOneDrive(id2);
      }
      if (item.url_imagen3 && item.url_imagen3.length > 0) {
        const { id: id3 } = await getPlanogramaIdImgOneDrive(
          item.id_planograma + "3",
          "Planograma"
        );
        await deleteImgOneDrive(id3);
      }
    } catch (error) {
      // alertError("Ocurrio un error al eliminar el planograma");
    } finally {
      // setLoadingBack(false);
      setLoading(false);
    }
  };

  const validarCampos = () => {
    let validate = false;
    if (clientGroup === "" || clientGroup === null || !clientGroup) {
      setErrorGrupo("* Este campo grupo es obligatorio");
      validate = true;
    }
    if (category === "" || category === null || !category) {
      setErrorCategoria("* Este campo categoria es obligatorio");
      validate = true;
    }
    if (
      (!files1 || files1 === "null" || files1 === null) &&
      (!files2 || files2 === "null" || files2 === null) &&
      (!files3 || files3 === "null" || files3 === null)
    ) {
      setErrorImagen("* Debe ingresar por lo menos una imagen");
      validate = true;
    }
    return validate;
  };

  if (loading) {
    return <Loading />;
  }

  const handleUserSection = async () => {
    setLoadingBack(true);
    try {
      const isAble = await handleCheckUserSection();
      if (isAble) {
        setId(null);
        setEditing(false);
        setFiles1(null);
        setFiles2(null);
        setFiles3(null);
        setCategory("");
        setClientGroup("");
        // setEstadoPlanograma("");
        setShowCreate(true);
      } else {
        handleShowAlert(ConstantsModerna.messages.USER_EDITING);
      }
    } catch (error) {
      // console.log("No se pudo subir el planograma");
      alertError2("Error de servidor, intentalo de nuevo");
    } finally {
      setLoadingBack(false);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <ButtonModerna
          secondary
          className={style.buttonImage}
          onClick={handleUserSection}
          cloud
        >
          Subir Planograma
        </ButtonModerna>
      </div>
      <Modal
        show={showPlanograma}
        title="Planograma"
        close={() => {
          setshowPlanograma(false);
          // setImagen("");
          setCategory("");
          setId(null);
          setClientGroup("");
        }}
      >
        <Carrusel images={[files1, files2, files3]} size={400} />
        &nbsp;
        <div className={style.inputBox2}>
          <Input
            value={grupo}
            label="Grupo de clientes"
            block
            className={style.typeText}
          />
          <Input
            value={categoria}
            label="Categoría &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; "
            className={style.typeText}
            block
          />
        </div>
      </Modal>

      <Modal
        disabled={cargando}
        className={style.container}
        show={showCreate}
        title={editing ? "Editar Planograma" : "Subir Planograma"}
        close={async () => {
          if (
            !editing &&
            (category || clientGroup || files1 || files2 || files3)
          ) {
            const confirm = await SweetAlert(
              "Tiene cambios sin guardar. ¿Desea salir?"
            );
            if (!confirm) {
              return;
            }
          }
          handleCleanUserSection();
          planogramaPrev.current = null;
          setShowCreate(cargando);
          setCategory("");
          setClientGroup("");
          setEditing(false);
          setErrorCategoria("");
          setErrorGrupo("");
          setErrorImagen("");
          setFiles1("");
          setFiles2("");
          setFiles3("");
          seterrorDuplicado("");
        }}
        buttons
        accept={handleSubmit}
      >
        {/* ---------------------------------------------------------------------------------------------------------------- */}
        <div className={style.container2}>
          <div className={style.cajaImages}>
            <ImageDropzone
              className={style.boxIm}
              setFiles={(e) => {
                setFiles1(URL.createObjectURL(e[0]));
                setErrorImagen("");
              }}
              sizeLimit
              setError={setErrorImagen}
            >
              {files1 && files1 != "null" ? (
                files1 === "loading" ? (
                  <div>
                    <Loading clean />
                  </div>
                ) : (
                  <div>
                    <Button
                      className={style.butt}
                      onClick={(e) => {
                        e.stopPropagation();
                        setFiles1(null);
                      }}
                    >
                      Borrar
                    </Button>
                    <img
                      style={{
                        height: "200px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={files1}
                    />
                  </div>
                )
              ) : (
                <Button className={style.button}>+</Button>
              )}
            </ImageDropzone>
            <ImageDropzone
              className={style.boxIm}
              setFiles={(e) => {
                setErrorImagen("");
                setFiles2(URL.createObjectURL(e[0]));
                // handleInputChange(e);
              }}
              sizeLimit
              setError={setErrorImagen}
            >
              {files2 && files2 != "null" ? (
                files2 === "loading" ? (
                  <div>
                    <Loading clean />
                  </div>
                ) : (
                  <div>
                    <Button
                      className={style.butt}
                      onClick={(e) => {
                        e.stopPropagation();
                        setFiles2(null);
                      }}
                    >
                      Borrar
                    </Button>
                    <img
                      style={{
                        height: "200px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={files2}
                    />
                  </div>
                )
              ) : (
                <Button className={style.button}>+</Button>
              )}
            </ImageDropzone>
            <ImageDropzone
              className={style.boxIm}
              setFiles={(e) => {
                setErrorImagen("");
                setFiles3(URL.createObjectURL(e[0]));
              }}
              sizeLimit
              setError={setErrorImagen}
            >
              {files3 && files3 != "null" ? (
                files3 === "loading" ? (
                  <div>
                    <Loading clean />
                  </div>
                ) : (
                  <div>
                    <Button
                      className={style.butt}
                      onClick={(e) => {
                        setFiles3(null);
                        e.stopPropagation();
                      }}
                    >
                      Borrar
                    </Button>
                    <img
                      style={{
                        height: "200px",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={files3}
                    />
                  </div>
                )
              ) : (
                <Button className={style.button}>+</Button>
              )}
            </ImageDropzone>
          </div>
          <p className={style.errorText}>{errorImagen}</p>
        </div>
        {/* --------------------------------------------------------------------------------------------------------------- */}
        <div className={style.inputBox}>
          <ComboPlanograma
            disabled={cargando}
            label={"Grupo de Clientes"}
            title={"Seleccione el grupo de clientes"}
            options={clientGroups}
            setSelected={(e) => {
              setIdGrupoCliente(e.id_grupo_cliente);
              setClientGroup(e);
              setErrorGrupo("");
              seterrorDuplicado("");
            }}
            selected={clientGroup}
            errorMessages={errorGrupo}
            filter
          />
          &nbsp;
          <ComboPlanograma
            disabled={cargando}
            label={"Categoría"}
            title={"Seleccione la Categoría"}
            options={categories}
            setSelected={(e) => {
              setIdCategoria(e.id_categoria);
              setCategory(e);
              setErrorCategoria("");
              seterrorDuplicado("");
              // console.log("que es e::::", e);
              // setCategoria()
            }}
            selected={category}
            errorMessages={errorCategoria}
            filter
          />
          &nbsp;
          <div
            style={{
              // backgroundColor: "gray",
              display: "flex",
              alignItems: "center",
            }}
          ></div>
          <p
            className={style.errorText}
            style={{ marginTop: "5px", height: "10px" }}
          >
            {errorDuplicado}
          </p>
        </div>
      </Modal>

      <div className={style.table}>
        <Row className={style.navTable}>
          <Col>
            <Row>
              <Col>Grupo de clientes</Col>
              <Col>Categoría</Col>
              <Col>Fecha de registro</Col>
              <Col style={{ textAlign: "center" }}>Planograma</Col>
            </Row>
          </Col>
        </Row>
      </div>

      {data?.map((item, index) => {
        return (
          <div key={index}>
            <Item
              data={item}
              showImage={() => handleView(item)}
              showDelete={async () => {
                await handleCheckAccessValidation(
                  setLoadingBack,
                  () => {
                    alertDelete(item);
                  },
                  handleShowAlert
                );
              }}
              showEdit={() => {
                // console.log("item----------------", item);
                handleEdit(item);
                planogramaPrev.current = item;
              }}
              handleState={async () => {
                await handleCheckAccessValidation(
                  setLoadingBack,
                  () => {
                    updateStateSubmit(item);
                  },
                  handleShowAlert
                );
              }}
            />
          </div>
        );
      })}
      <Pagination setPage={setPage} data={pageData} />
      {loadingBack && <Loading2 />}
    </Container>
  );
}
