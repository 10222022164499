import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import {
  CreateVariable,
  Error404,
  Login,
  Navbar,
  PathDisplay,
  Planograma,
  CreateExhibidor,
  RegisterExhibidor,
  TestPage,
  ROUTES,
  NoPermitsPage,
  Loading,
  ActiveSessionPage,
} from "./imports";
import PercentageCategory from "./pages/variables/percentageCategory/percentageCategory";
import Portafolio from "./pages/variables/portafolio/portafolio";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Ayuda from "./pages/ayuda/ayuda";
import { consultCodigoRolUserByEmail } from "./service/ConsultRolUserService";
import React, { useEffect, useState } from "react";
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  const { instance, accounts } = useMsal();
  let isAuthenticated = useIsAuthenticated();
  const [newState, setNewState] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const [blocked, setBlocked] = useState(false);
  // Validación de usuario
  const storageKey = "app_session";
  const uniqueValue = Math.random().toString(36).substring(2, 15);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleStorageChange = (event) => {
      setLoading(true);

      if (event.key === storageKey && event.newValue === null) {
        setBlocked(false);
        // La otra pestaña ha eliminado el valor del almacenamiento local/
        // Realizar acciones necesarias, como redirigir al usuario o mostrar un mensaje de error.
      } else {
        setBlocked(true);
      }
      setLoading(false);
    };

    // Comprobar si ya hay un valor en el almacenamiento local
    const storedValue = localStorage.getItem(storageKey);
    if (!storedValue) {
      localStorage.setItem(storageKey, uniqueValue);
    } else if (storedValue !== uniqueValue) {
      setBlocked(false);
      localStorage.removeItem(storageKey);
      // Realizar acciones necesarias, como redirigir al usuario o mostrar un mensaje de error.
    }
    // Escuchar el evento 'storage' para detectar cambios en el almacenamiento local
    window.addEventListener("storage", handleStorageChange);
    setLoading(false);
    return () => {
      // Limpiar el event listener cuando el componente se desmonte
      window.removeEventListener("storage", handleStorageChange);
      // Eliminar el valor del almacenamiento local al cerrar la pestaña
      localStorage.removeItem(storageKey);
    };
  }, []);

  let correosRolWeb;

  useEffect(() => {
    const timmer = setInterval(() => {
      setIsReady(true);
    }, 1000);
    return () => clearTimeout(timmer);
  }, []);

  useEffect(() => {
    const consultFunction = async () => {
      if (isAuthenticated) {
        if (accounts.length > 0) {
          correosRolWeb = await consultCodigoRolUserByEmail(
            accounts[0].username
          );
          if (
            correosRolWeb?.data?.data[0]?.codigo_rol == "01" ||
            correosRolWeb?.data?.data[0]?.codigo_rol == "02"
          ) {
            localStorage.setItem(
              "XXS",
              correosRolWeb?.data?.data[0]?.id_usuario
            );
            setNewState(true);
          } else {
            setNewState(false);
          }
        }
      }
    };
    consultFunction();
  }, [isAuthenticated]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Loading />
      </div>
    );
  }
  if (blocked) {
    return (
      <div
        style={{
          backgroundColor: "#D52626",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "25px",
          paddingRight: "25px",
          // width: "100px",
        }}
      >
        <ActiveSessionPage />
      </div>
    );
  }

  if (!isReady) {
    return <Loading />;
  }
  if (isAuthenticated != true) {
    return (
      <div>
        {!blocked && <div></div>}
        <Login />
      </div>
    );
  }
  if (newState === null) {
    return <Loading />;
  }

  return (
    <Router>
      {newState ? (
        <div>
          <Navbar />
          <PathDisplay />
          <Routes>
            <Route
              path="/"
              element={<Navigate to={ROUTES.VARIABLES[0].link} />}
            />
            <Route path="/test" element={<TestPage />} />
            <Route path={ROUTES.VARIABLES[1].link} element={<Portafolio />} />
            <Route
              path={ROUTES.VARIABLES[0].link}
              element={<CreateVariable />}
            />
            <Route
              path={ROUTES.VARIABLES[2].link}
              element={<PercentageCategory />}
            />
            <Route path={ROUTES.EXHIBIDORES[2].link} element={<Planograma />} />
            <Route
              path={ROUTES.EXHIBIDORES[0].link}
              element={<CreateExhibidor />}
            />
            <Route
              path={ROUTES.EXHIBIDORES[1].link}
              element={<RegisterExhibidor />}
            />
            <Route path="/ayuda" element={<Ayuda />} />
            {/* <Route path={ROUTES.AYUDA[0].link} element={<Ayuda />} /> */}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
      ) : (
        <NoPermitsPage />
      )}
    </Router>
  );
}

export default App;
