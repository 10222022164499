import { selectAllByTable, selectAllByTableWhere } from "./AxiosService";

const TABLE_STRUCTURE = {
  name: "grupo_cliente",
  columns: [
    "id_grupo_cliente",
    "nombre_grupo_cliente",
    "usuario_creacion",
    "fecha_creacion",
    "fecha_modificacion",
  ],
};
const TABLE_STRUCTURE_CLIENT = {
  name: "cliente",
  columns: [
    "id_cliente",
    "id_tipo_cliente",
    "nombre_cliente",
    "usuario_creacion",
    "fecha_creacion",
    "fecha_modificacion",
    "id_grupo_cliente",
    "nombre_grupo_cliente",
    "identificacion",
  ],
};

const TABLE_STRUCTURE_TYPE_CLIENT = {
  tableName: "tipo_cliente",
  columns: ["id_grupo_cliente"],
  values: ["12"],
};

export const fetchClientGroups = async () => {
  const response = await selectAllByTable(
    TABLE_STRUCTURE.name,
    TABLE_STRUCTURE.columns[1]
  );
  return response;
};

export const fetchClient = async () => {
  const response = await selectAllByTable(
    TABLE_STRUCTURE_CLIENT.name,
    TABLE_STRUCTURE.columns[0],
    TABLE_STRUCTURE.columns[2]
  );
  const values = Array.isArray(response)
    ? response.map((item) => {
        return {
          label: `${item.id_cliente} - ${item.nombre_cliente}`,
          value: item,
        };
      })
    : [];
  return values;
};

export const fetchTypeClient = async (value) => {
  const response = await selectAllByTableWhere(
    TABLE_STRUCTURE_TYPE_CLIENT.tableName,
    TABLE_STRUCTURE_TYPE_CLIENT.columns,
    [value]
  );
  return response;
};

export const fetchClientGroupsAsLabelValue = async () => {
  const response = await selectAllByTable(
    TABLE_STRUCTURE.name,
    TABLE_STRUCTURE.columns[1]
  );
  const values = Array.isArray(response)
    ? response.map((item) => {
        return { label: item.nombre_grupo_cliente, value: item };
      })
    : [];
  return values;
};
