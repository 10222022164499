import React, { useState } from "react";
import { Modal } from "antd";
import style from "./css/style.module.css";
import "./css/ModalAntd.css";
// import SelectSearchFetch from "../../pages/exhibidor/register/components/SelectSearch/SelectSearch";
import { Col, Row } from "react-bootstrap";
import { ButtonModerna } from "../../imports";
import { CloseOutlined } from "@ant-design/icons";

export const ModalModernaAntd = ({
  children,
  title,
  show,
  close,
  accept,
  buttons,
  className,
}) => {
  // const [isOpen, setIsopen] = useState(true);

  const [loading, setLoading] = useState(false);
  const handleAccept = async (e) => {
    setLoading(true);
    if (accept) {
      await accept(e);
    }
    setLoading(false);
  };
  return (
    <>
      <Modal
        className="modalStyle"
        footer={null}
        title={title}
        open={show}
        onCancel={close}
        closeIcon={<CloseOutlined className="custom-close-icon" />}
      >
        {children}
        {buttons && (
          <Row>
            <Col className={style.buttonGroup}>
              <ButtonModerna
                secondary
                disabled={loading}
                className={style.button}
                onClick={handleAccept}
              >
                Guardar
              </ButtonModerna>
              <ButtonModerna className={style.button} onClick={close}>
                Cancelar
              </ButtonModerna>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};
